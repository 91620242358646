import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const getItem = (key, secure) => {
  if (secure) {
    return secureLocalStorage.getItem(key);
  } else {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) : item;
  }
};

const setItem = (key, value, secure) => {
  if (secure) {
    return secureLocalStorage.setItem(key, value);
  } else {
    return localStorage.setItem(key, JSON.stringify(value));
  }
};

const removeItem = (key, secure) => {
  return secure
    ? secureLocalStorage.removeItem(key)
    : localStorage.removeItem(key);
};

const useLocalStorage = <T,>(
  key: string,
  initialValue: T,
  secure: boolean = false,
): [T, (value: T, force?: boolean) => void] => {
  const storedValue = getItem(key, secure);

  const initial = storedValue || initialValue;

  const [value, setValue] = useState(initial);

  const updateValue = (newValue: T, force = false) => {
    setValue(newValue);

    if (newValue) {
      if (force || newValue !== initial) {
        setItem(key, newValue, secure);
      }
    } else {
      removeItem(key, secure);
    }
  };

  return [value, updateValue];
};

export default useLocalStorage;
