import moment from "moment";
import { PhotoSentielImage, UnixEpoch } from "./DataTypes";

import { getFirebaseController } from "./FirebaseController";

export default class PhotoSentielController {
  static async getImages(
    deviceId,
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    filterImageIds?: number[],
  ): Promise<PhotoSentielImage[]> {
    if (filterImageIds && filterImageIds.length > 0) {
      return await getFirebaseController().Image.getPhotoSentinelImages(
        filterImageIds,
      );
    }

    const startDateString = moment.unix(startDate).format("YYYY-MM-DD");
    const endDateString = moment.unix(endDate).format("YYYY-MM-DD");

    return await fetch(
      `https://api.photosentinel.com/v2/installations/${deviceId}/photos?earliest_date=${startDateString}&latest_date=${endDateString}&sort_newest_first=true`,
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          return data.photos;
        } else {
          return [];
        }
      });
  }

  static async getFirstAndLastImage(
    deviceId,
  ): Promise<[PhotoSentielImage, PhotoSentielImage]> {
    return await fetch(
      `https://api.photosentinel.com/v2/installations/${deviceId}`,
    )
      .then((res) => res.json())
      .then((data) => [
        data.installation.earliest_photo,
        data.installation.latest_photo,
      ]);
  }
}
