import { DeviceV2, GalleryV2 } from "database/DataTypes";
import ImageHandlerV2 from "database/ImageHandlerV2";
import useEventTracker from "hooks/eventTracker/useEventTracker";
import { useAtomValue } from "jotai";
import { useState, useEffect } from "react";
import { currentUserState } from "states/auth";
import {
  currentGalleryClientState,
  currentGalleryJobsiteState,
} from "states/imageViewer";

export const TIMELAPSE_PLAYER_EVENT_ACTION_ITEM_MAPPER: {
  [key in TimelapsePlayerAction]?: {
    title: string;
    description?: string | string[];
  };
} = {
  compile: {
    title: "Compile timelapse",
    description: "",
  },
  "player seek": {
    title: "Player Seeked",
    description: "",
  },
  "player fast forward": {
    title: "Fast Forward video",
    description: "",
  },
  "player rewind": {
    title: "Rewind video",
    description: "",
  },
  "player play": {
    title: "Play video",
    description: "",
  },
  "player pause": {
    title: "Pause video",
    description: "",
  },
  "player finished": {
    title: "Video Finished",
    description: "",
  },
  download: {
    title: "Download timelapse",
    description: "",
  },
  "date picker": {
    title: "Open calander",
  },
  "item toggle": {
    title: "Gallery select dropdown",
  },
  "item search": {
    title: "Gallery Search",
  },
};

export type TimelapsePlayerAction =
  | "download"
  | "compile"
  | "date picker"
  | "player seek"
  | "player fast forward"
  | "player rewind"
  | "player pause"
  | "player play"
  | "player finished"
  | "item toggle"
  | "item search";

export type TimelapsePlayerTrackAction = (
  actionName: TimelapsePlayerAction,
) => void;

export type TimelapsePlayerTrackActionParams = {
  date_range?: string;
  date_range_option?: string;
  frame_blending?: string;
};

type TimelapsePlayerTrackInfo = {
  timelapse_player_id: string;
  device_id: string;
  device_name: string;
  device_friendly_name: string;
  gallery_id: string;
  gallery_name: string;
  gallery_friendly_name: string;
  job_site_id: string;
  client_id: string;
  page: "main";
};

export const useTimelapsePlayerTracker = (
  imageHandler: ImageHandlerV2 | null,
  page: TimelapsePlayerTrackInfo["page"] = "main",
  isTrackView?: boolean,
) => {
  const { trackEvent } = useEventTracker();

  const [info, setInfo] = useState<TimelapsePlayerTrackInfo>();
  const [isViewTracked, setIsViewTracked] = useState(false);

  const currentGalleryJobsite = useAtomValue(currentGalleryJobsiteState);
  const currentGalleryClient = useAtomValue(currentGalleryClientState);

  useEffect(() => {
    if (imageHandler) {
      let info = {
        timelapse_player_id: imageHandler.currentObject.id as unknown as string,
        device_id: "",
        device_name: "",
        device_friendly_name: "",
        gallery_id: "",
        gallery_name: "",
        gallery_friendly_name: "",
        job_site_id: "",
        client_id: "",
        page,
      };

      const isDevice = imageHandler.isDevice;

      if (isDevice) {
        const device = imageHandler.currentObject as DeviceV2;

        info = {
          ...info,
          device_id: device.id as unknown as string,
          device_name: device.deviceId,
          device_friendly_name: device.friendlyName,
        };

        setInfo(info);
      } else {
        const gallery = imageHandler.currentObject as GalleryV2;

        if (
          currentGalleryJobsite?.id === gallery.jobSite &&
          currentGalleryClient?.sites.includes(gallery.jobSite)
        ) {
          info = {
            ...info,
            gallery_id: gallery.id as unknown as string,
            gallery_name: gallery.galleryName,
            gallery_friendly_name: [
              currentGalleryClient?.name,
              currentGalleryJobsite.name,
              gallery.galleryName,
            ]
              .filter((value) => !!value)
              .join(" - "),
            job_site_id: currentGalleryJobsite.id as unknown as string,
            client_id: currentGalleryClient.id as unknown as string,
          };

          setInfo(info);
        }
      }
    }

    return () => {
      if (imageHandler?.currentObject.id !== info?.timelapse_player_id) {
        setIsViewTracked(false);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGalleryClient, currentGalleryJobsite, imageHandler, page]);

  useEffect(() => {
    if (isTrackView && info && !isViewTracked) {
      trackView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const trackAction = (
    actionName: TimelapsePlayerAction,
    params?: TimelapsePlayerTrackActionParams,
  ) => {
    if (imageHandler) {
      trackEvent("timelapse_player_action", {
        ...info,

        ...params,
        action: actionName,
      });
    }
  };

  const trackView = () => {
    if (info) {
      trackEvent("timelapse_player_view", {
        ...info,
      });

      setIsViewTracked(true);
    }
  };

  return { trackAction };
};

export default useTimelapsePlayerTracker;
