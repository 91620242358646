import { SvgIcon } from "@mui/material";

const SliderIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 60">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path strokeLinecap="round" d="M56 47v8H3v-8zM56 11v8H3v-8z" />
      <path
        strokeLinecap="square"
        d="M52 51h-3M52 15h-3M45 51h-3M45 15h-3M38 51h-3M38 15h-3M31 51h-3M31 15h-3M24 51h-3M24 15h-3M17 51h-3M17 15h-3M10 51H7M10 15H7"
      />
      <path strokeLinecap="round" d="M54 19v28H30V19zM30 19v28H6V19z" />
    </g>
  </SvgIcon>
);

export default SliderIcon;
