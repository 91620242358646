import React, { Component, ReactComponentElement, ReactNode } from "react";

const ConditionalWrapper = ({
  wrapper,
  isWrap,
  children,
}: {
  wrapper: (children: JSX.Element) => JSX.Element;
  isWrap: boolean;
  children: JSX.Element;
}): JSX.Element => {
  return isWrap ? wrapper(children) : children;
};

export default ConditionalWrapper;
