import { DeviceV2 } from "database/DataTypes";
import { atomWithReset } from "jotai/utils";

export interface SearchStateType {
  value: string;
  onSearch: boolean;
}

export const searchState = atomWithReset<SearchStateType>({
  value: "",
  onSearch: false,
});

export const currentDeviceState = atomWithReset<DeviceV2 | null>(null);
