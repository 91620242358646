import { Snackbar, Slide, Alert } from "@mui/material";

import { useSnackbar } from "context/Snackbar/SnackbarContext";

const GlobalSnackbar = () => {
  const { snackbarProps, resetSnackbarProps } = useSnackbar();

  const handleClose = (e, reason) => {
    if (reason !== "escapeKeyDown" && reason !== "clickaway") {
      resetSnackbarProps();
    }
  };

  return (
    <Snackbar
      key={snackbarProps.key}
      color="primary"
      open={snackbarProps.open}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={(e) => handleClose(e, "")}
        severity={snackbarProps.severity}
        sx={{ width: "100%" }}
      >
        {snackbarProps.content}
      </Alert>
    </Snackbar>
  );
};
export default GlobalSnackbar;
