import { atomWithReset } from "jotai/utils";
import { Client, JobSite, User, UserRole } from "database/DataTypes";

export const currentUserState = atomWithReset<User | null>(null);

export const currentUserRoleState = atomWithReset<UserRole | null>(null);

export const userRolesState = atomWithReset<UserRole[]>([]);

export const currentJobSitesState = atomWithReset<JobSite[]>([]);

export const currentClientState = atomWithReset<Client | null>(null);
