import {
  CreateOrUpdateDocumentOptions,
  FirebaseController,
} from "database/FirebaseController";
import { getDefaultClient } from "database/DataDefaultValues";
import { Client, JobSite, UserRole } from "database/DataTypes";

import {
  writeBatch,
  arrayUnion,
  getDocs,
  query,
  where,
  arrayRemove,
} from "firebase/firestore";

export class _ClientController {
  private parent!: FirebaseController;

  constructor(parent: FirebaseController) {
    this.parent = parent;
  }

  async getClient(clientId) {
    return this.parent.getDocumentWithId<Client>("clients", clientId);
  }

  async getClients(clientIds?: number[]) {
    return this.parent.getDocuments<Client>("clients", clientIds);
  }

  async addClient(
    client: Partial<Client>,
    options?: CreateOrUpdateDocumentOptions,
  ) {
    const id = client.id || this.parent.getNewDocumentId();

    const newClient = {
      ...getDefaultClient(),
      ...client,
      id,
    };

    return await this.parent.createOrUpdateDocument(
      newClient,
      "clients",
      id,
      options,
    );
  }

  async updateClient(
    clientId: number,
    client: Partial<Client>,
    editedJobSiteIds: [number[], number[]],
  ) {
    const batch = writeBatch(this.parent.getDb());

    const clientRef = this.parent.getDocRef<Client>("clients", clientId);

    batch.update(clientRef, client);

    const [addedJobSiteIds, removedJobSiteIds] = editedJobSiteIds;

    if (addedJobSiteIds.length > 0) {
      addedJobSiteIds.forEach((id) => {
        const jobSiteRef = this.parent.getDocRef<JobSite>("jobSites", id);

        batch.update(jobSiteRef, {
          associatedClients: arrayUnion(clientId),
        });
      });
    }

    if (removedJobSiteIds.length > 0) {
      for (const jobSiteId of removedJobSiteIds) {
        const jobSiteRef = this.parent.getDocRef<JobSite>(
          "jobSites",
          jobSiteId,
        );

        const prevUserRolesSnapshot = await getDocs(
          query(
            this.parent.getColRef<UserRole>("userRoles"),
            where("associatedClient", "==", clientId),
          ),
        );

        prevUserRolesSnapshot.forEach((snap) => {
          const userRole = snap.data();

          const userRoleRef = this.parent.getDocRef<UserRole>(
            "userRoles",
            userRole.id,
          );

          batch.update(userRoleRef, {
            accessableJobSites: arrayRemove(jobSiteId),
          });
        });

        batch.update(jobSiteRef, {
          associatedClients: arrayRemove(clientId),
        });
      }
    }

    return await batch.commit();
  }
}
