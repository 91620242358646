import { keyframes, styled } from "@mui/material";

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Root = styled("div")(({ theme, ...props }) => {
  const color = props.color || theme.palette.primary.main;

  const size = {
    small: "0.5",
    medium: "0.8",
    large: "1",
    // @ts-ignore
  }[props.size || "medium"];

  return {
    display: "inline-block",
    position: "relative",
    width: "60px",
    height: "60px",
    transform: `scale(${size})`,

    div: {
      boxSizing: "border-box",
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      border: `8px solid ${color}`,
      borderRadius: "50%",
      animation: `${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
      borderColor: `${color} transparent transparent transparent`,

      ":nth-of-type(1)": {
        animationDelay: "-0.45s",
      },

      ":nth-of-type(2)": {
        animationDelay: "-0.30s",
      },

      ":nth-of-type(3)": {
        animationDelay: "-0.15s",
      },
    },
  };
});

const LDSRingLoader = ({
  color,
  size,
}: {
  color?: string;
  size?: "small" | "medium" | "large";
}) => {
  return (
    // @ts-ignore
    <Root color={color} size={size}>
      <div />
      <div />
      <div />
    </Root>
  );
};

export default LDSRingLoader;
