import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  TabsProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TopToolBar } from "./ImageViewerWindow";
import {
  DeviceV2,
  GalleryV2,
  UnixEpoch,
  VisualCrossingWeather,
  VisualCrossingWeatherConditions,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";
import ImageHandlerV2, {
  Image,
  Thumbnail,
  WeatherLocation,
} from "database/ImageHandlerV2";
import _ from "lodash";
import moment from "moment";
import { useRef, useEffect, useState, useMemo, Fragment } from "react";
import { useAtom, useSetAtom } from "jotai";
import {
  currentWeatherImageState,
  gridComponentCahcesState,
  imageViewerComponentCahcesState,
  selectedImageState,
  weatherReportComponentCahcesState,
} from "states/imageViewer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useAccess from "hooks/useAccess";
import { ArrowBack, BrokenImage, ExpandMore } from "@mui/icons-material";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useImageViewerTracker from "hooks/eventTracker/useImageViewerTracker";
import FontAwesomeSvgIcon from "components/Icons/FontAwesomeSvgIcon";
import useWeather, {
  getFaWeatherIcon,
  FaWeatherIconName,
  getFaWeatherColor,
  WeatherStation,
} from "hooks/useWeather";
import LineChart, { LineChartOptions } from "components/Charts/LineChart";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import LDSRingLoader from "components/Loaders/LDSRingLoader";
import { momentWithTimeZoneHOF } from "hooks/useTimeZone";
import useLocalStorage from "hooks/useLocalStorage";
import { getDisplayValue } from "utils/display";
import { createSelectOption } from "utils/input";

const DATE_RANGE_OPTIONS = [
  createSelectOption("Past Week"),
  createSelectOption("Past 15 Days"),
];

const DEFAULT_STATION = {
  name: "Auto",
  id: "auto",
  distance: -1,
  longitude: -1,
  latitude: -1,
};

const ThumbnailCard = ({
  thumbnail,
  imageHandler,
  onClick,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  onClick?: () => {};
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const setSelectedImage = useSetAtom(selectedImageState);

  const navigate = useNavigate();
  const params = useParams();

  const handleImageClick = () => {
    if (thumbnail) {
      setSelectedImage(thumbnail.image);

      navigate(`../${params.id}/image-viewer`);
    }
  };

  const getThumbnail = async () => {
    if (imageHandler) {
      if (thumbnail) {
        setIsLoading(true);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (imageHandler && thumbnail) {
      getThumbnail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setThumbnailSrc("");
      setIsLoaded(false);
    };
  }, [imageHandler, thumbnail]);

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          // borderRadius: 2,
          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? palette.grey[100]
              : isLoading || !isLoaded
              ? alpha(palette.grey[300], 0.2)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImage fontSize="large" sx={{ color: "#dedbdc" }} />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          // maxWidth: 400,
          display: "flex",
          ml: "auto",
          // border: "1px solid #a6a6a6",
          borderRadius: 2,
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          minHeight: thumbnailSrc
            ? 0
            : {
                xs: 150,
                sm: 250,
              },
        }}
        onClick={handleImageClick}
      >
        <ThumbnailPlaceHolder />

        {thumbnailSrc && (
          <Box
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => setIsLoaded(true)}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
              userSelect: "none",
            }}
          />
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          display: "block",
          lineHeight: 1,
          textAlign: "center",

          color: ({ palette }) => palette.secondary.main,
        }}
      >
        {imageHandler && thumbnail
          ? `Latest Image ${moment(
              imageHandler.getImageTime(thumbnail.image),
            ).format("HH:mm A")}`
          : "-"}
      </Typography>
    </Box>
  );
};

const HourThumbnailCard = ({
  thumbnail,
  imageHandler,
  onClick,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  onClick: (thumbnail: Thumbnail<Image>) => void;
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const getThumbnail = async () => {
    if (imageHandler) {
      if (thumbnail) {
        setIsLoading(true);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (imageHandler && thumbnail) {
      getThumbnail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setThumbnailSrc("");
      setIsLoaded(false);
    };
  }, [imageHandler, thumbnail]);

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          // borderRadius: 2,
          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? palette.grey[100]
              : isLoading || !isLoaded
              ? alpha(palette.grey[300], 0.2)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImage fontSize="large" sx={{ color: "#dedbdc" }} />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 150,
          display: "flex",
          ml: "auto",
          // border: "1px solid #a6a6a6",
          borderRadius: 2,
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          minHeight: thumbnailSrc
            ? 0
            : {
                xs: 100,
              },

          transition: ({ transitions }) =>
            transitions.create("transform", { duration: 100 }),
          ":hover": {
            transform: "scale(1.02)",

            "+ span": {
              color: ({ palette }) => palette.white.main,
              fontWeight: "bold",
            },
          },
        }}
        onClick={() => {
          if (thumbnail) {
            onClick(thumbnail);
          }
        }}
      >
        <ThumbnailPlaceHolder />

        {thumbnailSrc && (
          <Box
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => setIsLoaded(true)}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
              userSelect: "none",
            }}
          />
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          display: "block",
          lineHeight: 1,
          textAlign: "center",
          color: ({ palette }) => palette.white.main,
          fontSize: 10,
        }}
      >
        {imageHandler && thumbnail
          ? moment(imageHandler.getImageTime(thumbnail.image)).format("HH:mm A")
          : "-"}
      </Typography>
    </Box>
  );
};

const HourDataAccordion = ({
  data,
  dataIndex,
  currentWeatherHourItemIndex,
  setCurrentWeatherHourItemIndex,
  imageHandler,
}: {
  data: VisualCrossingWeatherConditions;
  dataIndex: number;
  currentWeatherHourItemIndex: number;
  setCurrentWeatherHourItemIndex: (index: number) => void;
  imageHandler: ImageHandlerV2 | null;
}) => {
  const isActive = currentWeatherHourItemIndex === dataIndex;

  const [thumbnails, setThumbnails] = useState<Thumbnail<Image>[]>([]);

  const setSelectedImage = useSetAtom(selectedImageState);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (imageHandler) {
      const thumbnails = imageHandler.thumbnails.filter((thumbnail) => {
        const imageHour = moment(
          imageHandler.getImageTime(thumbnail.image),
        ).format("HH");

        const dateHour = moment(data.datetime, "HH").format("HH");

        return imageHour === dateHour;
      });

      setThumbnails(thumbnails);
    }
  }, [imageHandler]);

  const isExpandable = thumbnails.length > 0;

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  return (
    <Accordion
      id={`hour_data_${dataIndex}`}
      disabled={!isExpandable}
      elevation={0}
      data-disabled={!isExpandable}
      expanded={isActive}
      onChange={() => {
        if (isActive) {
          setCurrentWeatherHourItemIndex(-1);
        } else {
          setCurrentWeatherHourItemIndex(dataIndex);
        }
      }}
      sx={{
        borderRadius: 1,
        scrollMargin: 120,
        scrollBehavior: "smooth",

        "&::before": {
          display: "none",
        },

        outline: ({ palette }) =>
          isActive
            ? `1px solid ${palette.secondary.main}`
            : `0px solid ${palette.secondary.main}`,
        "&.Mui-expanded": {
          my: 1,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore
            sx={{ visibility: isExpandable ? "visible" : "hidden" }}
          />
        }
        sx={{
          p: 1,
          gap: 1,
          m: 0,
          minHeight: 0,
          ".MuiAccordionSummary-content": {
            m: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 1,
            overflow: "auto",
            flexWrap: mediumScreenAndUp ? "nowrap" : "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,

              width: {
                sm: "50%",
                md: "35%",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {moment(data.datetime, "HH:mm").format("HH:mm")}
            </Typography>

            <FontAwesomeSvgIcon
              icon={getFaWeatherIcon(data.icon as FaWeatherIconName)}
              sx={{
                fontSize: 22,
                color: getFaWeatherColor(data.icon as FaWeatherIconName),
              }}
            />

            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {getDisplayValue(data.temp)}°
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {getDisplayValue(data.conditions)}
            </Typography>
          </Box>

          {(mediumScreenAndUp || isActive) && (
            <Box
              sx={({ breakpoints }) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                width: {
                  xs: "100%",
                  md: "50%",
                  lg: "40%",
                },
                p: isActive ? 1 : 0,
                background: ({ palette }) => palette.grey[100],
                borderRadius: 1,

                [breakpoints.down("sm")]: {
                  p: 1,
                },
              })}
            >
              {[
                {
                  value: `${getDisplayValue(data.windspeed)} Km/h`,
                  icon: getFaWeatherIcon("wind"),
                },
                {
                  value: `${getDisplayValue(data.precip)} mm`,
                  icon: faDroplet,
                },
                {
                  value: `${getDisplayValue(data.solarenergy, (solarenergy) => {
                    return ((solarenergy as number) * 277.78).toFixed(2);
                  })} Wh/m²`,
                  icon: getFaWeatherIcon("clear-day"),
                },
              ].map((data, dataIndex) => {
                return (
                  <Fragment key={dataIndex}>
                    {dataIndex > 0 && (
                      <Divider flexItem orientation="vertical" />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                        flex: 1,
                      }}
                    >
                      <FontAwesomeSvgIcon
                        icon={data.icon}
                        sx={{
                          fontSize: 14,
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: 14,
                          textAlign: "center",
                        }}
                      >
                        {data.value}
                      </Typography>
                    </Box>
                  </Fragment>
                );
              })}
            </Box>
          )}
        </Box>
      </AccordionSummary>

      {isExpandable && (
        <AccordionDetails sx={{ p: 1, paddingTop: 0 }}>
          <Box
            sx={{
              p: 1,
              borderRadius: 1,

              background: ({ palette }) => palette.secondary.light,
            }}
          >
            <Box
              sx={{
                display: "flex",
                overflow: "auto",

                gap: 1,
                // minHeight: 100,
                // height: 100,
              }}
            >
              {thumbnails.map((thumbnail, thumbnailIndex) => {
                return (
                  <Box key={thumbnailIndex}>
                    <HourThumbnailCard
                      thumbnail={thumbnail}
                      imageHandler={imageHandler}
                      onClick={(thumbnail) => {
                        navigate(`../${params.id}/image-viewer`);
                        setSelectedImage(thumbnail.image);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const ChartTabs = ({
  chartsData,
  TabsProps,
  isLoading,
  onItemClick,
}: {
  chartsData: any;
  TabsProps?: TabsProps;
  isLoading?: boolean;
  onItemClick?: (data, dataIndex) => void;
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const currentChartData = useMemo(() => {
    return chartsData[currentTab];
  }, [currentTab, chartsData]);

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={(e, newTab) => setCurrentTab(newTab)}
        sx={{
          background: ({ palette }) => alpha(palette.primary.main, 1),

          ".MuiTab-root": {
            color: ({ palette }) => palette.secondary.main,

            "&.Mui-selected": {
              background: ({ palette }) => alpha(palette.primary.dark, 1),
              color: ({ palette }) => palette.white.main,
            },
          },

          ".MuiTabs-indicator": {
            background: ({ palette }) => palette.white.main,
          },
        }}
        {...TabsProps}
      >
        {chartsData.map((item, itemIndex) => {
          return (
            <Tab key={itemIndex} value={itemIndex} label={item.tabLabel} />
          );
        })}
      </Tabs>

      <Box
        sx={{
          flex: 1,
          overflow: "auto",

          background: ({ palette }) =>
            currentChartData.data.length > 0
              ? palette.white.main
              : palette.grey[100],

          p: 2,
        }}
      >
        <Box sx={{ minWidth: 500 }}>
          <LineChart
            id={currentChartData.title}
            data={currentChartData.data}
            options={currentChartData.options}
            title={currentChartData.title}
            onItemClick={onItemClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StationDropDown = ({
  stations,
  selectedStation,
  onChange,
}: {
  stations: WeatherStation[];
  selectedStation: WeatherStation;
  onChange: (station: WeatherStation) => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography>Station:</Typography>

      <Autocomplete
        size="small"
        disablePortal
        disableClearable
        options={stations}
        onChange={(e, station) => {
          onChange(station);
        }}
        noOptionsText="No stations"
        getOptionLabel={(option) => _.upperFirst(option.name)}
        value={selectedStation}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        ListboxProps={{
          sx: {
            ".MuiAutocomplete-option": {
              minHeight: 32,
              py: 0.5,
              fontSize: 14,
            },
          },
        }}
        sx={{
          width: 200,
          ".MuiInputBase-root": {
            backgroundColor: ({ palette }) => palette.secondary.light,
            color: ({ palette }) => palette.white.main,
            outline: "white !important",
            fontSize: {
              xs: 14,
              md: 16,
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: ({ palette }) => alpha(palette.white.main, 1),
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: ({ palette }) => palette.primary.main,
            },

            ".MuiOutlinedInput-notchedOutline": {
              borderColor: ({ palette }) => alpha(palette.white.main, 0.5),
            },
          },
        }}
        renderInput={(params) => (
          <TextField {...(params as TextFieldProps)} size="small" />
        )}
      />
    </Box>
  );
};

const ImageViewerWeatherWindow = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingHoursData, setIsLoadingHoursData] = useState<boolean>(true);
  const [currentDevice, setCurrentDevice] = useState<DeviceV2 | GalleryV2>();
  const [imageHandler, setImageHandler] = useState<ImageHandlerV2 | null>(null);

  const [galleryLocation, setGalleryLocation] = useState<
    WeatherLocation | undefined
  >(undefined);
  const [dateRange, setDateRange] = useState<UnixEpoch[]>([]);

  const [weatherDaysData, setWeatherDaysData] = useState<
    VisualCrossingWeatherConditions[]
  >([]);

  const [weatherHoursData, setWeatherHoursData] = useState<
    VisualCrossingWeatherConditions[]
  >([]);

  const [componentCahces, setComponentCahces] = useAtom(
    weatherReportComponentCahcesState,
  );

  const [imageViewerComponentCahces] = useAtom(imageViewerComponentCahcesState);

  const [customDateRange, setCustomDateRange] = useState<UnixEpoch[]>([]);

  const [dateRangeOption, setDateRangeOption] = useState(
    DATE_RANGE_OPTIONS[0].value,
  );

  const [selectedStation, setSelectedStation] = useState(DEFAULT_STATION);

  const [stationList, setStationList] = useState<WeatherStation[]>([
    DEFAULT_STATION,
  ]);
  const [usedStations, setUsedStations] = useState<WeatherStation[]>([]);

  const [currentWeatherItemIndex, setCurrentWeatherItemIndex] = useState(-1);
  const [currentWeatherHourItemIndex, setCurrentWeatherHourItemIndex] =
    useState(-1);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { palette } = useTheme();

  const { trackAction, trackImage } = useImageViewerTracker(
    imageHandler,
    "weather",
    true,
  );

  const { getWeather } = useWeather();

  const { isBlocked, isAccessable, isSiteAccessable, isAdmin } = useAccess();

  const [weatherStationCaches, setWeatherStationCaches] = useLocalStorage(
    "weather-staions",
    {},
  );

  const compCachesRef = useRef<any>(null);
  const firstRender = useRef(true);
  const skipEffects = useRef({
    imageHandler: false,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const isDevice = !!location.pathname.match("/devices/");

  const momentTz = useMemo(() => {
    return momentWithTimeZoneHOF(galleryLocation?.timeZone);
  }, [galleryLocation]);

  useEffect(() => {
    compCachesRef.current = {
      galleryLocation,
      dateRange,
      customDateRange,
      weatherDaysData,
      weatherHoursData,
      currentWeatherItemIndex,
      currentWeatherHourItemIndex,
      selectedStation,
      stationList,
      usedStations,
      imageHandler,
    };
  });

  useEffect(() => {
    setIsLoading(true);

    const viewerCaches = imageViewerComponentCahces[params.id as string];
    const caches = componentCahces[params.id as string];

    if (caches) {
      const imageHandler = caches.imageHandler as ImageHandlerV2;

      setCurrentDevice(imageHandler.currentObject);
      setImageHandler(imageHandler);
      setDateRange(caches.dateRange);
      setCustomDateRange(caches.customDateRange);
      setGalleryLocation(caches.galleryLocation);
      setWeatherDaysData(caches.weatherDaysData);
      setWeatherHoursData(caches.weatherHoursData);
      setCurrentWeatherItemIndex(caches.currentWeatherItemIndex);
      setCurrentWeatherHourItemIndex(caches.currentWeatherHourItemIndex);
      setSelectedStation(caches.selectedStation);
      setStationList(caches.stationList);
      setUsedStations(caches.usedStations);

      setIsLoading(false);
      setIsLoadingHoursData(false);

      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = caches.scrollTop || 0;
        }
      }, 100);

      skipEffects.current.imageHandler = true;
    } else if (viewerCaches) {
      const imageHandler = viewerCaches.imageHandler as ImageHandlerV2;

      setCurrentDevice(imageHandler.currentObject);
      setImageHandler(imageHandler);

      initWeather(imageHandler, [
        momentTz(
          imageHandler.getImageTime(
            imageHandler.thumbnails[viewerCaches.currentThumbnailIndex].image,
          ),
          true,
        )
          .startOf("day")
          .unix(),
      ]);

      setIsLoading(false);

      skipEffects.current.imageHandler = true;
    } else {
      setImageHandler(null);
      setDateRangeOption(DATE_RANGE_OPTIONS[0].value);
      setSelectedStation(DEFAULT_STATION);
      setStationList([DEFAULT_STATION]);
      setUsedStations([]);
      initImageHandler();
    }

    return () => {
      // TODO: try implement component cache, so that when user navigate back it doens't nid to be re-init again

      setComponentCahces((caches) => {
        return {
          ...caches,
          [params.id as string]: _.cloneDeep(compCachesRef.current),
        };
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (!firstRender.current && imageHandler) {
      if (skipEffects.current.imageHandler) {
        skipEffects.current.imageHandler = false;

        return;
      }

      setIsLoading(true);

      imageHandler
        .getImages()
        .then(async () => {
          const latestImage = imageHandler.images[0];

          // start of day so that we can cache the data by date, we don't care about exact time anyway
          initWeather(imageHandler, [
            momentTz(imageHandler.getImageTime(latestImage), true)
              .startOf("day")
              .unix(),
          ]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageHandler]);

  // this must be place on last
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const initWeatherData = async (
    location: WeatherLocation,
    dateRange: number[],
    station?: WeatherStation,
  ) => {
    setIsLoading(true);

    setGalleryLocation(location);
    setDateRange(dateRange);

    await getWeather(location.param, dateRange, {
      include: "days",
      timeZone: location.timeZone,
      stationId: station?.id !== "auto" ? station?.id : undefined,
    }).then(async (data) => {
      const weatherData = data?.days;

      if (weatherData) {
        const currentIndex = weatherData[currentWeatherItemIndex]
          ? currentWeatherItemIndex
          : weatherData.length - 1;

        setWeatherDaysData(weatherData as VisualCrossingWeatherConditions[]);
        getHoursData(
          weatherData[currentIndex],
          currentIndex,
          location,
          station,
        );

        if (data.stations) {
          const stations = (
            Object.values(data.stations) as WeatherStation[]
          ).map((station) => {
            station.displayName = station.name.split(", ")[0];

            return station;
          });

          if (stationList.length === 1) {
            // only set when there is no stations other than the auto

            const newStationList = [...stationList, ...stations];

            setStationList(newStationList);
          }

          setUsedStations(stations);
        } else {
          // some station has no station data for some reason, so try find the station from dropdown list, and set it as currect station

          const foundStation = stationList.find((s) => s.id === station?.id);

          if (foundStation) {
            setUsedStations([foundStation]);
          } else {
            // just fallback to auto staion in case something went wrong, most likely not

            return await initWeatherData(
              location,
              dateRange,
              DEFAULT_STATION,
            ).then(() => {
              setSelectedStation(DEFAULT_STATION);
            });
          }
        }
      }

      setIsLoading(false);
    });
  };

  const handleDateRangeInputChange = async (e) => {
    setIsLoading(true);

    if (!imageHandler || dateRangeOption === e.target.value) {
      return;
    }

    try {
      const newDateRange = dateRange;

      switch (e.target.value) {
        case "Past Week":
          // trackAction("filter past week");

          newDateRange[0] = moment
            .unix(dateRange[1])
            .subtract(1, "weeks")
            .unix();

          break;
        case "Past 15 Days":
          // trackAction("filter 15 days");

          newDateRange[0] = moment
            .unix(dateRange[1])
            .subtract(15, "days")
            .unix();

          break;

        default:
          return;
      }

      setDateRangeOption(e.target.value);

      await initWeather(imageHandler, newDateRange, selectedStation);
    } catch (error) {
      console.error(error);
    }
  };

  const initImageHandler = async () => {
    setIsLoading(true);

    const getData = async () =>
      isDevice
        ? await getFirebaseController().Device.getDevice(params.id)
        : await getFirebaseController().Gallery.getGallery(params.id);

    await getData()
      .then(async (currentDevice) => {
        if (!currentDevice) {
          throw new Error(`${isDevice ? "Divice" : "Gallery"} not found.`);
        }

        if (
          !isDevice &&
          !isAdmin &&
          !isSiteAccessable((currentDevice as GalleryV2).jobSite)
        ) {
          navigate("/");
          return;
        }

        const imageHandler = new ImageHandlerV2(currentDevice, isDevice);

        setCurrentDevice(currentDevice);
        setImageHandler(imageHandler);
      })
      .catch((err) => {
        console.error(err);
        navigate("../");
      });
  };

  const initWeather = async (
    imageHandler: ImageHandlerV2,
    dateRange: UnixEpoch[],
    station?: WeatherStation,
  ) => {
    if (imageHandler) {
      setIsLoading(true);

      const parsedDateRange = dateRange;

      if (dateRange.length === 1) {
        // default to 1 weeks if only 1 date in range

        parsedDateRange.unshift(
          moment.unix(dateRange[0]).subtract(1, "weeks").unix(),
        );
      }

      await imageHandler.getWeatherLocation().then(async (location) => {
        if (location) {
          return await initWeatherData(location, parsedDateRange, station);
        }
      });

      setIsLoading(false);
    }
  };

  const getHoursData = async (
    data: VisualCrossingWeatherConditions,
    dataIndex: number,
    location: WeatherLocation,
    station?: WeatherStation,
  ) => {
    setCurrentWeatherItemIndex(dataIndex);

    setIsLoadingHoursData(true);

    // (() => {
    //   const arr: any[] = [];

    //   _.times(24, (index) => {
    //     const time = index.toString().padStart(2, "0");

    //     arr.push({
    //       datetime: `${time}:00:00`,
    //       datetimeEpoch: 1718632800,
    //       temp: 4.2,
    //       feelslike: 1.1,
    //       humidity: 92.48,
    //       dew: 3.1,
    //       precip: 0,
    //       precipprob: 0,
    //       snow: 0,
    //       snowdepth: 0,
    //       preciptype: null,
    //       windgust: 2.5,
    //       windspeed: 13,
    //       winddir: 273,
    //       pressure: 1021,
    //       visibility: 10,
    //       cloudcover: 89.3,
    //       solarradiation: 0,
    //       solarenergy: 0,
    //       uvindex: 0,
    //       severerisk: 10,
    //       conditions: "Partially cloudy",
    //       icon: "partly-cloudy-night",
    //       stations: ["AV396", "YMAV", "YMML"],
    //       source: "obs",
    //     });
    //   });

    //   setWeatherHoursData(arr as unknown as VisualCrossingWeatherConditions[]);

    //   setIsLoadingHoursData(false);
    // })();

    await getWeather(location.param, [data.datetimeEpoch], {
      include: "hours",
      timeZone: location.timeZone,
      stationId: station?.id !== "auto" ? station?.id : undefined,
    }).then((data) => {
      const daydata = data?.days?.[0];

      if (daydata) {
        setWeatherHoursData(daydata.hours);
      }

      setIsLoadingHoursData(false);
    });
  };

  const handleDayClick = async (
    data: VisualCrossingWeatherConditions,
    dataIndex: number,
  ) => {
    const element = document.getElementById(`day_summary_container`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    setIsLoading(true);

    if (imageHandler && galleryLocation) {
      const startDate = moment(data.datetime).startOf("days").unix();
      const endDate = moment(data.datetime).endOf("days").unix();

      await imageHandler.filter(startDate, endDate).then(async () => {
        setIsLoading(false);

        await getHoursData(data, dataIndex, galleryLocation, selectedStation);
      });
    }
  };

  const handleHourClick = async (
    data: VisualCrossingWeatherConditions,
    dataIndex: number,
  ) => {
    if (data) {
      const element = document.getElementById(`hour_data_${dataIndex}`);

      if (element && element.getAttribute("data-disabled") === "false") {
        setCurrentWeatherHourItemIndex(dataIndex);

        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 250);
      }
    }
  };

  const handleStationChanged = (newStation: WeatherStation) => {
    if (newStation.id !== selectedStation.id && galleryLocation) {
      setSelectedStation(newStation);

      initWeatherData(galleryLocation, dateRange, newStation);
    }
  };

  const breadcrumbs = useMemo(() => {
    if (!currentDevice || !isAdmin) {
      return [];
    }

    if (isDevice) {
      const device = currentDevice as DeviceV2;
      return [
        {
          label: "Devices",
          path: "/devices",
        },
        {
          label: device.friendlyName || device.deviceId || "",
          path: `/devices/${device.id}`,
        },
        {
          label: "Image Viewer",
          path: `/devices/${device.id}/image-viewer`,
        },
        {
          label: "Weather Report",
        },
      ];
    } else {
      const gallery = currentDevice as GalleryV2;

      return [
        {
          label: "Galleries",
          path: "/galleries",
        },
        {
          label: gallery.galleryName || "",
          path: `/galleries/${gallery.id}`,
        },
        {
          label: "Image Viewer",
          path: `/galleries/${gallery.id}/image-viewer`,
        },
        {
          label: "Weather Report",
        },
      ];
    }
  }, [isDevice, isAdmin, currentDevice]);

  const buttons = [
    {
      name: "back",
      description: "Back to Image Viewer",
      icon: <ArrowBack />,
      onClick: () => {
        const toPath = location.pathname.split("/").slice(0, -1).join("/");

        navigate(toPath);
      },
    },
  ];

  const currentWeatherItem: VisualCrossingWeatherConditions = useMemo(() => {
    return {
      ...weatherDaysData[currentWeatherItemIndex],
      hours: [],
    } as VisualCrossingWeatherConditions;
  }, [currentWeatherItemIndex, weatherDaysData]);

  const {
    hoursTemperatureOptions,
    hoursWindSpeedOptions,
    hoursPrecipOptions,
  }: { [key: string]: LineChartOptions } = useMemo(() => {
    const baseOptions: Partial<LineChartOptions> = {
      xKey: "datetime",
      xLabel: `Time`,
      xInterval: 0,
      yInterval: 0,
      yDomain: [
        (dataMin) => Math.floor(dataMin - 3),
        (dataMax) => Math.floor(dataMax + 3),
      ],
      xTickFormatter: (value, i) => {
        return moment(value, "HH").format("HH");
      },
      showLegend: false,
      minHeight: 200,
    };

    return {
      hoursTemperatureOptions: {
        ...baseOptions,
        colors: [palette.error.main],
        yLabel: "Temperature (°C)",
        yKey: "temp",
        unit: "°C",
      },
      hoursWindSpeedOptions: {
        ...baseOptions,
        colors: [palette.primary.main],
        yKey: "windspeed",
        yLabel: "Wind Speed",
        unit: "Km/h",
      },
      hoursPrecipOptions: {
        ...baseOptions,
        colors: [palette.warning.dark],
        yKey: "precip",
        yLabel: "Precipitation",
        unit: "mm",
      },
    };
  }, [currentWeatherItem]);

  const {
    temperatureOptions,
    windSpeedOptions,
    precipOptions,
  }: { [key: string]: LineChartOptions } = useMemo(() => {
    const baseOptions: Partial<LineChartOptions> = {
      xKey: "datetime",
      xLabel: `Date`,
      xInterval: "preserveEnd",
      yInterval: 0,
      yDomain: [
        (dataMin) => Math.floor(dataMin - 3),
        (dataMax) => Math.floor(dataMax + 3),
      ],
      //   xDomain: ["auto", "auto"],
      xTickFormatter: (value, i) => {
        return moment(value).format("MMM DD");
      },
      showLegend: false,
      minHeight: 200,
    };

    return {
      temperatureOptions: {
        ...baseOptions,
        colors: [palette.error.main],
        yLabel: "Temperature (°C)",
        yKey: "temp",
        unit: "°C",
      },
      windSpeedOptions: {
        ...baseOptions,
        colors: [palette.primary.main],
        yLabel: "Wind Speed (Km/h)",
        yKey: "windspeed",
        unit: "Km/h",
      },
      precipOptions: {
        ...baseOptions,
        colors: [palette.warning.dark],
        yLabel: "Precipitation (mm)",
        yKey: "precip",
        unit: "mm",
        yDomain: [0, (dataMax) => Math.floor(dataMax + 3)],
      },
    };
  }, [currentWeatherItem]);

  return (
    <Box
      onScroll={(e) => {
        compCachesRef.current.scrollTop = e.currentTarget.scrollTop;
      }}
      ref={containerRef}
      sx={({ palette }) => ({
        width: "100vw",
        height: "100vh",
        backgroundColor: palette.secondary.main,
        color: palette.white.main,
        position: "fixed",
        overflow: "auto",
        ".MuiIconButton-root": {
          color: palette.grey[400],
          transition: ({ transitions }) => transitions.create("all"),

          ":hover": {
            color: palette.white.main,
          },

          "&.Mui-disabled": {
            color: palette.grey[800],
          },
        },
      })}
    >
      <TopToolBar buttons={buttons} imageHandler={imageHandler} />

      <Box
        sx={({ palette, customConfig }) => ({
          py: customConfig.imageViewerNavbarHeight,
          backgroundColor: `${palette.secondary.main} !important`,
          height: "100%",
          width: "100%",
        })}
      >
        <Container
          fixed
          maxWidth={"lg"}
          sx={{
            p: {
              xs: 2,
              sm: 3,
            },
            pt: 0,
          }}
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ pb: 2 }} />

          <Box pb={2}>
            <Grid
              container
              alignItems={"stretch"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Grid item display={"flex"} alignItems={"center"}>
                <TextField
                  size="small"
                  select
                  variant={"outlined"}
                  onChange={handleDateRangeInputChange}
                  value={dateRangeOption}
                  sx={{
                    width: 200,
                    ".MuiInputBase-root": {
                      backgroundColor: ({ palette }) => palette.secondary.light,
                      color: ({ palette }) => palette.white.main,
                      outline: "white !important",
                      fontSize: {
                        xs: 14,
                        md: 16,
                      },

                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.white.main, 1),
                      },

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) => palette.primary.main,
                      },

                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.white.main, 0.5),
                      },
                    },
                  }}
                >
                  {DATE_RANGE_OPTIONS.map((option, optionIndex) => {
                    return (
                      <MenuItem
                        dense
                        key={optionIndex}
                        value={option.value as string}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item display={"flex"} alignItems={"center"}>
                <StationDropDown
                  stations={stationList}
                  selectedStation={selectedStation}
                  onChange={handleStationChanged}
                />
              </Grid>
            </Grid>

            {dateRangeOption === "Custom Month" && imageHandler && (
              <Grid item display={"flex"} alignItems={"center"} gap={1} mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month"]}
                    minDate={moment
                      .unix(imageHandler!.dateRange[0])
                      .startOf("month")}
                    maxDate={moment
                      .unix(imageHandler!.dateRange[1])
                      .endOf("month")}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[0])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              palette.secondary.light,
                            color: ({ palette }) => palette.white.main,
                            outline: "white !important",

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "month")
                    // }
                  />
                </LocalizationProvider>
              </Grid>
            )}

            {dateRangeOption === "Custom Range" && imageHandler && (
              <Grid item display={"flex"} alignItems={"center"} gap={1} mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    minDate={moment.unix(imageHandler!.dateRange[0])}
                    maxDate={moment.unix(imageHandler!.dateRange[1])}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[0])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              palette.secondary.light,
                            color: ({ palette }) => palette.white.main,
                            outline: "white !important",

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "start")
                    // }
                  />
                </LocalizationProvider>

                <Typography>To</Typography>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    minDate={moment.unix(imageHandler!.dateRange[0])}
                    maxDate={moment.unix(imageHandler!.dateRange[1])}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[1])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              palette.secondary.light,
                            color: ({ palette }) => palette.white.main,
                            outline: "white !important",

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.white.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "end")
                    // }
                  />
                </LocalizationProvider>
              </Grid>
            )}

            <Box>
              <Box
                sx={{
                  mt: 2,
                  background: ({ palette }) => palette.white.main,
                  borderRadius: 1,
                  boxShadow: 1,
                  color: ({ palette }) => palette.secondary.main,
                  overflow: "hidden",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      height: 320,
                      width: "100%",
                      background: ({ palette }) => palette.grey[100],
                    }}
                  />
                ) : (
                  <ChartTabs
                    chartsData={[
                      {
                        title: "Temperature Changes",
                        options: temperatureOptions,
                        data: weatherDaysData,
                        tabLabel: "Temperature",
                      },
                      {
                        title: "Wind Speed Changes",
                        options: windSpeedOptions,
                        data: weatherDaysData,
                        tabLabel: "Wind",
                      },
                      {
                        title: "Rain Fall Changes (precip)",
                        options: precipOptions,
                        data: weatherDaysData,
                        tabLabel: "Precipitation",
                      },
                    ]}
                    onItemClick={handleDayClick}
                  />
                )}
              </Box>

              <Box
                sx={{
                  // mt: 2,
                  background: ({ palette }) => palette.secondary.main,
                  borderRadius: 1,
                  // boxShadow: 1,
                  color: ({ palette }) => palette.secondary.main,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    py: 2,
                  }}
                >
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: 200,
                        width: "100%",
                        background: ({ palette }) => palette.grey[100],
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "stretch",
                        overflow: "scroll",
                        gap: 1,
                      }}
                    >
                      {weatherDaysData.map((data, dataIndex) => {
                        const isActive = currentWeatherItemIndex === dataIndex;

                        const day = moment(data.datetime).format("ddd");
                        const isWeekend = day === "Sun" || day === "Sat";

                        return (
                          <Box
                            key={data.datetime}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",

                              height: 210,
                              cursor: "pointer",
                              minWidth: 65,
                              width: 65,

                              transition: ({ transitions }) =>
                                transitions.create("all"),

                              borderRadius: 1,
                              p: 1,
                              background: ({ palette }) =>
                                isActive ? "#fcd5ac" : palette.white.main,

                              ":hover": {
                                background: ({ palette }) =>
                                  isActive ? "#fcd5ac" : palette.grey[200],
                              },
                            }}
                            onClick={() => handleDayClick(data, dataIndex)}
                          >
                            <FontAwesomeSvgIcon
                              icon={getFaWeatherIcon(
                                data.icon as FaWeatherIconName,
                              )}
                              sx={{
                                fontSize: 20,
                                color: getFaWeatherColor(
                                  data.icon as FaWeatherIconName,
                                ),
                              }}
                            />
                            <Box
                              sx={{
                                mt: 1,
                                textAlign: "center",
                                color: ({ palette }) =>
                                  isWeekend
                                    ? palette.secondary.dark
                                    : getFaWeatherColor(
                                        data.icon as FaWeatherIconName,
                                      ),
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: "bold",
                                }}
                              >
                                {day}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                              >
                                {moment(data.datetime).format("DD")}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 8, fontWeight: "bold" }}
                              >
                                {moment(data.datetime).format("MMM, YY")}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                textAlign: "center",
                                mt: 2,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 14, fontWeight: "bold" }}
                              >
                                {`${getDisplayValue(data.temp)}°`}
                              </Typography>

                              <Typography
                                sx={{ fontSize: 10, fontWeight: "bold" }}
                              >
                                {getDisplayValue(
                                  data.conditions,
                                  (conditions) => conditions.split(",")[0],
                                )}
                              </Typography>
                            </Box>

                            <Box sx={{ mt: "auto", textAlign: "center" }}>
                              <FontAwesomeSvgIcon
                                icon={faDroplet}
                                sx={{
                                  fontSize: 12,
                                  color: ({ palette }) =>
                                    alpha(palette.info.light, 0.5),
                                }}
                              />

                              <Typography
                                sx={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                  lineHeight: 1,
                                }}
                              >
                                {`${getDisplayValue(data.precip, (precip) => {
                                  return (precip as number).toFixed(1);
                                })} mm`}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                id="day_summary_container"
                sx={{
                  background: ({ palette }) => palette.white.main,

                  scrollMargin: ({ customConfig }) =>
                    customConfig.imageViewerNavbarHeight,

                  borderRadius: 1,
                  boxShadow: 1,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    background: ({ palette }) =>
                      alpha(palette.primary.light, 1),
                    color: ({ palette }) => palette.secondary.main,
                    //   color: "white",
                    boxShadow: 1,
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    position: "sticky",
                    zIndex: 10,
                    top: 50,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold", lineHeight: 1 }}
                  >
                    {`Summary - ${moment(currentWeatherItem.datetime).format(
                      "ddd, MMM DD, YYYY",
                    )}`}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    p: 2,
                    color: ({ palette }) => palette.secondary.main,
                    gap: 4,
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <FontAwesomeSvgIcon
                          icon={getFaWeatherIcon(
                            currentWeatherItem.icon as FaWeatherIconName,
                          )}
                          sx={{
                            fontSize: 100,
                            color: getFaWeatherColor(
                              currentWeatherItem.icon as FaWeatherIconName,
                            ),
                          }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontSize: 24,
                              fontWeight: "bold",
                              lineHeight: 1,
                            }}
                          >
                            {`${currentWeatherItem.temp || "-"} °C`}
                          </Typography>
                          <Typography sx={{ fontSize: 16 }}>
                            Feels Like:{" "}
                            {`${currentWeatherItem.feelslike || "-"}°`}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            lineHeight: 1,
                          }}
                        >
                          {currentWeatherItem.conditions}
                        </Typography>

                        <Typography sx={{ fontSize: 12 }}>
                          {currentWeatherItem.description}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      {[
                        [
                          {
                            label: "Wind Speed",
                            value: `${getDisplayValue(
                              currentWeatherItem.windspeed,
                            )} Km/h`,
                          },
                          {
                            label: "Solar Energy",
                            value: `${getDisplayValue(
                              currentWeatherItem.solarenergy,
                              (solarenergy) => {
                                return (Number(solarenergy) * 277.78).toFixed(
                                  2,
                                );
                              },
                            )} Wh/m²`,
                          },
                        ],

                        [
                          {
                            label: "Precipitation",
                            value: `${getDisplayValue(
                              currentWeatherItem.precip,
                            )} mm`,
                          },

                          {
                            label: "Humidity",
                            value: `${getDisplayValue(
                              currentWeatherItem.humidity,
                            )}%`,
                          },
                        ],

                        [
                          {
                            label: "Pressure",
                            value: `${getDisplayValue(
                              currentWeatherItem.pressure,
                            )} Mb`,
                          },
                          {
                            label: "UV Index",
                            value: getDisplayValue(currentWeatherItem.uvindex),
                          },
                        ],
                        [
                          {
                            label: "Sun Rise",
                            value: getDisplayValue(currentWeatherItem.sunrise),
                          },
                          {
                            label: "Sun Set",
                            value: getDisplayValue(currentWeatherItem.sunset),
                          },
                        ],

                        [
                          {
                            label: "Cloud Cover",
                            value: `${getDisplayValue(
                              currentWeatherItem.cloudcover,
                            )}%`,
                          },
                          {
                            label: "Visibility",
                            value: `${getDisplayValue(
                              currentWeatherItem.visibility,
                            )} Km`,
                          },
                        ],
                        [
                          {
                            label: "Time Zone",
                            value: `${getDisplayValue(
                              galleryLocation?.timeZone || "-",
                            )}`,
                          },
                          {
                            label: "Offset",
                            value: `${momentTz().format("Z z")}`,
                          },
                        ],
                      ].map((row, rowIndex) => {
                        return (
                          <Fragment key={rowIndex}>
                            <Divider sx={{ my: 1 }} />

                            <Box
                              sx={({ breakpoints }) => ({
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 4,

                                [breakpoints.down("sm")]: {
                                  flexDirection: "column",
                                  gap: 1,
                                },
                              })}
                            >
                              {row.map((data, dataIndex) => {
                                return (
                                  <Box
                                    key={dataIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flex: 1,
                                      gap: 0.5,
                                    }}
                                  >
                                    <Typography sx={{ fontSize: 14 }}>
                                      {data.label}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        textAlign: "end",
                                      }}
                                    >
                                      {data.value}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Fragment>
                        );
                      })}

                      <Divider sx={{ my: 1 }} />

                      <Box
                        sx={({ breakpoints }) => ({
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 4,

                          [breakpoints.down("sm")]: {
                            flexDirection: "column",
                            gap: 1,
                          },
                        })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 14, whiteSpace: "nowrap" }}
                          >
                            Station Used
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: "bold",
                              textAlign: "start",
                              wordBreak: "break-word",
                            }}
                          >
                            {usedStations.map((s) => s.displayName).join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                        lg: "40%",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: ({ palette }) => palette.grey[100],
                      border: ({ palette }) => `1px solid ${palette.grey[200]}`,
                      // boxShadow: 1,
                      borderRadius: 2,
                      p: 1,
                      m: "auto",
                    }}
                  >
                    <ThumbnailCard
                      thumbnail={imageHandler?.thumbnails[0]}
                      imageHandler={imageHandler}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    color: ({ palette }) => palette.secondary.main,
                    background: ({ palette }) => palette.grey[100],
                  }}
                >
                  {isLoadingHoursData ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        height: 300,
                        width: "100%",
                        background: ({ palette }) => palette.grey[100],
                      }}
                    />
                  ) : (
                    <>
                      <Box>
                        <ChartTabs
                          TabsProps={{
                            sx: {
                              background: ({ palette }) =>
                                palette.secondary.main,
                              minHeight: 44,

                              ".MuiTab-root": {
                                color: ({ palette }) =>
                                  `${palette.white.main} !important`,
                                fontSize: 12,
                                p: 1,
                                minHeight: 44,

                                "&.Mui-selected": {
                                  background: ({ palette }) =>
                                    palette.secondary.dark,
                                },
                              },

                              ".MuiTabs-indicator": {
                                background: ({ palette }) => palette.white.main,
                              },
                            },
                          }}
                          chartsData={[
                            {
                              title: "Temperature Changes",
                              options: hoursTemperatureOptions,
                              data: weatherHoursData,
                              tabLabel: "Temperature",
                            },
                            {
                              title: "Wind Speed Changes",
                              options: hoursWindSpeedOptions,
                              data: weatherHoursData,
                              tabLabel: "Wind",
                            },
                            {
                              title: "Rain Fall Changes (precip)",
                              options: hoursPrecipOptions,
                              data: weatherHoursData,
                              tabLabel: "Precipitation",
                            },
                          ]}
                          onItemClick={handleHourClick}
                        />
                      </Box>

                      <Box
                        sx={{
                          overflow: "auto",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1.5,
                          p: 2,
                        }}
                      >
                        {weatherHoursData.map((data, dataIndex) => {
                          return (
                            <HourDataAccordion
                              key={dataIndex}
                              data={data}
                              dataIndex={dataIndex}
                              currentWeatherHourItemIndex={
                                currentWeatherHourItemIndex
                              }
                              setCurrentWeatherHourItemIndex={
                                setCurrentWeatherHourItemIndex
                              }
                              imageHandler={imageHandler}
                            />
                          );
                        })}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ImageViewerWeatherWindow;
