import { Outlet } from "react-router-dom";

const DeviceDashboardWindow = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default DeviceDashboardWindow;
