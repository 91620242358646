import { memo, useEffect, useMemo, useRef, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { useSetAtom } from "jotai";
import { currentDeviceState } from "states/navbar";

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link as MuiLink,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Slider,
  SliderProps,
  InputAdornment,
  Menu,
  IconButton,
  TextFieldProps,
} from "@mui/material";
import {
  ArrowDropDown,
  ContentCopy as ContentCopyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";

import Navbar from "components/Layouts/Navbar";

import {
  DAY_NAMES,
  UPDATE_CHANNEL_MAPPER,
  getDefaultDevice,
  getDefaultDeviceDetails,
  getDefaultDeviceIntervalSettings,
  getDefaultDeviceSettings,
  INTERVAL_OPTIONS,
  BURST_INTERVAL_OPTIONS,
  INTERVAL_TIME_OPTIONS,
  ROTATE_VALUE_MAPPER,
  getDefaultDeviceInfo,
  UPDATE_TIME_OPTIONS,
  AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR,
  SATURATION_RANGE,
  SHARPNESS_RANGE,
  CONTRAST_RANGE,
  BRIGHTNESS_RANGE,
} from "database/DataDefaultValues";
import { DeviceV2 } from "database/DataTypes";

import { useSnackbar } from "context/Snackbar/SnackbarContext";

import { Controller, useForm } from "react-hook-form";

import _ from "lodash";

import DashboardEditContainer, {
  baseSettingSwitchProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";

import SettingsTable, {
  Column,
  columns,
  SettingRow,
  IntervalSettingsRow,
} from "components/Dashboard/SettingsTable";
import { useDeviceTracker } from "hooks/eventTracker/useDeviceTracker";
import { getFirebaseController } from "database/FirebaseController";

import { createSelectOption } from "utils/input";

export const intervalSettingsColumns: Column[] = [
  { id: "active", label: "Active", width: "5%", align: "center" },
  { id: "day", label: "Day", width: "35%" },
  { id: "startTime", label: "Start Time", width: "15%" },
  { id: "finishTime", label: "Finish Time", width: "15%" },
  { id: "interval", label: "Interval", width: "15%" },
  { id: "action", label: "Action", width: "15%" },
];

export const deviceInfoColumns: Column[] = [
  {
    id: "settingName",
    label: "Setting Name",
    width: "30%",
    verticalAlign: "top",
  },
  { id: "value", label: "Value", width: "70%" },
];

/**
 * To get edited fields, for react-hook-form usage only.
 * @param dirtyFields - formState.dirtyFields
 * @param allValues - data, the form values got from the formState.handleSubmit
 * @return - same as the form values but only just edited fields.
 */
export const getDirtyFields = (
  dirtyFields: object | boolean,
  allValues: object,
): any => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFields(dirtyFields[key], allValues[key]),
    ]),
  );
};

export const getFieldNames = (data, prefix = "") => {
  let result: string[] = [];

  for (const key in data) {
    const fullPath = prefix ? `${prefix}.${key}` : key;

    if (typeof data[key] === "object" && data[key] !== null) {
      const nestedChanges = getFieldNames(data[key], fullPath);
      result = result.concat(nestedChanges);
    } else {
      result.push(fullPath);
    }
  }

  return result;
};

// TODO: move this to another file
export const SliderInput = (props: SliderProps) => {
  const { sx: sxProps = {} } = props;

  const [textFieldValue, setTextFieldValue] = useState("");

  useEffect(() => {
    if (typeof props.value === "number") {
      setTextFieldValue((props.value as number).toString());
    }
  }, [props.value]);

  return (
    <Grid
      container
      sx={{
        width: {
          xs: "100%",
          sm: "50%",
        },

        columnGap: 2,
        flexWrap: "nowrap",
      }}
    >
      <Grid
        item
        xs={9}
        xl={10}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 0.5,
          minWidth: 125,
        }}
      >
        <Slider
          {...props}
          valueLabelDisplay="auto"
          value={typeof props.value === "number" ? props.value : 0}
          sx={{
            borderRadius: 0.5,
            ".MuiSlider-thumb": {
              boxShadow: "none !important",
              width: 6,
              height: 18,
              borderRadius: 0.5,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: -6,
              py: 0.5,
              px: 1,
              backgroundColor: ({ palette }) => palette.grey[600],
              color: ({ palette }) => palette.white.main,
              "&::before": {
                display: "none",
              },
              "& *": {
                background: "transparent",
                color: ({ palette }) => palette.white.main,
              },
            },
            ...sxProps,
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
        xl={2}
        sx={{ display: "flex", alignItems: "center", minWidth: 100 }}
      >
        <TextField
          sx={{
            size: "small",

            "& input[type=number]::-webkit-inner-spin-button": {
              opacity: 1,
            },
          }}
          InputProps={{
            sx: {
              fontSize: 12,
              padding: 0,
            },
          }}
          inputProps={{
            step: props.step || 1,
            min: props.min,
            max: props.max,
            sx: {
              padding: "2px 8px",
              background: "white !important",

              "&:-webkit-autofill": {
                background: "white !important",
                WebkitBoxShadow: "0 0 0 1000px white inset",
                WebkitTextFillColor: "default",
              },
            },
          }}
          variant="outlined"
          size="small"
          value={textFieldValue}
          onBlur={() => {
            if (!textFieldValue) {
              setTextFieldValue((props.value as number).toString());
            } else {
              const value = Number(textFieldValue);

              if (
                (props.min && value < props.min) ||
                (props.max && value > props.max)
              ) {
                setTextFieldValue((props.value as number).toString());
              }
            }
          }}
          onChange={(e) => {
            const newValue = e.target.value;

            setTextFieldValue(newValue);

            if (newValue) {
              const value = Number(newValue);

              if (
                (props.min && value < props.min) ||
                (props.max && value > props.max)
              ) {
                return;
              }

              props.onChange && props.onChange(e as unknown as Event, value, 0);
            }
          }}
          type="number"
          disabled={props.disabled}
        />

        {/* <Typography sx={{ fontSize: 12 }}>
          {((props.value as number) || 0).toFixed(1)}
        </Typography> */}
      </Grid>
    </Grid>
  );
};

// TODO: move this to another file
export const PrefixTextField = memo(
  (
    props: TextFieldProps & {
      prefix?: string;
      prefixOptions?: string[];
      onPrefixChange?: (prefix: string) => void;
    },
  ) => {
    const {
      prefix = "",
      prefixOptions = [],
      onPrefixChange,
      ...restProps
    } = props;

    const [currentValue, setCurrentValue] = useState<string>("");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setCurrentValue(prefix);

      if (onPrefixChange) {
        onPrefixChange(prefix);
      }
    }, [prefix]);

    const optionItems = [
      {
        value: "",
        label: "None",
      },
      ...prefixOptions.map((option) => {
        return createSelectOption(option, option);
      }),
    ];

    const isShowOptions = optionItems.length > 0;

    const handleCopy = () => {
      navigator.clipboard.writeText(currentValue + props.value);

      setIsCopy(true);
    };

    const [isCopy, setIsCopy] = useState(false);

    useEffect(() => {
      if (isCopy) {
        setTimeout(() => {
          setIsCopy(false);
        }, 500);
      }
    }, [isCopy]);

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isShowOptions && (
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {optionItems.map((item, itemIndex) => {
              const isNone = itemIndex === 0;

              return (
                <MenuItem
                  dense
                  key={item.value}
                  sx={{
                    fontSize: 14,
                    fontStyle: isNone ? "italic" : "normal",
                    color: ({ palette }) =>
                      isNone ? palette.secondary.light : "black",
                  }}
                  selected={item.value === currentValue}
                  onClick={() => {
                    setCurrentValue(item.value);
                    setAnchorEl(null);

                    if (onPrefixChange) {
                      onPrefixChange(item.value);
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Menu>
        )}

        <TextField
          {...baseSettingTextFieldProps}
          {...restProps}
          InputProps={{
            ...baseSettingTextFieldProps.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  cursor: isShowOptions ? "pointer" : "auto",
                  height: "100%",
                  background: ({ palette }) => palette.grey[100],
                  pl: 1,
                  pr: prefixOptions.length > 1 ? 0 : 1,
                  mr: 0,
                  width: "min-content",

                  ":hover": {
                    color: "black",
                    background: ({ palette }) => palette.grey[50],
                  },
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Box
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {currentValue}
                </Box>

                {isShowOptions && <ArrowDropDown sx={{ p: 0 }} />}
              </InputAdornment>
            ),
          }}
        />

        <IconButton size="small" sx={{ ml: 1 }} onClick={handleCopy}>
          {isCopy ? (
            <CheckCircleOutlineIcon
              sx={{
                fontSize: 12,
                color: ({ palette }) => palette.primary.main,
              }}
            />
          ) : (
            <ContentCopyIcon sx={{ fontSize: 12 }} />
          )}
        </IconButton>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

const DeviceEditWindow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initDevice, setInitDevice] = useState<DeviceV2 | null>(null);
  const [deviceId, setDeviceId] = useState<string>("");
  const [intervalSettingsRenderTrigger, setIntervalSettingsRenderTrigger] =
    useState(false);
  const [isForcedFocus, setIsForcedFocus] = useState(false);

  const isEdit = !!params.id;

  const { setSnackbarProps } = useSnackbar();

  const setCurrentDevice = useSetAtom(currentDeviceState);

  const cacheManualFocusDistanceRef = useRef(0);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState,
  } = useForm({});

  useDeviceTracker(initDevice, "settings", true);

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "device.deviceId") {
        setDeviceId(value.device.deviceId);
      } else if (props.name?.match("deviceSettings.triggerTwoStep")) {
        // if triggerTwoStep = true, set forcedFocus to false and set manualFocusDistance to -1
        // if forcedFocus = false, set manualFocusDistance to -1
        // if forcedFocus = true, set manualFocusDistance to prev value

        if (props.type !== "change") {
          return;
        }

        const triggerTwoStep = value.deviceSettings.triggerTwoStep;

        if (triggerTwoStep) {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });

          setValue("_temp.isForcedFocus", false);
        }
      } else if (props.name?.match("deviceSettings.manualFocusDistance")) {
        const currentValue = value.deviceSettings.manualFocusDistance;

        if (currentValue > -1) {
          cacheManualFocusDistanceRef.current =
            value.deviceSettings.manualFocusDistance;
        }
      } else if (props.name?.match("_temp.isForcedFocus")) {
        const isForcedFocus = value._temp.isForcedFocus;

        setIsForcedFocus(isForcedFocus);

        if (isForcedFocus) {
          setValue(
            "deviceSettings.manualFocusDistance",
            cacheManualFocusDistanceRef.current,
            {
              shouldDirty: true,
            },
          );

          setValue("deviceSettings.triggerTwoStep", false, {
            shouldDirty: true,
          });
        } else {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });
        }
      } else if (
        props.name?.match(/^deviceIntervalSettings.\w+.(active|burst)$/)
      ) {
        setIntervalSettingsRenderTrigger((prev) => !prev);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsLoading(true);

    if (isEdit) {
      getDevice().then(() => setIsLoading(false));
    } else {
      const device = getDefaultDevice();
      const deviceDetails = getDefaultDeviceDetails();
      const deviceSettings = getDefaultDeviceSettings();
      const deviceIntervalSettings = getDefaultDeviceIntervalSettings();
      const deviceInfo = getDefaultDeviceInfo();
      const isForcedFocus = deviceSettings.manualFocusDistance !== -1;

      setInitDevice(device);
      setIsForcedFocus(isForcedFocus);

      reset({
        device,
        deviceDetails,
        deviceSettings,
        deviceIntervalSettings,
        deviceInfo,
        _temp: {
          isForcedFocus,
        },
      });

      setIsLoading(false);
    }

    return () => {
      setCurrentDevice(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getDevice = async () => {
    try {
      const firebaseController = getFirebaseController();

      const device = await firebaseController.Device.getDevice(
        params.id as string,
      );

      if (device) {
        const deviceDetails = await firebaseController.Device.getDeviceDetails(
          device.id as number,
        );

        const deviceSettings =
          await firebaseController.Device.getDeviceSettings(
            device.id as number,
          );

        const deviceIntervalSettings =
          await firebaseController.Device.getDeviceIntervals(
            device.id as number,
          );

        const deviceInfo = await firebaseController.Device.getDeviceInfo(
          device.id as number,
        );

        const isForcedFocus = deviceSettings?.manualFocusDistance !== -1;

        setCurrentDevice(device);
        setInitDevice(device);
        setDeviceId(device.deviceId);
        setIsForcedFocus(isForcedFocus);

        reset({
          device,
          deviceDetails,
          deviceSettings,
          deviceIntervalSettings,
          deviceInfo,
          _temp: {
            isForcedFocus,
          },
        });
      } else {
        throw new Error("Device not found.");
      }
    } catch (err) {
      navigate("../");
    }
  };

  const handleSave = async (rawData) => {
    setIsLoading(true);

    const { _temp, ...data } = rawData;

    if (!deviceId) {
      setIsLoading(false);
      return;
    }

    if (isEdit) {
      const { _temp, ...dirtyFieldsState } = formState.dirtyFields;

      const editedData = getDirtyFields(dirtyFieldsState, data);

      if (_.isEmpty(editedData)) {
        setIsLoading(false);
        return;
      }

      const {
        device,
        deviceSettings,
        deviceDetails,
        deviceIntervalSettings,
        deviceInfo,
      } = editedData;

      const firebaseContorller = getFirebaseController();

      await firebaseContorller.Device.updateDevice(data.device.id, {
        device,
        deviceDetails,
        deviceSettings,
        deviceIntervalSettings,
        currentAssignedGallery: initDevice!.assignedGallery,
      })
        .then(async () => {
          if (deviceInfo) {
            if (data.deviceInfo.id) {
              await firebaseContorller.Device.updateDeviceInfo(
                data.deviceInfo.id,
                deviceInfo,
              );
            } else {
              await firebaseContorller.Device.addDeviceInfo({
                ...getDefaultDeviceInfo(),
                ...deviceInfo,
                deviceId: data.device.id,
              });
            }
          }

          setSnackbarProps({
            open: true,
            content: `Device updated successfully!`,
          });

          await getDevice().then(() => {
            setIsLoading(false);

            const editedFieldsNames = getFieldNames(editedData);

            getFirebaseController().Device.addDeviceAdminEvents({
              deviceId: data.device.id,
              eventTitle: "Device Settings Updated",
              eventDetails: `Updated fields are '${editedFieldsNames.join(
                "', ",
              )}'.`,
            });
          });
        })
        .catch(() => {
          setSnackbarProps({
            open: true,
            content: `Device update failed!`,
            severity: "error",
          });
        });
    }
  };

  const handleIntervalSettingsCopy = (day) => {
    const values = getValues(`deviceIntervalSettings.${day}`);

    DAY_NAMES.forEach((day) => {
      setValue(`deviceIntervalSettings.${day}`, values, { shouldDirty: true });
    });

    setIntervalSettingsRenderTrigger((prev) => !prev);
  };

  const deviceChannelOptions = Object.keys(UPDATE_CHANNEL_MAPPER).map(
    (value) => {
      return { value: Number(value), label: UPDATE_CHANNEL_MAPPER[value] };
    },
  );

  const rotateOptions = Object.keys(ROTATE_VALUE_MAPPER).map((value) => {
    return { value: Number(value), label: ROTATE_VALUE_MAPPER[value] };
  });

  const autoWhiteBalanceModeOptions = Object.keys(
    AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR,
  ).map((value) => {
    return {
      value: Number(value),
      label: AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR[value],
    };
  });

  const deviceDetailRows: SettingRow[] = useMemo(() => {
    const galleryName = initDevice?.frontendName?.split(" / ").pop();

    return [
      {
        settingName: "Name",
        value: <Typography>{initDevice?.deviceId || ""}</Typography>,
      },
      {
        settingName: "Friendly Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("device.friendlyName", {})}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Associated Gallery",
        value: (
          <>
            {galleryName && initDevice?.assignedGallery && (
              <MuiLink
                component={Link}
                to={`/galleries/${initDevice.assignedGallery.id}`}
                target="_blank"
                sx={{
                  fontSize: 14,

                  ":hover": {
                    color: ({ palette }) => palette.primary.light,
                    textDecoration: "underline !important",
                  },
                }}
              >
                {galleryName}
              </MuiLink>
            )}
          </>
        ),
      },
    ];
  }, [
    register,
    isLoading,
    initDevice?.deviceId,
    initDevice?.frontendName,
    initDevice?.assignedGallery,
  ]);

  const deviceSettingRows: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Exposure Time",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceSettings.exposureTime", {
              valueAsNumber: true,
            })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Auto Exposure",
        value: (
          <Controller
            name="deviceSettings.autoExposure"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Auto Focus",
        value: (
          <Controller
            name="deviceSettings.autoFocus"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Trigger Two Step",
        value: (
          <Controller
            name="deviceSettings.triggerTwoStep"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Manually Forced Focus",
        value: (
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Controller
              name="_temp.isForcedFocus"
              defaultValue={false}
              control={control}
              render={(props) => (
                <Switch
                  onChange={(e) => props.field.onChange(e.target.checked)}
                  checked={props.field.value}
                  disabled={isLoading}
                  {...baseSettingSwitchProps}
                />
              )}
            />

            {isForcedFocus && (
              <TextField
                {...baseSettingTextFieldProps}
                {...register("deviceSettings.manualFocusDistance", {
                  valueAsNumber: true,
                })}
                type="number"
                sx={{ width: 100 }}
                inputProps={{ ...baseSettingTextFieldProps.inputProps, min: 0 }}
                disabled={isLoading}
              />
            )}
          </Box>
        ),
      },
      {
        settingName: "Two Step Focus Value",
        value: (
          <Controller
            name="deviceDetails.twoStepFocusValue"
            control={control}
            render={(props) => (
              <Typography sx={{ fontSize: 14 }}>{props.field.value}</Typography>
            )}
          />
        ),
      },
      {
        settingName: "Auto White Balance",
        value: (
          <Controller
            name="deviceSettings.autoWhiteBalance"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Auto White Balance Mode",
        value: (
          <Controller
            control={control}
            name="deviceSettings.autoWhiteBalanceMode"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {autoWhiteBalanceModeOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "Setup Mode",
        value: (
          <Controller
            name="deviceSettings.setupMode"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Rotate",
        value: (
          <Controller
            control={control}
            name="deviceSettings.rotate"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {rotateOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "Gain",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceSettings.gain", {
              valueAsNumber: true,
            })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Brightness",
        value: (
          <Controller
            control={control}
            name="deviceSettings.brightness"
            render={({ field }) => (
              <SliderInput
                min={BRIGHTNESS_RANGE[0]}
                max={BRIGHTNESS_RANGE[1]}
                step={0.01}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        settingName: "Contrast",
        value: (
          <Controller
            control={control}
            name="deviceSettings.contrast"
            render={({ field }) => (
              <SliderInput
                min={CONTRAST_RANGE[0]}
                max={CONTRAST_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        settingName: "Saturation",
        value: (
          <Controller
            control={control}
            name="deviceSettings.saturation"
            render={({ field }) => (
              <SliderInput
                min={SATURATION_RANGE[0]}
                max={SATURATION_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        settingName: "Sharpness",
        value: (
          <Controller
            control={control}
            name="deviceSettings.sharpness"
            render={({ field }) => (
              <SliderInput
                min={SHARPNESS_RANGE[0]}
                max={SHARPNESS_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        settingName: "Max Sleep Time",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceSettings.maxSleepTime", {
              valueAsNumber: true,
            })}
            disabled={isLoading}
            type="number"
            sx={{ width: 100 }}
          />
        ),
      },
      {
        settingName: "90 Degree Camera",
        value: (
          <Controller
            name="device.deg90Camera"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Update Channel",
        value: (
          <Controller
            control={control}
            name={"deviceSettings.channel"}
            defaultValue={"0"}
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                select
                disabled={isLoading}
              >
                {deviceChannelOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "Perform Update",
        value: (
          <Controller
            name="deviceSettings.performUpdate"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Update Check Start Time",
        value: (
          <Controller
            control={control}
            name="deviceSettings.updateCheckFrom"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                sx={{
                  width: 100,
                }}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || UPDATE_TIME_OPTIONS[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
              >
                {UPDATE_TIME_OPTIONS.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "Update Check Finish Time",
        value: (
          <Controller
            control={control}
            name="deviceSettings.updateCheckTo"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                sx={{
                  width: 100,
                }}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || UPDATE_TIME_OPTIONS[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
              >
                {UPDATE_TIME_OPTIONS.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
    ],
    [
      register,
      control,
      isLoading,
      deviceChannelOptions,
      rotateOptions,
      autoWhiteBalanceModeOptions,
      isForcedFocus,
    ],
  );

  const networkSettingRows: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Public IP",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceDetails.publicIP")}
            placeholder="xxx.xxx.xxx.xxx"
            disabled={true}
          />
        ),
      },
      {
        settingName: "VPN IP",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceDetails.vpnIP", {
              setValueAs: (value) => {
                return value || "Disconnected";
              },
            })}
            placeholder="xxx.xxx.xxx.xxx"
            disabled={true}
          />
        ),
      },
      {
        settingName: "ETH IP",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceDetails.ethIP", {
              setValueAs: (value) => {
                return value || "Disconnected";
              },
            })}
            placeholder="xxx.xxx.xxx.xxx"
            disabled={true}
          />
        ),
      },
    ],
    [register, isLoading],
  );

  const intervalSettingsRows: IntervalSettingsRow[] = useMemo(() => {
    return DAY_NAMES.flatMap((day) => {
      const isActive = getValues(`deviceIntervalSettings.${day}.active`);
      const isBurst = getValues(`deviceIntervalSettings.${day}.burst`);

      return [
        {
          id: day,
          active: (
            <Controller
              name={`deviceIntervalSettings.${day}.active`}
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <Switch
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                  disabled={isLoading}
                  {...baseSettingSwitchProps}
                />
              )}
            />
          ),
          day: (
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{
                color: ({ palette }) => (isActive ? "" : palette.grey[400]),
                columnGap: 2,
              }}
            >
              <Grid item>{day}</Grid>
              <Grid item xs={12} md="auto">
                <FormControlLabel
                  label="Burst"
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      },
                    },
                  }}
                  sx={{
                    margin: 0,
                    mr: 1,
                  }}
                  control={
                    <Controller
                      name={`deviceIntervalSettings.${day}.burst`}
                      control={control}
                      defaultValue={false}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          sx={{
                            padding: 0,
                            mr: {
                              xs: 0.5,
                              sm: 1,
                            },
                          }}
                          disabled={isLoading || !isActive}
                          size="small"
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
          ),
          startTime: (
            <Controller
              control={control}
              name={`deviceIntervalSettings.${day}.timeFrom`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          finishTime: (
            <Controller
              control={control}
              name={`deviceIntervalSettings.${day}.timeTo`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          interval: (
            <Controller
              control={control}
              name={`deviceIntervalSettings.${day}.timeBetweenShots`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  defaultValue={field.value}
                  value={field.value || INTERVAL_OPTIONS[1].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          action: (
            <Typography
              onClick={() => handleIntervalSettingsCopy(day)}
              sx={{
                width: "max-content",
                fontSize: "12px",
                color: ({ palette }) => palette.primary.main,
                cursor: "pointer",
                ":hover": {
                  color: ({ palette }) => palette.primary.light,
                },
              }}
            >
              Copy To All
            </Typography>
          ),
        },
        ...(isBurst
          ? [
              {
                id: `${day}_burst`,
                day: (
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      sx={{
                        color: ({ palette }) =>
                          isBurst ? "" : palette.grey[400],
                      }}
                    >
                      <Typography variant="caption">{`${day}`}</Typography>
                      <Typography
                        variant="caption"
                        fontStyle={"italic"}
                      >{` (Burst Mode)`}</Typography>
                    </Grid>
                  </Grid>
                ),
                startTime: (
                  <Controller
                    control={control}
                    name={`deviceIntervalSettings.${day}.burstTimeFrom`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                finishTime: (
                  <Controller
                    control={control}
                    name={`deviceIntervalSettings.${day}.burstTimeTo`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                interval: (
                  <Controller
                    control={control}
                    name={`deviceIntervalSettings.${day}.burstTimeBetweenShots`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || BURST_INTERVAL_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {BURST_INTERVAL_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                action: <></>,
              },
            ]
          : []),
      ];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, isLoading, intervalSettingsRenderTrigger]);

  const deviceInfoRows: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Extra Info",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("deviceInfo.extraInfo")}
            fullWidth
            multiline={true}
            rows={10}
            disabled={isLoading}
            sx={{
              minWidth: 200,
            }}
          />
        ),
      },
    ],

    [register, isLoading],
  );

  const breadcrumbs = useMemo(() => {
    if (!initDevice) {
      return [];
    }

    return [
      {
        label: "Devices",
        path: "/devices",
      },
      {
        label: initDevice.friendlyName || initDevice.deviceId || "",
        path: `/devices/${initDevice?.id}` || "",
      },
      {
        label: "Settings",
      },
    ];
  }, [initDevice]);

  return (
    <Box>
      {isEdit ? (
        <Navbar isShowDevice={true} />
      ) : (
        <Navbar title={"New Device"} />
      )}

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? `${initDevice?.deviceId || "-"}`
            : `New Device ${deviceId ? `- ${deviceId}` : ""}`
        }
        subtitle="Fill in the device's info and press save when done."
        onBack={() => navigate(`/devices/${initDevice!.id}`)}
        onSave={handleSubmit(handleSave)}
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"Device Details"}
              rows={deviceDetailRows}
              columns={columns}
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Device Settings"}
              rows={deviceSettingRows}
              columns={columns}
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Network Settings"}
              rows={networkSettingRows}
              columns={columns}
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Shooting Times"}
              rows={intervalSettingsRows}
              columns={intervalSettingsColumns}
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Device Info"}
              rows={deviceInfoRows}
              columns={deviceInfoColumns}
            />
          </Grid>
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default DeviceEditWindow;
