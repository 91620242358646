import { where, query, orderBy, limit, Firestore } from "firebase/firestore";

import { FirebaseController } from "database/FirebaseController";

import { Version } from "database/DataTypes";

import _ from "lodash";
import moment from "moment";

export class _VersionController {
  private parent!: FirebaseController;
  private db!: Firestore;

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.db = this.parent.getDb();
  }

  async getVersion(versionId) {
    return this.parent.getDocumentWithId<Version>("versions", versionId);
  }

  async getVersionsByNumber(versionNumber: string, versionChannel: number) {
    const queries = [where("version", "==", versionNumber)];

    if (typeof versionChannel === "number") {
      queries.push(where("channel", "==", versionChannel));
    }

    const q = query(this.parent.getColRef("versions"), ...queries);

    return this.parent.getDocumentListWithQuery<Version>(q);
  }

  async getVersionsByChannel(versionChannel: number, limitCount?: number) {
    const queries: any[] = [
      where("channel", "==", versionChannel),
      orderBy("epochTime", "desc"),
    ];

    if (!_.isUndefined(limitCount)) {
      queries.push(limit(limitCount));
    }

    return this.parent.getDocumentListWithQuery<Version>(
      query(this.parent.getColRef("versions"), ...queries),
    );
  }

  async getVersionByLinkedBuildID(id) {
    return this.parent.getDocumentWithValue<Version>(
      "versions",
      "linkedBuildId",
      id,
    );
  }

  async addVersion(version: Partial<Version>) {
    const id = version.id || this.parent.getNewDocumentIdString("versions");

    const newVersion = {
      ...version,
      id,
    };

    return await this.parent.createOrUpdateDocument(newVersion, "versions", id);
  }

  async promoteVersion(versionId) {
    try {
      const promtingVersion = await this.getVersion(versionId);

      if (!promtingVersion) {
        throw new Error("Version not found.");
      }

      const existingVersion = await this.getVersionsByNumber(
        promtingVersion.version,
        0,
      );

      if (existingVersion.length > 0) {
        throw new Error(
          `Production version ${promtingVersion.version} is already created.`,
        );
      }

      const newVersionId = this.parent.getNewDocumentIdString("versions");

      const newVersion: Version = {
        ...promtingVersion,
        id: newVersionId,
        channel: 0,
        epochTime: moment().unix(),
        linkedBuildId: promtingVersion.id,
      };

      return this.addVersion(newVersion);
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteVersion(id, location = "") {
    const version = await this.getVersion(id);

    if (version) {
      const deleteVersion = await this.parent.deleteDocument("versions", id);

      let linkedVersion;

      // only production version might has linkedBuildId if it promoted from beta
      if (version.linkedBuildId) {
        // means promoted version, so deleting this promoted version only
      } else {
        // check if any version has liniked to this version, delete them together

        linkedVersion = await this.getVersionByLinkedBuildID(id);
      }

      if (linkedVersion) {
        await this.parent.deleteDocument("versions", linkedVersion.id);
      }

      if (location && location.match(/^Versions\/.+[0-9]+$/)) {
        this.parent.deleteFolder(location);
      }

      return deleteVersion;
    }
  }
}
