import { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  MapContainerProps,
  useMap,
} from "react-leaflet";

import L, {
  LatLngExpression,
  Map as LeafletMap,
  LeafletMouseEvent,
} from "leaflet";

import markerIcon from "assets/Leaflet/marker-icon.png";
import markerShadow from "assets/Leaflet/marker-shadow.png";

import GestureHandling from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

// Fixed COEP error
const MARKER_ICON = L.icon({
  shadowUrl: markerShadow,
  iconUrl: markerIcon,
  iconAnchor: [12.5, 41], // this is calculate from the size of the icon 25 x 41, and we want the market pin on top of the clicked location
});

const MapController = ({ onClick }) => {
  const map = useMap();

  useEffect(() => {
    map.addHandler("gestureHandling", GestureHandling);
    map.attributionControl.setPrefix(
      `<a href="https://leafletjs.com/">Leaflet</a>`,
    );

    const contributions = [
      `Powered by <a href="https://www.geoapify.com/">Geoapify</a>`,
      `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
    ];

    map.attributionControl.addAttribution(contributions.join(" | "));

    // @ts-expect-error typescript does not see additional handler here
    map.gestureHandling.enable();
  }, [map]);

  useEffect(() => {
    map.addEventListener("click", (e) => {
      onClick(e, map);
    });
  }, [map, onClick]);

  return null;
};

const Markers = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    const [lat, lon] = position || [];

    if (lat && lon) {
      map.setView(position, map.getZoom());
    }
  }, [position, map]);

  return position ? <Marker position={position} icon={MARKER_ICON} /> : null;
};

const Map = ({
  MapContainerProps,
  center,
  markerPosition = null,
  mapOnClick,
}: {
  MapContainerProps?: MapContainerProps;
  center: LatLngExpression;
  markerPosition: LatLngExpression | null;
  mapOnClick?: (event: LeafletMouseEvent, map: LeafletMap) => void;
}) => {
  return (
    <MapContainer
      style={{
        width: "100%",
        height: "100%",
        zIndex: 0,
      }}
      center={center}
      zoom={16}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      {...MapContainerProps}
    >
      <MapController onClick={mapOnClick} />
      <TileLayer
        crossOrigin="anonymous"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Markers position={markerPosition} />
    </MapContainer>
  );
};

export default Map;
