import { SvgIcon } from "@mui/material";

const CompareIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 60">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path d="M7 4h33v33H7z" />
      <path d="M24 21h33v33H24zM16.513 4.487l-9.575 9.575M56.513 44.487l-9.575 9.575M27.506 4.494 7.493 24.507M56.506 33.494 36.493 53.507M39.504 4.496 7.493 36.507M56.504 21.496 24.493 53.507M24 32l-5 5M29 37l-5 5M40 16l-5 5M45 21l-5 5" />
    </g>
  </SvgIcon>
);

export default CompareIcon;
