import { ReactNode, useMemo } from "react";
import { renderToString } from "react-dom/server";

import { Typography, TypographyProps } from "@mui/material";

import _ from "lodash";

type ToggleTypographyProps = {
  value: ReactNode | null;
  dataKey?: string;
  dataId1?: string;
  dataId2?: string;
};

export const ToggleTypography = (
  props: TypographyProps & ToggleTypographyProps,
) => {
  const { children, value, dataKey, dataId1, dataId2, ...restProps } = props;

  const defaultValue = useMemo(() => _.cloneDeep(children), [children]);

  const key = dataKey || "data-display";
  const id1 = dataId1 || "1";
  const id2 = dataId2 || "2";

  const useProps = {
    [key]: id1,
    ...restProps,
  };

  return (
    <Typography
      {...useProps}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.currentTarget.getAttribute(key) === id1) {
          e.currentTarget.innerHTML = renderToString(<>{value}</>);

          e.currentTarget.setAttribute(key, id2);
        } else {
          e.currentTarget.innerHTML = renderToString(<>{defaultValue}</>);

          e.currentTarget.setAttribute(key, id1);
        }
      }}
    >
      {defaultValue}
    </Typography>
  );
};
