import { ComponentType, ReactElement, memo, useEffect, useState } from "react";

import { Route, Routes, Navigate, useMatch } from "react-router-dom";

import DeviceDashboardWindow from "Windows/Device/DeviceDashboardWindow";
import DeviceStatusWindow from "Windows/Device/DeviceStatusWindow";
import DeviceGridWindow from "Windows/Device/DeviceGridWindow";
import DeviceEditWindow from "Windows/Device/DeviceEditWindow";

import GalleryDashboardWindow from "Windows/Gallery/GalleryDashboardWindow";
import GalleryGridWindow from "Windows/Gallery/GalleryGridWindow";
import GalleryEditWindow from "Windows/Gallery/GalleryEditWindow";

import TVDashboardWindow from "Windows/TVDashboard/TVDashboardWindow";
import TVGalleryGridWindow from "Windows/TVDashboard/TVGalleryGridWindow";

import ImageViewerWindow from "Windows/ImageViewer/ImageViewerWindow";
import ImageViewerGridWindow from "Windows/ImageViewer/ImageViewerGridWindow";
import ImageViewerWeatherWindow from "Windows/ImageViewer/ImageViewerWeatherWindow";
import TimelapsePlayer from "Windows/TimelapsePlayer/TimelapsePlayer";

import ClientDashboardWindow from "Windows/Client/ClientDashboardWindow";
import ClientIndexWindow from "Windows/Client/ClientIndexWindow";
import ClientEditWindow from "Windows/Client/ClientEditWindow";

import UserDashboardWindow from "Windows/User/UserDashboardWindow";
import UserIndexWindow from "Windows/User/UserIndexWindow";
import UserEditWindow from "Windows/User/UserEditWindow";

import JobSiteDashboardWindow from "Windows/JobSite/JobSiteDashboardWindow";
import JobSiteIndexWindow from "Windows/JobSite/JobSiteIndexWindow";
import JobSiteEditWindow from "Windows/JobSite/JobSiteEditWindow";

import VersionDashboardWindow from "Windows/Version/VersionDashboardWindow";
import VersionIndexWindow from "Windows/Version/VersionIndexWindow";
import VersionListWindow from "Windows/Version/VersionListWindow";

import FeedbackIndexWindow from "Windows/Feedback/FeedbackIndexWindow";
import FeedbackDashboardWindow from "Windows/Feedback/FeedbackDashboardWindow";

import AnalyticDashboardWindow from "Windows/Analytic/AnalyticDashboardWindow";
import AnalyticIndexWindow from "Windows/Analytic/AnalyticIndexWindow";

import { useAtomValue, useSetAtom } from "jotai";
import { isNavbarShowState, isSidenavShowState } from "states/layout";
import { currentUserRoleState } from "states/auth";

import { RoleAccessLevel } from "database/DataTypes";

import _ from "lodash";

export interface RouteType {
  name: string;
  title?: string;
  icon?: string;
  key: string;
  localeKey: string;
  path: string;
  redirectPath?: string | ((isAdmin: boolean) => string);
  component?: ComponentType<any>;
  nestedRoutes?: RouteType[];
  isNavbarShow?: boolean;
  isSidenavShow?: boolean;
  isProtected?: boolean;
  accessableLevels?: RoleAccessLevel[];
}

export interface RouteInfoType {
  title: string;
  name: string;
  key: string;
  localeKey: string;
  icon?: string;
  path?: string;
  isNavbarShow?: boolean;
  isSidenavShow?: boolean;
  fullPath?: string;
}

const ALL_ROUTES: RouteType[] = [
  {
    title: "Home",
    name: "Home",
    key: "home",
    localeKey: "home",
    icon: "home",
    path: "/",
    redirectPath: (isAdmin) => (isAdmin ? "/devices" : "/galleries"),
  },
  {
    title: "Device Dashboard",
    name: "DeviceDashboard",
    key: "device_dashboard",
    localeKey: "devices",
    icon: "device",
    path: "/devices",
    isNavbarShow: false,
    isSidenavShow: false,
    isProtected: true,
    accessableLevels: ["3"],
    component: DeviceDashboardWindow,
    nestedRoutes: [
      {
        name: "DeviceDashboard",
        key: "device_dashboard",
        localeKey: "devices",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: DeviceGridWindow,
      },
      {
        name: "DeviceDashboard",
        key: "device_dashboard",
        localeKey: "devices",
        path: "new",
        isNavbarShow: false,
        isSidenavShow: false,
        component: DeviceEditWindow,
      },
      {
        name: "DeviceDashboard",
        key: "device_dashboard",
        localeKey: "devices",
        path: ":id",
        isNavbarShow: false,
        isSidenavShow: false,
        component: DeviceStatusWindow,
      },
      {
        name: "DeviceDashboard",
        key: "device_dashboard",
        localeKey: "devices",
        path: ":id/edit",
        isNavbarShow: false,
        isSidenavShow: false,
        component: DeviceEditWindow,
      },
      {
        title: "Device Image Viewer",
        name: "DeviceImageViewer",
        key: "device_image_viewer",
        localeKey: "device_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerWindow,
      },
      {
        title: "Device Image Viewer",
        name: "DeviceImageViewer",
        key: "device_image_viewer",
        localeKey: "device_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer/list",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerGridWindow,
      },
      {
        title: "Device Image Viewer",
        name: "DeviceImageViewer",
        key: "device_image_viewer",
        localeKey: "device_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer/weather",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerWeatherWindow,
      },
      {
        title: "Device Timelapse Player",
        name: "DeviceTimelapsePlayer",
        key: "device_timelapse_player",
        localeKey: "device_timelapse_player",
        icon: "gallery",
        path: ":id/timelapse-player",
        isNavbarShow: false,
        isSidenavShow: false,
        component: TimelapsePlayer,
      },
    ],
  },
  {
    title: "Gallery Dashboard",
    name: "GalleryDashboard",
    key: "gallery_dashboard",
    localeKey: "galleries",
    icon: "gallery",
    path: "/galleries",
    isNavbarShow: false,
    isSidenavShow: false,
    component: GalleryDashboardWindow,
    nestedRoutes: [
      {
        name: "GalleryDashboard",
        key: "gallery_dashboard",
        localeKey: "galleries",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: GalleryGridWindow,
      },
      {
        name: "GalleryDashboard",
        key: "gallery_dashboard",
        localeKey: "galleries",
        path: "new",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: GalleryEditWindow,
      },
      {
        name: "GalleryDashboard",
        key: "gallery_dashboard",
        localeKey: "galleries",
        path: ":id",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: GalleryEditWindow,
      },
      {
        title: "Gallery Image Viewer",
        name: "GalleryImageViewer",
        key: "gallery_image_viewer",
        localeKey: "gallery_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerWindow,
      },
      {
        title: "Gallery Image Viewer",
        name: "GalleryImageViewer",
        key: "gallery_image_viewer",
        localeKey: "gallery_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer/list",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerGridWindow,
      },
      {
        title: "Gallery Image Viewer",
        name: "GalleryImageViewer",
        key: "gallery_image_viewer",
        localeKey: "gallery_image_viewer",
        icon: "gallery",
        path: ":id/image-viewer/weather",
        isNavbarShow: false,
        isSidenavShow: false,
        component: ImageViewerWeatherWindow,
      },
      {
        title: "Gallery Timelapse Player",
        name: "GalleryTimelapsePlayer",
        key: "gallery_timelapse_player",
        localeKey: "gallery_timelapse_player",
        icon: "gallery",
        path: ":id/timelapse-player",
        isNavbarShow: false,
        isSidenavShow: false,
        component: TimelapsePlayer,
      },
    ],
  },
  {
    title: "TV Dashboard",
    name: "TVDashboard",
    key: "tv_dashboard",
    localeKey: "tv_dashboard",
    icon: "tv_dashboard",
    path: "/tv-dashboard",
    isNavbarShow: false,
    isSidenavShow: false,
    component: TVDashboardWindow,
    nestedRoutes: [
      {
        name: "TVDashboard",
        key: "tv_dashboard",
        localeKey: "tv_dashboard",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: TVGalleryGridWindow,
      },
    ],
  },
  {
    title: "User Dashboard",
    name: "UserDashboard",
    key: "user_dashboard",
    localeKey: "users",
    icon: "user",
    path: "/users",
    isNavbarShow: false,
    isSidenavShow: false,
    component: UserDashboardWindow,
    nestedRoutes: [
      {
        name: "UserDashboard",
        key: "user_dashboard",
        localeKey: "users",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: UserIndexWindow,
      },
      {
        name: "UserDashboard",
        key: "user_dashboard",
        localeKey: "users",
        path: ":id",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: UserEditWindow,
      },
      {
        name: "UserDashboard",
        key: "user_dashboard",
        localeKey: "users",
        path: "new",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: UserEditWindow,
      },
    ],
  },
  {
    title: "Client Dashboard",
    name: "ClientDashboard",
    key: "client_dashboard",
    localeKey: "clients",
    icon: "user",
    path: "/clients",
    isNavbarShow: false,
    isSidenavShow: false,
    component: ClientDashboardWindow,
    nestedRoutes: [
      {
        name: "ClientDashboard",
        key: "client_dashboard",
        localeKey: "clients",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: ClientIndexWindow,
      },
      {
        name: "ClientDashboard",
        key: "client_dashboard",
        localeKey: "clients",
        path: "new",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: ClientEditWindow,
      },
      {
        name: "ClientDashboard",
        key: "client_dashboard",
        localeKey: "clients",
        path: ":id",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: ClientEditWindow,
      },
    ],
  },
  {
    title: "Job Site Dashboard",
    name: "JobSiteDashboard",
    key: "job_site_dashboard",
    localeKey: "job_sites",
    icon: "user",
    path: "/job-sites",
    isNavbarShow: false,
    isSidenavShow: false,
    component: JobSiteDashboardWindow,
    nestedRoutes: [
      {
        name: "JobSiteDashboard",
        key: "job_site_dashboard",
        localeKey: "job_sites",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: JobSiteIndexWindow,
      },
      {
        name: "JobSiteDashboard",
        key: "job_site_dashboard",
        localeKey: "job_sites",
        path: "new",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: JobSiteEditWindow,
      },
      {
        name: "JobSiteDashboard",
        key: "job_site_dashboard",
        localeKey: "job_sites",
        path: ":id",
        isNavbarShow: false,
        isSidenavShow: false,
        isProtected: true,
        accessableLevels: ["3"],
        component: JobSiteEditWindow,
      },
    ],
  },
  {
    title: "Version Dashboard",
    name: "VersionDashboard",
    key: "version_dashboard",
    localeKey: "version",
    icon: "device",
    path: "/versions",
    isNavbarShow: false,
    isSidenavShow: false,
    isProtected: true,
    accessableLevels: ["3"],
    component: VersionDashboardWindow,
    nestedRoutes: [
      {
        name: "VersionDashboard",
        key: "version_dashboard",
        localeKey: "devices",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: VersionIndexWindow,
      },
      {
        name: "VersionDashboard",
        key: "version_dashboard",
        localeKey: "devices",
        path: "list",
        isNavbarShow: false,
        isSidenavShow: false,
        component: VersionListWindow,
      },
    ],
  },
  {
    title: "Feedback",
    name: "feedback",
    key: "feedback",
    localeKey: "feedback",
    icon: "feedback",
    path: "/feedback",
    isNavbarShow: false,
    isSidenavShow: false,
    component: FeedbackDashboardWindow,
    nestedRoutes: [
      {
        name: "Feedback",
        key: "feedback",
        localeKey: "feedback",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: FeedbackIndexWindow,
      },
    ],
  },
  {
    title: "Analytics",
    name: "analytics",
    key: "analytics",
    localeKey: "analytics",
    icon: "analytics",
    path: "/analytics",
    isNavbarShow: false,
    isSidenavShow: false,
    isProtected: true,
    accessableLevels: ["3"],
    component: AnalyticDashboardWindow,
    nestedRoutes: [
      {
        name: "Analytics",
        key: "analytics",
        localeKey: "analytics",
        path: "",
        isNavbarShow: false,
        isSidenavShow: false,
        component: AnalyticIndexWindow,
      },
    ],
  },
  {
    title: "Not Found",
    name: "404",
    key: "404",
    localeKey: "404",
    path: "*",
    redirectPath: "/",
  },
];

const createRoute = (
  route,
  nRouteIndex,
  pRoute?: any,
  isAdmin: boolean = false,
): ReactElement => {
  const routeInfo: RouteInfoType = {
    key: route.key,
    localeKey: route.localeKey,
    title: route.title,
    name: route.name,
    path: route.path,
    fullPath: pRoute
      ? `${pRoute.fullPath || pRoute.path}/${route.path}`
      : route.path,
    icon: route.icon,
    isNavbarShow: route.isNavbarShow,
    isSidenavShow: route.isSidenavShow,
  };

  return (
    <Route
      key={nRouteIndex}
      path={route.path}
      element={
        route.redirectPath ? (
          <Navigate
            to={
              typeof route.redirectPath === "function"
                ? route.redirectPath(isAdmin)
                : route.redirectPath
            }
          />
        ) : (
          <RouteGuard route={route}>
            <RouteComponentHOC
              key={route.path}
              Component={route.component}
              routeInfo={routeInfo}
            />
          </RouteGuard>
        )
      }
    >
      {route.nestedRoutes?.map((nRoute, nRouteIndex) =>
        createRoute(nRoute, nRouteIndex, route, isAdmin),
      )}
    </Route>
  );
};

const RouteComponentHOC = ({ Component, routeInfo }) => {
  const match = useMatch(routeInfo.fullPath || routeInfo.path);

  const setIsNavbarShow = useSetAtom(isNavbarShowState);
  const setIsSidenavShow = useSetAtom(isSidenavShowState);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (match) {
      setIsNavbarShow(routeInfo.isNavbarShow !== false);
      setIsSidenavShow(routeInfo.isSidenavShow !== false);
    }

    setIsLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{isLoaded && <Component routeInfo={routeInfo} />}</>;
};

const RouteGuard = ({
  route,
  children,
}: {
  route: RouteType;
  children: ReactElement;
}) => {
  const { isProtected, accessableLevels } = route;

  const userRole = useAtomValue(currentUserRoleState);

  const isAccessable =
    userRole && accessableLevels?.includes(userRole.accessLevel);

  if (isProtected && !isAccessable) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const RoutesContainer = memo(
  ({ isAdmin = false }: { isAdmin: boolean }) => {
    return (
      <>
        <Routes>
          {ALL_ROUTES.map((route, routeIndex) => {
            return createRoute(route, routeIndex, null, isAdmin);
          })}
        </Routes>
      </>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

export default RoutesContainer;
