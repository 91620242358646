import { RefObject, useState, useMemo, useEffect } from "react";

const useOnScreen = (ref: RefObject<HTMLElement>, topOffSet?: string) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },

        { rootMargin: topOffSet || "0px" },
      ),
    [topOffSet],
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;
