import { useEffect, useState } from "react";

import { DeviceV2 } from "database/DataTypes";

import useEventTracker from "./useEventTracker";

export type DeviceAction =
  | "controller"
  | "settings"
  | "view location"
  | "logs tab"
  | "detailed logs tab"
  | "power tab"
  | "sensor tab"
  | "alerts tab"
  | "admin logs tab"
  | "info tab"
  | "filter logs"
  | "filter admin logs"
  | "controller get info"
  | "controller get camera settings"
  | "controller get next image time"
  | "controller get file location"
  | "controller get connectivity"
  | "controller get device id"
  | "controller get reporter logs"
  | "controller get camera logs"
  | "controller get updater logs"
  | "controller test image"
  | "controller two step focus"
  | "controller slepp device"
  | "controller terminate application"
  | "controller set setup mode";

type DeviceTrackActionParams = {
  date_range?: string;
  date_range_option?: string;
  log_type?: string;
  tab?: string;
};

export type DeviceTrackAction = (
  actionName: DeviceAction,
  params?: DeviceTrackActionParams,
) => void;

type DeviceTrackInfo = {
  device_id: DeviceV2["id"];
  device_name: DeviceV2["deviceId"];
  device_friendly_name: DeviceV2["friendlyName"];
  page: "status" | "settings";
};

export const useDeviceTracker = (
  device: DeviceV2 | null,
  page: DeviceTrackInfo["page"],
  isTrackView?: boolean,
) => {
  const { trackEvent } = useEventTracker();

  const [info, setInfo] = useState<DeviceTrackInfo>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isViewTracked, setIsViewTracked] = useState(false);

  useEffect(() => {
    if (device) {
      const info = {
        device_id: device.id,
        device_name: device.deviceId,
        device_friendly_name: device.friendlyName,
        page,
      };

      setIsLoaded(true);
      setInfo(info);
    }

    return () => {
      setIsLoaded(false);
      setIsViewTracked(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, page]);

  useEffect(() => {
    if (isTrackView && info && !isViewTracked) {
      trackView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const trackView = () => {
    if (info) {
      trackEvent("device_view", {
        ...info,
      });

      setIsViewTracked(true);
    }
  };

  const trackAction: DeviceTrackAction = (
    actionName: DeviceAction,
    params?: DeviceTrackActionParams,
  ) => {
    if (info) {
      trackEvent("device_action", {
        ...info,
        ...params,
        action: actionName,
      });
    }
  };

  return { trackView, trackAction, isTrackerLoaded: isLoaded };
};
