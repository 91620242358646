import JSZip from "jszip";
import FileSaver from "file-saver";

import { getDropboxObject } from "./DropboxRequests";

import moment from "moment";
import * as piexif from "piexifjs";
import { getDropboxController } from "./DropboxController";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default class ZipDownloader {
  static async generateTimeStampImage(imageBlob, fileName, timestamp) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");

      const imageBlobUrl = URL.createObjectURL(imageBlob);

      const img = document.createElement("img");
      img.crossOrigin = "anonymous";
      img.src = imageBlobUrl;

      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(img, 0, 0);

          // set ratio based on the canvas size, adjust as needed
          const fontSizeRatio = canvas.width / 1000;
          const fontSize = 12 * fontSizeRatio;

          const fontFace = new FontFace(
            "Kode Mono",
            `url(${process.env.PUBLIC_URL}/assets/fonts/KodeMono-VariableFont_wght.ttf)`,
          );

          await fontFace.load().then((font) => {
            document.fonts.add(font);
          });

          ctx.font = `${fontSize}px 'Kode Mono'`;

          const text = moment(timestamp).format("YYYY-MM-DD hh:mm:ss A");

          const textMetrics = ctx.measureText(text);

          const textWidth = textMetrics.width;
          const textHeight =
            textMetrics.fontBoundingBoxAscent +
            textMetrics.fontBoundingBoxDescent;

          const paddingX = 30 * fontSizeRatio;
          const paddingY = 15 * fontSizeRatio;

          const margin = canvas.width * (3 / 100);

          const x = margin;
          const y = canvas.height - margin - textHeight - paddingY / 2;

          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          ctx.fillRect(x, y, textWidth + paddingX, textHeight + paddingY);

          ctx.fillStyle = "rgba(255, 255, 255, 1)";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(
            text,
            x + textWidth / 2 + paddingX / 2,
            y + textHeight / 2 + paddingY / 2,
          );

          const watermarkedImageUrl = canvas.toDataURL("image/jpeg");

          const reader = new FileReader();

          reader.readAsDataURL(imageBlob);

          reader.onload = function () {
            const dataUrl = reader.result;

            const exifObj = piexif.load(dataUrl);

            const exifStr = piexif.dump(exifObj);

            const url = piexif.insert(exifStr, watermarkedImageUrl);

            const base64Data = url.split(",")[1];

            resolve(base64Data);
          };
        }
      };
    });
  }

  static async downloadPartial(
    images,
    zip,
    provider = "0",
    withTimeStamp = false,
  ) {
    const promises = [];

    switch (provider) {
      case "0":
        images.forEach((image, imageIndex) => {
          const url = image.fulRes || image.url;
          let name;

          if (image.fileName != null) {
            const extension = image.fileName.split(".").pop();
            const fileName = image.fileName.replace(`.${extension}`, "");

            name = `${fileName}.jpg`;
          } else {
            name = `file${imageIndex}.jpg`;
          }

          promises.push(
            fetch(url)
              .then((r) =>
                r.blob().then(async (imageBlob) => {
                  if (!withTimeStamp) {
                    return zip.file(name, imageBlob, { binary: true });
                  } else {
                    await this.generateTimeStampImage(
                      imageBlob,
                      name,
                      image.timestamp,
                    )
                      .then((dataPart) => {
                        return zip.file(name, dataPart, { base64: true });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                }),
              )
              .catch((error) => {
                console.error(error);
              }),
          );
        });
        break;

      case "1":
      default:
        images.forEach((image, imageIndex) => {
          promises.push(
            getDropboxController()
              .getImageHighRes(image.path_display, image.name)
              .then(async (val) => {
                const extension = image.name.split(".").pop();
                const fileName = image.name.replace(`.${extension}`, "");

                const name = `${fileName}.jpg`;
                const imageBlob = val.blob;

                if (!withTimeStamp) {
                  return zip.file(name, imageBlob, { binary: true });
                } else {
                  await this.generateTimeStampImage(
                    imageBlob,
                    name,
                    image.timeStamp,
                  )
                    .then((dataPart) => {
                      return zip.file(name, dataPart, { base64: true });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              })
              .catch((error) => {
                console.error(error);
              }),
          );
        });
        break;
    }

    return Promise.all(promises).then(() => {
      return zip;
    });
  }

  static passPartialArray(
    images,
    amount,
    zip,
    provider = "0",
    withTimeStamp = false,
  ) {
    return new Promise((res, err) => {
      if (images.length <= amount) {
        this.downloadPartial(images, zip, provider, withTimeStamp).then(
          (val) => {
            res(val);
          },
        );
      } else {
        this.downloadPartial(
          images.slice(0, amount),
          zip,
          provider,
          withTimeStamp,
        ).then((val) => {
          this.passPartialArray(
            images.slice(amount, images.length),
            amount,
            zip,
            provider,
            withTimeStamp,
          ).then((finalVal) => {
            res(finalVal);
          });
        });
      }
    });
  }

  static async downloader(images, provider = "0", withTimeStamp = false) {
    let localImages = [...images];
    console.log(localImages);

    if (localImages.length > 200) {
      // images = images.slice(0, 200)
      //DefaultToasterNotificationSystem.newPopup("Too many images!", `This will download ${localImages.length} images, which is more than the 200 which can be handled. If more is required, please contact us.`, 2, 4)
      return;
    }
    //DefaultToasterNotificationSystem.newPopup("Starting download", `This will download ${localImages.length} images. This may take a while.`, 0, 4)
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Pictures.zip";

    return this.passPartialArray(
      localImages,
      100,
      zip,
      provider,
      withTimeStamp,
    ).then((val) => {
      localImages = null;
      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver.saveAs(content, zipFilename);
      });
    });
  }

  static downloadFolderZip(device, date, progressCallback) {
    console.log(date);
    // let directory;
    // let selectedDate = new Date(date);
    // switch (device.deviceImageHost) {
    //   case "1":
    //     let downloadDate;
    //     directory = device.externalHostDirectory.toLowerCase().split("/");
    //     console.log(directory);
    //     switch (directory[2]) {
    //       case "photosentinel":
    //         downloadDate =
    //           selectedDate.getFullYear() +
    //           "_" +
    //           ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
    //           "_" +
    //           monthNames[selectedDate.getMonth()].toUpperCase();
    //         console.log(downloadDate);
    //         this.downloadZipFromDirectry(
    //           device.externalHostDirectory + "/" + downloadDate,
    //           progressCallback,
    //         );
    //         break;
    //       case "infinity image sync":
    //         downloadDate =
    //           selectedDate.getFullYear() +
    //           "_" +
    //           ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
    //           "_" +
    //           monthNames[selectedDate.getMonth()];
    //         console.log(downloadDate);
    //         this.downloadZipFromDirectry(
    //           device.externalHostDirectory + "/" + downloadDate,
    //           progressCallback,
    //         );
    //         //year_monthNumber_monthText(first 3 letters)
    //         break;
    //       default:
    //         console.log("Default, not downloading given date");
    //         this.downloadZipFromDirectry(
    //           device.externalHostDirectory,
    //           progressCallback,
    //         );
    //         //year_monthNumber_monthText(first 3 letters)
    //         break;
    //     }
    //     break;
    //   case "0":
    //   default:
    //     console.log("Firebase");
    //     new firebaseClass().getLastImage(device).then((value) => {
    //       if (value != null) {
    //         let locationArray = value.storageLocation.split("/");
    //         directory = "/Apps/Snappy/images/";
    //         for (let i = 0; i < locationArray.length - 3; i++) {
    //           directory += locationArray[i] + "/";
    //         }
    //         directory +=
    //           selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1);
    //         console.log(directory);
    //         this.downloadZipFromDirectry(directory, progressCallback);
    //       }
    //     });
    //     break;
    // }
  }

  static downloadZipFromDirectry(location, progressCallback) {
    getDropboxObject().getZip(location, progressCallback);
    // .then((resp)=>{
    //     resp.blob().then((val)=>{
    //         FileSaver.saveAs(val, "TestDownload.zip");
    //     })
    // })
  }
}
