import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  alpha,
  Box,
  Tooltip,
  TableRowProps,
} from "@mui/material";
import React, { ReactElement } from "react";
import { Column } from "./SettingsTable";

type Row = {
  id: string | number;
  [key: string]: string | number | ReactElement;
};

const DashboardListTable = ({
  isLoading,
  rows,
  columns,
  noDataText = "Data not found.",
  rowOnClick,
  rowProps,
}: {
  isLoading: boolean;
  rows: Row[];
  columns: Column[];
  noDataText?: string;
  rowOnClick?: (row: Row, rowIndex: number) => void;
  rowProps?: (row: Row, rowIndex: number) => TableRowProps;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          width: "100%",
        }}
      >
        <TableHead sx={{ boxShadow: 1 }}>
          <TableRow>
            {columns.map((column) => (
              <Tooltip title={column.description} key={column.id}>
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    width: column.width || "min-content",
                    textWrap: "nowrap",
                    p: 1.5,
                    backgroundColor: ({ palette }) => "#8c8e91",

                    color: ({ palette }) => palette.white.main,
                    borderBottom: 0,
                    borderRight: ({ palette }) =>
                      `0.5px solid ${palette.grey[400]}`,

                    "&:last-child": {
                      borderRight: 0,
                    },
                  }}
                >
                  {column.label}

                  {column.description && (
                    <Typography
                      variant="caption"
                      sx={{ fontSize: 12, verticalAlign: "super" }}
                    >
                      *
                    </Typography>
                  )}
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            <>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={row.id || rowIndex}
                    onClick={
                      rowOnClick ? () => rowOnClick(row, rowIndex) : undefined
                    }
                    hover
                    sx={({ palette }) => ({
                      cursor: "pointer",

                      backgroundColor: palette.white.main,

                      "&:nth-of-type(odd)": {
                        backgroundColor: palette.action.hover,
                      },

                      ":hover": {
                        backgroundColor: "#e3e6e8 !important",
                      },
                    })}
                    {...(rowProps && rowProps(row, rowIndex))}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            borderRight: ({ palette }) =>
                              `0.5px solid ${palette.grey[400]} !important`,

                            borderBottom: 0,
                            p: 1.5,

                            "&:last-child": {
                              borderRight: 0,
                            },
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </>
          ) : (
            <TableRow sx={{ position: "relative" }}>
              <TableCell colSpan={columns.length}>
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "max-content",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: ({ palette }) => palette.grey[600] }}
                  >
                    {isLoading ? "Loading..." : noDataText}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardListTable;
