import { initializeApp } from "firebase/app";

import {
  getStorage,
  ref as refStore,
  getDownloadURL as firebaseGetDownloadURL,
  connectStorageEmulator,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";

import { getAuth, connectAuthEmulator } from "firebase/auth";

import {
  collection,
  getDocs,
  getFirestore,
  doc,
  getDoc,
  query,
  limit,
  where,
  onSnapshot,
  deleteDoc,
  setDoc,
  connectFirestoreEmulator,
  Query,
  WhereFilterOp,
  CollectionReference,
  DocumentData,
} from "firebase/firestore";

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { DeviceV2, GalleryV2, User } from "./DataTypes";
import _ from "lodash";

import { _AuthController } from "./_controllers/_AuthController";
import { _FunctionController } from "./_controllers/_FunctionController";
import { _ClientController } from "./_controllers/_ClientController";
import { _DeviceController } from "./_controllers/_DeviceController";
import { _GalleryController } from "./_controllers/_GalleryController";
import { _JobSiteController } from "./_controllers/_JobSiteController";
import { _UserController } from "./_controllers/_UserController";
import { _VersionController } from "./_controllers/_VersionController";
import { _FeedbackController } from "./_controllers/_FeedbackController";
import { _AnalyticController } from "./_controllers/_AnalyticController";
import { _ImageController } from "./_controllers/_ImageController";

type CollectionPath =
  | "adminEvents"
  | "clients"
  | "comments"
  | "deviceEvents"
  | "deviceInfos"
  | "devices"
  | `devices/${string}/DeviceIntervalSettings`
  | `devices/${string}/DeviceDetails`
  | `devices/${string}/DeviceLogs`
  | `devices/${string}/DeviceSettings`
  | "feedbacks"
  | "galleries"
  | `galleries/${string}/GallerySettings`
  | `galleries/${string}/GalleryIntervalSettings`
  | "imageExtras"
  | "images"
  | "jobSites"
  | "photoSentinelImages"
  | "userRoles"
  | "users"
  | "versions"
  | "webAppSetup";

export type CreateOrUpdateDocumentOptions = {
  returnDocument?: boolean;
};

type GetDocumentOptions = {
  includeDocId?: boolean;
};

const firebaseConfig =
  process.env.NODE_ENV === "test"
    ? {
        apiKey: "test",
        projectId: "project-snappy-test",
        storageBucket: "project-snappy-test.appspot.com",
      }
    : {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID,
      };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const db = getFirestore();
const functions = getFunctions(app, "australia-southeast1");
const storage = getStorage(app);
const storageRef = refStore(storage, "images");

const isLocalEmulator =
  process.env.REACT_APP_ENV === "development" &&
  process.env.REACT_APP_IS_LOCAL_DEV === "true";

export const getFirebaseController = () => {
  return new FirebaseController();
};

export const getDownloadURL = async (ref) => {
  let url = await firebaseGetDownloadURL(ref);

  if (
    process.env.REACT_APP_ENV === "development" &&
    url.includes(process.env.REACT_APP_DEV_HOST_IP as string)
  ) {
    const oldProtocol = `http://`;
    const newProtocol = `https://`;

    url = url.replace(oldProtocol, newProtocol);

    const oldDomain = `${process.env.REACT_APP_DEV_HOST_IP}`;
    const newDomain = `${process.env.REACT_APP_DEV_HOST_NAME}`;

    url = url.replace(oldDomain, newDomain);

    // const oldPort = `:9005`;
    // const newPath = `/snappy/storage`;

    // url = url.replace(oldPort, newPath);
  }

  return url;
};

export class FirebaseController {
  private static _instance: FirebaseController | null;

  signedIn!: boolean;
  deviceList?: DeviceV2[];
  galleryList?: GalleryV2[];
  currentUser?: User | null;
  dropboxRefreshToken?: string;
  Auth!: _AuthController;
  Client!: _ClientController;
  Device!: _DeviceController;
  Gallery!: _GalleryController;
  JobSite!: _JobSiteController;
  User!: _UserController;
  Version!: _VersionController;
  Feedback!: _FeedbackController;
  Analytic!: _AnalyticController;
  Image!: _ImageController;
  Callable!: _FunctionController;

  constructor() {
    if (FirebaseController._instance) {
      return FirebaseController._instance;
    } else {
      FirebaseController._instance = this;
    }

    if (process.env.NODE_ENV === "test" || isLocalEmulator) {
      console.log("TEST SERVER ACTIVE");

      if (auth["_canInitEmulator"]) {
        connectAuthEmulator(auth, "http://127.0.0.1:9099", {
          disableWarnings: false,
        });

        connectStorageEmulator(storage, "127.0.0.1", 9199);
        connectFirestoreEmulator(db, "127.0.0.1", 8080);

        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      }
    } else if (process.env.REACT_APP_ENV === "development") {
      console.log("DEV SERVER ACTIVE");

      // fixed hot-reload error
      if (auth["_canInitEmulator"]) {
        try {
          connectAuthEmulator(auth, "https://lab.beerlabs.com.au", {
            disableWarnings: true,
          });

          connectStorageEmulator(storage, "lab.beerlabs.com.au", 9005);

          connectFirestoreEmulator(db, "lab.beerlabs.com.au", 9003);

          connectFunctionsEmulator(functions, "lab.beerlabs.com.au", 9002);
        } catch (error) {
          console.error(error);
        }

        db["_settings"]["ssl"] = true;
        storage["_protocol"] = "https";
        functions["emulatorOrigin"] = functions["emulatorOrigin"].replace(
          "http",
          "https",
        );
      }
    }

    this.signedIn = false;
    this.deviceList = [];
    this.galleryList = [];
    this.currentUser = null;
    this.dropboxRefreshToken = "";

    this.Analytic = new _AnalyticController(this);
    this.Auth = new _AuthController(this);
    this.Callable = new _FunctionController(this);
    this.Client = new _ClientController(this);
    this.Device = new _DeviceController(this);
    this.Feedback = new _FeedbackController(this);
    this.Gallery = new _GalleryController(this);
    this.Image = new _ImageController(this);
    this.JobSite = new _JobSiteController(this);
    this.User = new _UserController(this);
    this.Version = new _VersionController(this);
  }

  getDb() {
    return db;
  }

  getAuth() {
    return auth;
  }

  getFunctions() {
    return functions;
  }

  getStorage() {
    return storage;
  }

  getStorageRef() {
    return storageRef;
  }

  //  helpers function
  async getDocumentWithId<T>(
    collectionPath: CollectionPath,
    id: string | number,
  ) {
    if (id) {
      const docSnap = await getDoc(doc(db, collectionPath, id.toString()));

      if (docSnap.exists()) {
        return docSnap.data() as T;
      } else {
        throw new Error(`Document Not Found: ${collectionPath}/${id}`);
      }
    }
  }

  async getDocumentWithValue<T>(
    collectionPath: CollectionPath,
    fieldName: string,
    value: string | number | any[],
  ) {
    let operator: WhereFilterOp = "==";

    if (_.isArray(value)) {
      operator = "in";
    }

    const q = query(
      collection(db, collectionPath),
      where(fieldName, operator, value),
      limit(1),
    );

    return this.getDocumentWithQuery<T>(q);
  }

  async getDocumentWithQuery<T>(query: Query<unknown>) {
    const data = await this.getDocumentListWithQuery<T>(query);

    return data[0] || null;
  }

  async getDocumentList<T>(collectionPath: CollectionPath) {
    return this.getDocumentListWithQuery<T>(collection(db, collectionPath));
  }

  async getDocumentHash<T>(collectionPath: CollectionPath) {
    const listData = await this.getDocumentListWithQuery<T>(
      collection(db, collectionPath),
      { includeDocId: true },
    );

    const hash = {};

    // not efficient, better way would be passing an option to getDocumentListWithQuery and return either list or hash data based on it
    // but doing this saves time to bypass typescript conditional return type
    listData.forEach((item) => {
      const { _id, ...rest } = item as T & { _id: string };

      hash[_id] = rest;
    });

    return hash as { [key: string]: T };
  }

  async getDocumentListWithValue<T>(
    collectionPath: CollectionPath,
    fieldName: string,
    value: string | number | any[],
  ) {
    let operator: WhereFilterOp = "==";

    if (_.isArray(value)) {
      operator = "in";

      if (value.length === 0) {
        return [] as T[];
      }
    }

    const q = query(
      collection(db, collectionPath),
      where(fieldName, operator, value),
    );

    return this.getDocumentListWithQuery<T>(q);
  }

  async getDocumentListWithQuery<T>(
    query: Query<unknown>,
    options: GetDocumentOptions = {},
  ) {
    const parsedOptions = this.parseGetDocumentOptions(options);
    const querySnapshot = await getDocs(query);

    const documents: T[] = [];

    if (querySnapshot) {
      querySnapshot.forEach((snap) => {
        const value = snap.data() as T;

        if (parsedOptions.includeDocId) {
          value["_id"] = snap.id;
        }

        documents.push(value);
      });
    }

    return documents;
  }

  async getDocuments<T>(
    collectionPath: CollectionPath,
    ids?: number[] | string[],
  ) {
    if (ids) {
      if (ids.length > 0) {
        // 'IN' supports up to 30 comparison values. So send multiple batch requests of 30 ids
        const idsChunk = _.chunk<number | string>(ids, 30);
        const dataPromises: Promise<T[]>[] = [];

        idsChunk.forEach((ids) => {
          dataPromises.push(
            this.getDocumentListWithValue<T>(collectionPath, "id", ids),
          );
        });

        return await Promise.all(dataPromises).then((data) => {
          return data.flat();
        });
      } else {
        return [];
      }
    } else {
      return await this.getDocumentList<T>(collectionPath);
    }
  }

  async createOrUpdateDocument<T = any>(
    data: T,
    collectionPath: CollectionPath,
    id: string | number,
    { returnDocument = false }: CreateOrUpdateDocumentOptions = {},
  ) {
    const updateRef = this.getDocRef<T>(collectionPath, id.toString());

    return setDoc(updateRef, data, { merge: true })
      .then(() => {
        if (returnDocument) {
          return getDoc(updateRef)
            .then((doc) => {
              if (doc.exists()) {
                return doc.data();
              } else {
                throw new Error("Document not found.");
              }
            })
            .catch((err) => {
              throw new Error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async deleteDocument(collectionPath: CollectionPath, id: number | string) {
    return await deleteDoc(doc(db, collectionPath, id.toString()));
  }

  getNewDocumentId() {
    // depreating, please use getNewDocumentIdString for new collection

    const timeSinceEpoch = new Date().getTime();
    const randomValue = 100 + Math.random() * 900;

    return Number(`${timeSinceEpoch}${Math.floor(randomValue)}`);
  }

  getNewDocumentIdString(collectionPath: CollectionPath) {
    const docRef = doc(collection(db, collectionPath));

    return docRef.id;
  }

  parseGetDocumentOptions(rawOptions: GetDocumentOptions = {}) {
    const parsedOptions = {
      includeDocId: _.isUndefined(rawOptions.includeDocId)
        ? false
        : rawOptions.includeDocId,
    };

    return parsedOptions;
  }

  listenDocumentWithQuery<T>(
    query: Query<T>,
    callback: (error: null, data: T | null) => void,
  ) {
    const docSnap = query;

    return onSnapshot<T>(docSnap, (snap) => {
      if (snap) {
        const returnValue: T[] = [];

        snap.forEach((eachValue) => {
          returnValue.push(eachValue.data());
        });

        callback(null, returnValue[0]);
      } else {
        callback(null, null);
      }
    });
  }

  listenDocument(collectionPath: CollectionPath, id, callback) {
    const docSnap = doc(db, collectionPath, `${id}`);

    return onSnapshot(docSnap, (snap) => {
      if (snap.exists()) {
        callback(snap.data());
      } else {
        callback(new Error("Data not found!"));
      }
    });
  }

  getDocRef<T = DocumentData>(collectionPath: CollectionPath, id) {
    return doc<T>(this.getColRef<T>(collectionPath), id.toString());
  }

  getColRef<T = DocumentData>(collectionPath: CollectionPath) {
    return collection(db, collectionPath) as CollectionReference<T>;
  }

  async deleteFolder(path: string) {
    if (!path || path === "/") {
      return;
    }

    const storageRef = refStore(storage, path);

    const promises: Promise<any>[] = [];

    await listAll(storageRef).then((res) => {
      res.items.forEach((itemRef) => {
        promises.push(
          this.deleteFile(itemRef.fullPath).catch((err) => console.error(err)),
        );
      });
    });

    return Promise.all(promises);
  }

  async deleteFile(pathToFile: string) {
    const storageRef = refStore(storage, pathToFile);

    return deleteObject(storageRef);
  }

  uploadFileListener(file: Blob, location: string = "") {
    const fileLocation = `${location}/${file.name}`;

    const storageRef = refStore(storage, fileLocation);

    const uploadTask = uploadBytesResumable(storageRef, file);

    return uploadTask;
  }
}
