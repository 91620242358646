import { useEffect, useState } from "react";

import useEventTracker from "hooks/eventTracker/useEventTracker";

import { GalleryV2 } from "database/DataTypes";

type GalleryTrackInfo = {
  gallery_id: GalleryV2["id"];
  gallery_name: GalleryV2["galleryName"];
  page: "settings";
};

export const useGalleryTracker = (
  gallery: GalleryV2 | null,
  page: GalleryTrackInfo["page"],
  isTrackView?: boolean,
) => {
  const { trackEvent } = useEventTracker();

  const [info, setInfo] = useState<GalleryTrackInfo>();

  const [isViewTracked, setIsViewTracked] = useState(false);

  useEffect(() => {
    if (gallery) {
      const info = {
        gallery_id: gallery.id,
        gallery_name: gallery.galleryName,
        gallery_job_site: gallery.jobSite,
        page,
      };

      setInfo(info);
    }

    return () => {
      setIsViewTracked(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery, page]);

  useEffect(() => {
    if (isTrackView && info && !isViewTracked) {
      trackView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const trackView = () => {
    if (info) {
      trackEvent("gallery_view", {
        ...info,
      });

      setIsViewTracked(true);
    }
  };

  return { trackView };
};
