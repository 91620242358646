/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

export type PxToRemFunction = (number: number, baseNumber?: number) => string;

const pxToRem: PxToRemFunction = (number, baseNumber = 16) => {
  return `${number / baseNumber}rem`;
};

export default pxToRem;
