import { useEffect, useState } from "react";

import useEventTracker from "hooks/eventTracker/useEventTracker";

export type DashboardAction =
  | "search"
  | "sort name"
  | "sort email"
  | "sort friendly name"
  | "sort device name"
  | "sort gallery name"
  | "sort interval"
  | "sort battery"
  | "sort solar"
  | "sort capture"
  | "sort alert"
  | "sort site"
  | "sort version"
  | "display all galleries"
  | "view grid"
  | "view list"
  | "delete version"
  | "promote version";

export type DashboardTrackAction = (actionName: DashboardAction) => void;

type DashboardTrackInfo = {
  page: "devices" | "galleries" | "clients" | "users" | "versions";
};

export const useDashboardTracker = (
  page: DashboardTrackInfo["page"],
  isTrackView?: boolean,
) => {
  const { trackEvent } = useEventTracker();

  const [info, setInfo] = useState<DashboardTrackInfo>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isViewTracked, setIsViewTracked] = useState(false);

  useEffect(() => {
    const info = {
      page,
    };

    setIsLoaded(true);
    setInfo(info);

    return () => {
      setIsLoaded(false);
      setIsViewTracked(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (isTrackView && info && !isViewTracked) {
      trackView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const trackView = () => {
    if (info) {
      trackEvent("dashboard_view", {
        ...info,
      });

      setIsViewTracked(true);
    }
  };

  const trackAction: DashboardTrackAction = (actionName) => {
    if (info) {
      trackEvent("dashboard_action", {
        ...info,
        action: actionName,
      });
    }
  };

  return { trackView, trackAction, isTrackerLoaded: isLoaded };
};
