import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { Client, GalleryV2, JobSite } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import DashboardContainer from "components/Dashboard/DashboardContainer";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";
import { Column } from "components/Dashboard/SettingsTable";

import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";

import _ from "lodash";
import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import DataChips from "components/Dashboard/DataChips";
import DashboardListTable from "components/Dashboard/DashboardListTable";
import { orderByIgnoreCase } from "utils/display";
import useLocalStorage from "hooks/useLocalStorage";

const SORT_OPTIONS = ["Name"];
const DISPLAY_OPTIONS = ["Active", "Archived"];

type GetJobSiteListOptions = {
  isShowArchived?: boolean;
  isShowActive?: boolean;
};

const ListView = ({
  displayJobSiteList,
  headerRef,
  isLoading,
  clientsLookup,
}: {
  displayJobSiteList: JobSite[];
  headerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  clientsLookup: { [id: number]: Client };
}) => {
  const navigate = useNavigate();

  const columns: Column[] = [
    { id: "name", label: "Name", width: "30%" },
    { id: "associatedClients", label: "Associated Clients", width: "40%" },
    { id: "address", label: "Address", width: "30%" },
  ];

  const rows = displayJobSiteList.map((site, siteIndex) => {
    const clients = orderByIgnoreCase(
      site.associatedClients.map((siteId) => {
        return clientsLookup[siteId as number];
      }),
      "name",
    );

    return {
      id: site.id?.toString() || siteIndex,
      name: site.name || "-",
      address: site.physicalAddress,
      associatedClients: (
        <DataChips
          items={clients}
          labelkey="name"
          isItemActive={(item) => {
            return site.associatedClients[0] === item.id;
          }}
          itemLink={(item) => {
            return `/clients/${item.id}`;
          }}
          openNewTab={false}
        />
      ),
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <DashboardListTable
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        noDataText="No job site found."
        rowOnClick={(row) => {
          navigate(`/job-sites/${row.id}`);
        }}
      />
    </Box>
  );
};

const JobSiteIndexWindow = () => {
  const [displayJobSiteList, setDisplayJobSiteList] = useState<JobSite[]>([]);
  const [jobSiteList, setJobSiteList] = useState<JobSite[]>([]);

  const [currentSortType, setCurrentSortType] = useState<SortType>({
    type: SORT_OPTIONS[0],
    isAsc: true,
  });

  const clientList = useAtomValue(clientListCacheState);

  const clientsLookup = useMemo(() => {
    return _.keyBy(clientList, "id");
  }, [clientList]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const search = useAtomValue(searchState);

  const { trackAction } = useDashboardTracker("clients", true);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch]);

  useEffect(() => {
    getJobSiteList(getCurrentJobSiteDisplayOptions());
  }, []);

  const getJobSiteList = async ({
    isShowActive,
  }: GetJobSiteListOptions = {}) => {
    setIsLoading(true);

    try {
      const jobSiteList = await getFirebaseController().JobSite.getJobSites({
        isArchived: !isShowActive,
      });

      const sortedList = orderByIgnoreCase(jobSiteList, "name");

      setDisplayJobSiteList(sortedList);
      setJobSiteList(sortedList);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleSearch = () => {
    let newJobSiteList = _.cloneDeep(jobSiteList);

    if (search.value) {
      trackAction("search");

      newJobSiteList = newJobSiteList.filter((site) => {
        const clientNames = site.associatedClients.map((clientId) => {
          return clientsLookup[clientId as number].name;
        });

        const searchFieldValues = [
          site.name,
          ...clientNames,
          site.physicalAddress,
        ];

        return searchFieldValues.some((value) => {
          return (
            value &&
            _.toLower(value.toString()).includes(_.toLower(search.value))
          );
        });
      });
    }

    setDisplayJobSiteList(newJobSiteList);
  };

  const handleSort = (sortType: string) => {
    const isAsc =
      currentSortType.type === sortType ? !currentSortType.isAsc : false;
    const order = isAsc ? "asc" : "desc";

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    let newClientList = _.cloneDeep(displayJobSiteList);

    switch (sortType) {
      case "Name":
        trackAction("sort name");

        newClientList = orderByIgnoreCase(newClientList, "name", order);
        break;

      case "Email":
        trackAction("sort email");

        newClientList = orderByIgnoreCase(newClientList, "email", order);
        break;

      default:
        break;
    }

    setDisplayJobSiteList(newClientList);
  };

  const headerRef = useRef<HTMLDivElement>(null);

  const [currentDisplayOptions, setCurrentDisplayOptions] = useLocalStorage<
    string[]
  >("job-site-display-type", ["Active"]);

  const handleDisplay = async (displayOption: string) => {
    const newCurrenDisplayOptions = [displayOption];

    setCurrentDisplayOptions(newCurrenDisplayOptions);

    await getJobSiteList(
      getCurrentJobSiteDisplayOptions(newCurrenDisplayOptions),
    );
  };

  const getCurrentJobSiteDisplayOptions = useCallback(
    (displayOptions?: string[]) => {
      const options: GetJobSiteListOptions = {};

      (displayOptions || currentDisplayOptions).forEach((option) => {
        options.isShowActive = option === "Active";
      });

      return options;
    },
    [currentDisplayOptions],
  );

  return (
    <DashboardContainer NavbarProps={{ title: "Job Site Dashboard" }}>
      <DashboardHeader
        sortOptions={SORT_OPTIONS}
        currentSortType={currentSortType}
        handleSort={handleSort}
        displayOptions={DISPLAY_OPTIONS}
        currentDisplayOptions={currentDisplayOptions}
        handleDisplay={handleDisplay}
        buttons={[
          {
            label: "+ Add New Job Site",
            onClick: () => navigate("./new"),
          },
        ]}
      />

      <ListView
        displayJobSiteList={displayJobSiteList}
        headerRef={headerRef}
        isLoading={isLoading}
        clientsLookup={clientsLookup}
      />
    </DashboardContainer>
  );
};

export default JobSiteIndexWindow;
