import { forwardRef, useState, useEffect, memo } from "react";
import { BoxProps, Box } from "@mui/material";

const ImageWithFallBack = forwardRef<
  HTMLImageElement,
  BoxProps<"img"> & { fallbackSrc: string }
>((props, ref) => {
  const { src, fallbackSrc, onError, ...restProps } = props;

  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    setImageSrc(src || "");
  }, [src]);

  return (
    <Box
      component="img"
      ref={ref}
      {...restProps}
      src={imageSrc}
      onError={(e) => {
        if (imageSrc === fallbackSrc) {
          e.currentTarget.style.backgroundImage = "";

          console.error(`Fallback image not found. src: ${fallbackSrc}`);

          if (onError) {
            onError(e);
          }
        } else {
          console.error(`Image not found. src: ${src}`);

          setImageSrc(fallbackSrc);
        }
      }}
    />
  );
});

export default memo(ImageWithFallBack);
