import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Slider,
  Stack,
  Switch,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";

import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import GradientIcon from "@mui/icons-material/Gradient";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { TopToolBar } from "Windows/ImageViewer/ImageViewerWindow";
import LDSRingLoader from "components/Loaders/LDSRingLoader";

import { getFirebaseController } from "database/FirebaseController";
import { getDropboxController } from "database/DropboxController";
import {
  DropBoxImage,
  FirebaseImage,
  GalleryV2,
  RoleAccessLevel,
  UnixEpoch,
} from "database/DataTypes";
import ImageHandlerV2 from "database/ImageHandlerV2";

import type { Image, Thumbnail } from "database/ImageHandlerV2";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

import moment, { Moment } from "moment";
import _ from "lodash";

import { useAtomValue } from "jotai";
import { currentGalleryJobsiteState } from "states/imageViewer";

import useTimelapsePlayerTracker from "hooks/eventTracker/useTimelapsePlayerTracker";
import useAccess from "hooks/useAccess";
import pLimit from "p-limit";

let _animationId = 0;
let _currentPreviewIndex = 0;
let _previousPreviewIndex = 0;

const SEEK_BAR_EXPAND_HEIGHT = 30;
const SEEK_BAR_HEIGHT = 5;
const SEEK_BAR_TRIGGER_RANGE = 30;
const FRAME_RATE = 25;
const MAX_FRAMES_SIZE = 1500;

const FRAME_BLENDING_OPTIONS = [
  { label: "Off", value: "" },
  // { label: "Motion Interpolation", value: "minterpolate" },
  { label: "Time Blend", value: "tblend=all_mode=average" },
];

const GENERATE_OPTIONS = [
  { label: "Preview", value: "preview" },
  // { label: "Preview (High Res)", value: "preview:high-res" },
  { label: "Video", value: "video" },
  {
    label: "Video (High Res)",
    value: "video:high-res",
    accessableLevel: ["3"],
  },
];

const DATE_RANGE_OPTIONS = ["Past Day", "Past Week", "Past Month", "Custom"];

const limit = pLimit(50);

const PlayerSeekBar = forwardRef<any, any>((props, ref) => {
  const { onChange, totalDuration, isDisable } = props;

  const [value, setValue] = useState(0);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    let timeout;

    if (isExpand) {
      timeout = setTimeout(() => {
        setIsExpand(false);
      }, 5000);
    } else {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isExpand]);

  useImperativeHandle(ref, () => ({
    setProgress(value: number) {
      setValue(value);
    },
  }));

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (isExpand) {
          setIsExpand(false);
        }
      }}
    >
      <Box
        id="seek-bar-container"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          id="seek-bar-expand-trigger"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: SEEK_BAR_TRIGGER_RANGE,
            opacity: 0,
            transform: `translateY(-${SEEK_BAR_TRIGGER_RANGE}px)`,
          }}
          onPointerDown={(e) => {
            e.stopPropagation();

            if (e.pointerType !== "mouse") {
              if (!isDisable) {
                setIsExpand(true);
              }
            }
          }}
          onPointerEnter={(e) => {
            if (e.pointerType === "mouse") {
              if (!isDisable) {
                setIsExpand(true);
              }
            }
          }}
          onPointerOut={(e) => {
            if (e.pointerType === "mouse") {
              setIsExpand(false);
            }
          }}
        />

        <Slider
          id="seek-bar"
          disabled={isDisable}
          ref={ref}
          onMouseEnter={() => {
            if (!isDisable) {
              setIsExpand(true);
            }
          }}
          onMouseLeave={() => {
            setIsExpand(false);
          }}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => {
            const sec = Number(((value / 100) * totalDuration).toFixed(0));

            return moment.utc(sec * 1000).format("mm:ss");
          }}
          sx={{
            transform: isExpand
              ? `translateY(-${SEEK_BAR_EXPAND_HEIGHT}px)`
              : `translateY(-${SEEK_BAR_HEIGHT}px)`,
            transition: "transform 0.2s ease",
            background: ({ palette }) =>
              isDisable
                ? `${alpha(palette.grey[900], 1)} !important`
                : alpha(palette.grey[300], 0.6),
            borderRadius: 0,
            padding: "0px !important",
            height: SEEK_BAR_EXPAND_HEIGHT,

            ".MuiSlider-rail": {
              height: SEEK_BAR_EXPAND_HEIGHT,
            },

            ".MuiSlider-track": {
              background: ({ palette }) =>
                isDisable
                  ? `${alpha(palette.grey[600], 0.2)} !important`
                  : alpha(palette.white.main, 0.5),
              border: 0,
              borderRight: ({ palette }) => `1px solid ${palette.white.main}`,
              transition: "none",
            },

            ".MuiSlider-thumb": {
              boxShadow: "none !important",
              height: SEEK_BAR_EXPAND_HEIGHT,
              width: 10,
              borderRadius: 0,
              transition: "none",
            },
          }}
          value={value}
          onChange={(e, v) => {
            setValue(v as number);
            onChange(v);
          }}
          defaultValue={0}
          color="secondary"
        />
      </Box>
    </ClickAwayListener>
  );
});

const MenuWrapper = ({ anchorEl, open, onClose, children }: MenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: 0,
        },

        ".MuiList-root": {
          p: 0,
        },
      }}
    >
      {children}
    </Menu>
  );
};

const BottomToolBar = forwardRef<any, any>(
  (
    {
      isPlaying,
      isEnded,
      isLoading,
      isFFmpegLoaded,
      handlePlay,
      handleSkip,
      handleGenerate,
      dateRange,
      setDateRange,
      dateRangeOption,
      setDateRangeOption,
      totalImageCount,
      videoSrc,
      blendingOption,
      setBlendingOption,
      imageHandler,
      seekBarRef,
      handleSeek,
      videoDuration,
      isPreview,
      previewUrls,
      commitedInfo,
      maxDateRange,
    },
    ref,
  ) => {
    const [dateOptionMenuAnchorEl, setDateOptionMenuAnchorEl] =
      useState<null | HTMLElement>(null);
    const [startDateMenuAnchorEl, setStartDateMenuAnchorEl] =
      useState<null | HTMLElement>(null);
    const [endDateMenuAnchorEl, setEndDateMenuAnchorEl] =
      useState<null | HTMLElement>(null);
    const [blendingMenuAnchorEl, setBlendingMenuAnchorEl] =
      useState<null | HTMLElement>(null);
    const [generateMenuAnchorEl, setGenerateMenuAnchorEl] =
      useState<null | HTMLElement>(null);

    const videoTimeStampRef = useRef<HTMLParagraphElement>(null);

    const { isCommited, isDateChanged, isEndDateChanged, isStartDateChanged } =
      commitedInfo;

    const isDateRangeMenuOpen = Boolean(dateOptionMenuAnchorEl);
    const isStartDateMenuOpen = Boolean(startDateMenuAnchorEl);
    const isEndDateMenuOpen = Boolean(endDateMenuAnchorEl);
    const isBlendingMenuOpen = Boolean(blendingMenuAnchorEl);
    const isGenerateMenuOpen = Boolean(generateMenuAnchorEl);

    const { trackAction } = useTimelapsePlayerTracker(imageHandler);

    useImperativeHandle(ref, () => ({
      setTimeStamp(currentTime, duration) {
        if (videoTimeStampRef.current) {
          const time = moment.utc(currentTime * 1000).format("mm:ss.SSS");
          const total = moment.utc(duration * 1000).format("mm:ss.SSS");

          videoTimeStampRef.current.innerText = `${time} / ${total}`;
        }
      },
    }));

    const handleDateRangeMenuOpen = (
      event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
    ) => {
      setDateOptionMenuAnchorEl(event.currentTarget);
    };

    const handleDateRangeMenuClose = () => {
      setDateOptionMenuAnchorEl(null);
    };

    const handleStartDateMenuOpen = (
      event: React.MouseEvent<HTMLDivElement>,
    ) => {
      setStartDateMenuAnchorEl(event.currentTarget);

      trackAction("date picker");
    };

    const handleStartDateMenuClose = () => {
      setStartDateMenuAnchorEl(null);
    };

    const handleEndDateMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
      setEndDateMenuAnchorEl(event.currentTarget);

      trackAction("date picker");
    };

    const handleEndDateMenuClose = () => {
      setEndDateMenuAnchorEl(null);
    };

    const handleBlendingMenuOpen = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      setBlendingMenuAnchorEl(event.currentTarget);
    };

    const handleBlendingMenuClose = () => {
      setBlendingMenuAnchorEl(null);
    };

    const handleGenerateMenuOpen = (
      event: React.MouseEvent<HTMLDivElement>,
    ) => {
      setGenerateMenuAnchorEl(event.currentTarget);
    };

    const handleGenerateMenuClose = () => {
      setGenerateMenuAnchorEl(null);
    };

    const handleDateRangeOptionChange = (option) => {
      const latestDate = maxDateRange[1];

      switch (option) {
        case "Past Day": {
          // const end = moment.unix(currentDate).endOf("days").unix();

          // dateRange = [moment.unix(end).subtract(24, "hours").unix(), end];

          dateRange = [
            moment.unix(latestDate).subtract(1, "days").startOf("days").unix(),
            moment.unix(latestDate).endOf("days").unix(),
          ];

          setDateRange(dateRange);

          break;
        }

        case "Past Week":
          dateRange = [
            moment.unix(latestDate).subtract(1, "weeks").startOf("days").unix(),
            moment.unix(latestDate).endOf("days").unix(),
          ];

          setDateRange(dateRange);

          break;
        case "Past Month":
          dateRange = [
            moment
              .unix(latestDate)
              .subtract(1, "months")
              .startOf("days")
              .unix(),
            moment.unix(latestDate).endOf("days").unix(),
          ];

          setDateRange(dateRange);

          break;
        default:
          break;
      }

      setDateRangeOption(option);

      handleDateRangeMenuClose();
    };

    const handleBlendingOptionChange = (option) => {
      setBlendingOption(option.value);
      handleBlendingMenuClose();
    };

    const handleGenerateOptionChange = (option) => {
      const [name, type] = option.value.split(":");

      handleGenerate(name === "preview", type === "high-res");

      handleGenerateMenuClose();
    };

    const handleDateInputChange = (date: Moment, name: "end" | "start") => {
      let [start, end] = dateRange;

      if (name === "start") {
        start = date.startOf("days").unix();

        if (start > end) {
          end = date.endOf("days").unix();
        }

        handleStartDateMenuClose();
      } else {
        end = date.endOf("days").unix();

        if (end < start) {
          start = date.startOf("days").unix();
        }

        handleEndDateMenuClose();
      }

      setDateRangeOption(_.last(DATE_RANGE_OPTIONS));
      setDateRange([start, end]);
    };

    const smallScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm"),
    );

    const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("md"),
    );

    const disabled =
      isLoading ||
      (isPreview ? previewUrls.length === 0 : !videoSrc || !isFFmpegLoaded);

    const { isAccessable } = useAccess();

    return (
      <Box
        id="bottom-tool-bar-container"
        sx={({ palette, customConfig, zIndex, transitions }) => ({
          position: "fixed",
          top: "auto",
          bottom: 0,
          width: "100%",
          boxShadow: 1,
          backgroundColor: palette.secondary.main,
          zIndex: zIndex.appBar,
          color: isLoading ? palette.grey[800] : palette.grey[400],
        })}
      >
        <Box sx={{ postion: "relative" }}>
          <PlayerSeekBar
            ref={seekBarRef}
            isDisable={isLoading || (previewUrls.length === 0 && !videoSrc)}
            onChange={handleSeek}
            totalDuration={
              isPreview ? previewUrls.length / FRAME_RATE : videoDuration
            }
          />

          <Grid
            id="bottom-tool-bar"
            container
            alignItems="center"
            justifyContent="space-between"
            height={"100%"}
            sx={{
              position: "relative",
              background: ({ palette }) => palette.secondary.main,
              flexWrap: "nowrap",
              height: ({ customConfig }) =>
                customConfig.imageViewerNavbarHeight,
              px: {
                xs: 2,
                md: 2,
                lg: 14,
                xl: 20,
              },
            }}
          >
            <Grid
              item
              xs={2}
              md={5}
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: 8, sm: 12 }, mr: "auto" }}
                ref={videoTimeStampRef}
              >
                {`${totalImageCount} images`}
              </Typography>

              {mediumScreenAndUp && (
                <>
                  <MenuWrapper
                    anchorEl={dateOptionMenuAnchorEl}
                    open={isDateRangeMenuOpen}
                    onClose={handleDateRangeMenuClose}
                  >
                    {DATE_RANGE_OPTIONS.map((option) => {
                      return (
                        <MenuItem
                          selected={option === dateRangeOption}
                          key={option}
                          dense
                          onClick={() => handleDateRangeOptionChange(option)}
                        >
                          {option}
                        </MenuItem>
                      );
                    })}
                  </MenuWrapper>

                  <MenuWrapper
                    anchorEl={startDateMenuAnchorEl}
                    open={isStartDateMenuOpen}
                    onClose={handleStartDateMenuClose}
                  >
                    <Box
                      sx={{
                        background: ({ palette }) => palette.secondary.light,
                        p: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ color: "white !important", fontSize: 14 }}
                      >
                        From Date
                      </Typography>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                          // slots={{
                          //   day: CustomPickersDay,
                          // }}
                          onChange={(date) => {
                            if (date) {
                              handleDateInputChange(date as Moment, "start");
                            }
                          }}
                          value={moment.unix(dateRange[0])}
                          minDate={moment.unix(maxDateRange[0])}
                          maxDate={moment.unix(maxDateRange[1])}
                        />
                      </LocalizationProvider>
                    </Box>
                  </MenuWrapper>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      background: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.primary.dark, 0.1)
                          : isDateChanged
                          ? alpha(palette.primary.dark, 0.6)
                          : alpha(palette.primary.dark, 0.3),

                      color: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.white.main, 0.2)
                          : isDateChanged
                          ? alpha(palette.white.main, 0.9)
                          : alpha(palette.white.main, 0.7),

                      pointerEvents: isLoading ? "none" : "auto",

                      transition: ({ transitions }) =>
                        transitions.create("all"),
                      height: "max-content",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      position: "relative",
                      cursor: "pointer",
                      mr: 2,

                      ":hover": {
                        background: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.primary.dark, 0.1)
                            : alpha(palette.primary.dark, 0.8),
                        color: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.white.main, 0.2)
                            : alpha(palette.white.main, 1),
                      },
                    }}
                    onClick={handleDateRangeMenuOpen}
                  >
                    <Typography fontSize={13}>{dateRangeOption}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      background: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.primary.dark, 0.1)
                          : isStartDateChanged
                          ? alpha(palette.primary.dark, 0.6)
                          : alpha(palette.primary.dark, 0.3),

                      color: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.white.main, 0.2)
                          : isStartDateChanged
                          ? alpha(palette.white.main, 0.9)
                          : alpha(palette.white.main, 0.7),
                      pointerEvents: isLoading ? "none" : "auto",

                      transition: ({ transitions }) =>
                        transitions.create("all"),
                      height: "max-content",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      position: "relative",
                      cursor: "pointer",

                      ":hover": {
                        background: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.primary.dark, 0.1)
                            : alpha(palette.primary.dark, 0.8),
                        color: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.white.main, 0.2)
                            : alpha(palette.white.main, 1),
                      },
                    }}
                    onClick={handleStartDateMenuOpen}
                  >
                    <Typography fontSize={13}>
                      {moment.unix(dateRange[0]).format("YYYY-MM-DD")}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={8}
              md={2}
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "space-around",
                  sm: "center",
                },
                gap: 2,
              }}
            >
              <IconButton
                sx={{ p: 0 }}
                onClick={() => handleSkip(-1)}
                disabled={disabled}
              >
                <FastRewindIcon />
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                size="large"
                onClick={handlePlay}
                disabled={disabled}
              >
                {isPlaying ? (
                  <PauseIcon fontSize="large" />
                ) : isEnded ? (
                  <ReplayIcon fontSize="large" />
                ) : (
                  <PlayArrowIcon fontSize="large" />
                )}
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => handleSkip(1)}
                disabled={disabled}
              >
                <FastForwardIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={2}
              md={5}
              display={"flex"}
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {mediumScreenAndUp && (
                <>
                  <MenuWrapper
                    anchorEl={endDateMenuAnchorEl}
                    open={isEndDateMenuOpen}
                    onClose={handleEndDateMenuClose}
                  >
                    <Box
                      sx={{
                        background: ({ palette }) => palette.secondary.light,
                        p: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ color: "white !important", fontSize: 14 }}
                      >
                        To Date
                      </Typography>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                          // slots={{
                          //   day: CustomPickersDay,
                          // }}
                          onChange={(date) => {
                            if (date) {
                              handleDateInputChange(date as Moment, "end");
                            }
                          }}
                          value={moment.unix(dateRange[1])}
                          minDate={moment.unix(imageHandler?.dateRange[0])}
                          maxDate={moment.unix(imageHandler?.dateRange[1])}
                        />
                      </LocalizationProvider>
                    </Box>
                  </MenuWrapper>

                  <MenuWrapper
                    anchorEl={blendingMenuAnchorEl}
                    open={isBlendingMenuOpen}
                    onClose={handleBlendingMenuClose}
                  >
                    {FRAME_BLENDING_OPTIONS.map((option) => {
                      return (
                        <MenuItem
                          selected={option.value === blendingOption}
                          key={option.value}
                          dense
                          onClick={() => handleBlendingOptionChange(option)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </MenuWrapper>

                  <MenuWrapper
                    anchorEl={generateMenuAnchorEl}
                    open={isGenerateMenuOpen}
                    onClose={handleGenerateMenuClose}
                  >
                    {GENERATE_OPTIONS.map((option) => {
                      if (
                        option.accessableLevel &&
                        !isAccessable(
                          option.accessableLevel as RoleAccessLevel[],
                        )
                      ) {
                        return null;
                      }

                      return (
                        <MenuItem
                          selected={option.value === blendingOption}
                          key={option.value}
                          dense
                          onClick={() => handleGenerateOptionChange(option)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </MenuWrapper>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      background: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.primary.dark, 0.1)
                          : isEndDateChanged
                          ? alpha(palette.primary.dark, 0.6)
                          : alpha(palette.primary.dark, 0.3),
                      color: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.white.main, 0.2)
                          : isEndDateChanged
                          ? alpha(palette.white.main, 0.9)
                          : alpha(palette.white.main, 0.7),
                      pointerEvents:
                        isLoading || !isFFmpegLoaded ? "none" : "auto",

                      transition: ({ transitions }) =>
                        transitions.create("all"),
                      height: "max-content",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      position: "relative",
                      cursor: "pointer",

                      ":hover": {
                        background: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.primary.dark, 0.1)
                            : alpha(palette.primary.dark, 0.8),
                        color: ({ palette }) =>
                          isLoading || !isFFmpegLoaded
                            ? alpha(palette.white.main, 0.2)
                            : alpha(palette.white.main, 1),
                      },
                    }}
                    onClick={handleEndDateMenuOpen}
                  >
                    <Typography fontSize={13}>
                      {moment.unix(dateRange[1]).format("YYYY-MM-DD")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      background: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.warning.dark, 0.1)
                          : !isCommited
                          ? alpha(palette.warning.dark, 0.6)
                          : alpha(palette.primary.dark, 0.3),
                      color: ({ palette }) =>
                        isLoading || !isFFmpegLoaded
                          ? alpha(palette.white.main, 0.2)
                          : !isCommited
                          ? alpha(palette.white.main, 0.9)
                          : alpha(palette.white.main, 0.4),
                      pointerEvents:
                        isLoading || !isFFmpegLoaded ? "none" : "auto",

                      transition: ({ transitions }) =>
                        transitions.create("all"),
                      height: "max-content",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      position: "relative",
                      cursor: "pointer",
                      ml: 2,

                      ":hover": {
                        background: ({ palette }) =>
                          isLoading
                            ? alpha(palette.warning.dark, 0.1)
                            : alpha(palette.warning.dark, 0.8),
                        color: ({ palette }) =>
                          isLoading
                            ? alpha(palette.white.main, 0.2)
                            : alpha(palette.white.main, 1),
                      },
                    }}
                    onClick={handleGenerateMenuOpen}
                  >
                    <Typography fontSize={13}>Generate</Typography>
                  </Box>
                </>
              )}

              <Typography
                sx={{ fontSize: { xs: 8, sm: 12 }, ml: "auto" }}
                ref={videoTimeStampRef}
              >
                00:00 / 00:00
              </Typography>
            </Grid>
          </Grid>

          {!mediumScreenAndUp && (
            <Grid
              id="bottom-tool-bar-2"
              container
              sx={{
                background: ({ palette }) => palette.secondary.main,
                position: "relative",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: ({ customConfig }) =>
                  customConfig.imageViewerNavbarHeight,
                px: {
                  xs: 2,
                  sm: 10,
                },
                borderTop: "0.5px solid #a6a6a6",
              }}
            >
              <MenuWrapper
                anchorEl={dateOptionMenuAnchorEl}
                open={isDateRangeMenuOpen}
                onClose={handleDateRangeMenuClose}
              >
                {DATE_RANGE_OPTIONS.map((option) => {
                  return (
                    <MenuItem
                      selected={option === dateRangeOption}
                      key={option}
                      dense
                      onClick={() => handleDateRangeOptionChange(option)}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </MenuWrapper>

              <MenuWrapper
                anchorEl={startDateMenuAnchorEl}
                open={isStartDateMenuOpen}
                onClose={handleStartDateMenuClose}
              >
                <Box
                  sx={{
                    background: ({ palette }) => palette.secondary.light,
                    p: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "white !important", fontSize: 14 }}>
                    From Date
                  </Typography>
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                      // slots={{
                      //   day: CustomPickersDay,
                      // }}
                      onChange={(date) => {
                        if (date) {
                          handleDateInputChange(date as Moment, "start");
                        }
                      }}
                      value={moment.unix(dateRange[0])}
                      minDate={moment.unix(maxDateRange[0])}
                      maxDate={moment.unix(maxDateRange[1])}
                    />
                  </LocalizationProvider>
                </Box>
              </MenuWrapper>

              <MenuWrapper
                anchorEl={endDateMenuAnchorEl}
                open={isEndDateMenuOpen}
                onClose={handleEndDateMenuClose}
              >
                <Box
                  sx={{
                    background: ({ palette }) => palette.secondary.light,
                    p: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "white !important", fontSize: 14 }}>
                    To Date
                  </Typography>
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                      // slots={{
                      //   day: CustomPickersDay,
                      // }}
                      onChange={(date) => {
                        if (date) {
                          handleDateInputChange(date as Moment, "end");
                        }
                      }}
                      value={moment.unix(dateRange[1])}
                      minDate={moment.unix(maxDateRange[0])}
                      maxDate={moment.unix(maxDateRange[1])}
                    />
                  </LocalizationProvider>
                </Box>
              </MenuWrapper>

              <MenuWrapper
                anchorEl={blendingMenuAnchorEl}
                open={isBlendingMenuOpen}
                onClose={handleBlendingMenuClose}
              >
                {FRAME_BLENDING_OPTIONS.map((option) => {
                  return (
                    <MenuItem
                      selected={option.value === blendingOption}
                      key={option.value}
                      dense
                      onClick={() => handleBlendingOptionChange(option)}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </MenuWrapper>

              <MenuWrapper
                anchorEl={generateMenuAnchorEl}
                open={isGenerateMenuOpen}
                onClose={handleGenerateMenuClose}
              >
                {GENERATE_OPTIONS.map((option) => {
                  if (
                    option.accessableLevel &&
                    !isAccessable(option.accessableLevel as RoleAccessLevel[])
                  ) {
                    return null;
                  }

                  return (
                    <MenuItem
                      selected={option.value === blendingOption}
                      key={option.value}
                      dense
                      onClick={() => handleGenerateOptionChange(option)}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </MenuWrapper>

              <IconButton
                size="small"
                disabled={isLoading || !isFFmpegLoaded}
                onClick={handleDateRangeMenuOpen}
                sx={{ p: 0 }}
              >
                <AccessTimeIcon fontSize="small" />
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.primary.dark, 0.1)
                      : isStartDateChanged
                      ? alpha(palette.primary.dark, 0.6)
                      : alpha(palette.primary.dark, 0.3),

                  color: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.white.main, 0.2)
                      : isStartDateChanged
                      ? alpha(palette.white.main, 0.9)
                      : alpha(palette.white.main, 0.7),
                  pointerEvents: isLoading ? "none" : "auto",

                  transition: ({ transitions }) => transitions.create("all"),
                  height: "max-content",
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 1,
                  position: "relative",
                  cursor: "pointer",

                  ":hover": {
                    background: ({ palette }) =>
                      isLoading || !isFFmpegLoaded
                        ? alpha(palette.primary.dark, 0.1)
                        : alpha(palette.primary.dark, 0.8),
                    color: ({ palette }) =>
                      isLoading || !isFFmpegLoaded
                        ? alpha(palette.white.main, 0.2)
                        : alpha(palette.white.main, 1),
                  },
                }}
                onClick={handleStartDateMenuOpen}
              >
                <Typography fontSize={13}>
                  {moment.unix(dateRange[0]).format("YYYY-MM-DD")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.warning.dark, 0.1)
                      : !isCommited
                      ? alpha(palette.warning.dark, 0.6)
                      : alpha(palette.primary.dark, 0.3),
                  color: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.white.main, 0.2)
                      : !isCommited
                      ? alpha(palette.white.main, 0.9)
                      : alpha(palette.white.main, 0.4),
                  pointerEvents: isLoading || !isFFmpegLoaded ? "none" : "auto",

                  transition: ({ transitions }) => transitions.create("all"),
                  height: "max-content",
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 1,
                  position: "relative",
                  cursor: "pointer",

                  ":hover": {
                    background: ({ palette }) =>
                      isLoading
                        ? alpha(palette.warning.dark, 0.1)
                        : alpha(palette.warning.dark, 0.8),
                    color: ({ palette }) =>
                      isLoading
                        ? alpha(palette.white.main, 0.2)
                        : alpha(palette.white.main, 1),
                  },
                }}
                onClick={handleGenerateMenuOpen}
              >
                <Typography fontSize={13}>Generate</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  background: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.primary.dark, 0.1)
                      : isEndDateChanged
                      ? alpha(palette.primary.dark, 0.6)
                      : alpha(palette.primary.dark, 0.3),
                  color: ({ palette }) =>
                    isLoading || !isFFmpegLoaded
                      ? alpha(palette.white.main, 0.2)
                      : isEndDateChanged
                      ? alpha(palette.white.main, 0.9)
                      : alpha(palette.white.main, 0.7),
                  pointerEvents: isLoading || !isFFmpegLoaded ? "none" : "auto",

                  transition: ({ transitions }) => transitions.create("all"),
                  height: "max-content",
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 1,
                  position: "relative",
                  cursor: "pointer",

                  ":hover": {
                    background: ({ palette }) =>
                      isLoading || !isFFmpegLoaded
                        ? alpha(palette.primary.dark, 0.1)
                        : alpha(palette.primary.dark, 0.8),
                    color: ({ palette }) =>
                      isLoading || !isFFmpegLoaded
                        ? alpha(palette.white.main, 0.2)
                        : alpha(palette.white.main, 1),
                  },
                }}
                onClick={handleEndDateMenuOpen}
              >
                <Typography fontSize={13}>
                  {moment.unix(dateRange[1]).format("YYYY-MM-DD")}
                </Typography>
              </Box>

              {/* empty box to make the generate button display in middle */}
              <Box sx={{ width: 20 }} />
            </Grid>
          )}
        </Box>
      </Box>
    );
  },
);

const getDistributedFrames = <T,>(allItems: T[], neededCount: number) => {
  if (neededCount >= allItems.length) {
    return [...allItems];
  }

  const result: T[] = [];
  const totalItems = allItems.length;

  const interval = totalItems / neededCount;

  for (let i = 0; i < neededCount; i++) {
    // always add half of interval, so 'picking area' is 'aligned' to the center
    // eg evenlyPickItemsFromArray([0...100], 1); // [50] instead of [0]
    const evenIndex = Math.floor(i * interval + interval / 2);

    result.push(allItems[evenIndex]);
  }

  return result;
};

let abortController: AbortController | undefined;

const TimelapsePlayer = (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isDevice = !!location.pathname.match("/devices/");

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [imageHandler, setImageHandler] = useState<ImageHandlerV2 | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isFFmpegLoaded, setIsFFmpegLoaded] = useState(false);
  const [maxDateRange, setMaxDateRange] = useState<UnixEpoch[]>([
    moment().unix(),
    moment().unix(),
  ]);
  const [dateRange, setDateRange] = useState<UnixEpoch[]>([
    moment().subtract(1, "days").startOf("days").unix(),
    moment().endOf("days").unix(),
  ]);
  const [dateRangeOption, setDateRangeOption] = useState(DATE_RANGE_OPTIONS[0]);
  const [blendingOption, setBlendingOption] = useState(
    _.last(FRAME_BLENDING_OPTIONS)!.value,
  );

  const [committedDateRange, setCommittedDateRange] = useState<UnixEpoch[]>([]);
  const [committedDateRangeOption, setCommittedDateRangeOption] = useState("");
  const [committedBlendingOption, setCommittedBlendingOption] = useState("");

  const [totalImageCount, setTotalImageCount] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [isPreview, setIsPreview] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const currentGalleryJobsite = useAtomValue(currentGalleryJobsiteState);

  const { isAdmin, isSiteAccessable, isAccessable } = useAccess();

  const ffmpegRef = useRef(new FFmpeg());
  const videoRef = useRef<HTMLVideoElement>(null);
  const seekBarRef = useRef<any>(null);
  const bottomToolBarRef = useRef<any>(null);
  const loadingTextRef = useRef<HTMLParagraphElement>(null);
  const downloadingTextRef = useRef<HTMLParagraphElement>(null);

  const { trackAction } = useTimelapsePlayerTracker(imageHandler, "main", true);

  const [compilingModalParams, setCompilingModalParams] = useState<{
    isOpen: boolean;
    isDownload?: boolean;
    isHighRes?: boolean;
  }>({
    isOpen: false,
    isDownload: false,
    isHighRes: false,
  });

  const initFfmpeg = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.4/dist/umd";
    const ffmpeg = ffmpegRef.current;

    ffmpeg.on("log", ({ message }) => {});

    // ffmpeg.on("progress", ({ progress, time }) => {
    //   const useProgress = progress > 1 ? 0 : progress;

    //   setLoadingText(
    //     `(2/2) ${(useProgress * 100).toFixed(0)}% - Generating video...`,
    //   );
    // });

    await ffmpeg
      .load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript",
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm",
        ),
        workerURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.worker.js`,
          "text/javascript",
        ),
      })
      .then(() => {
        setIsFFmpegLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setIsFFmpegLoaded(false);

        setErrorMessage("Sometime went wrong.");
      });
  };

  useEffect(() => {
    initViewer();

    return () => {
      setPreviewUrls([]);
      setIsPreview(false);
      setVideoSrc("");
      setErrorMessage("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    if (ffmpegRef.current) {
      if (abortController) {
        abortController.abort("Timelapse compilation aborted.");
      }

      ffmpegRef.current.terminate();
    }
  };

  const initViewer = async () => {
    setIsLoading(true);
    setLoadingText("Loading...");
    setVideoSrc("");
    setVideoDuration(0);
    setCommittedDateRange([]);
    setTotalImageCount(0);
    updateProgressBar(false, true);

    const promises: Promise<any>[] = [];

    if (!isFFmpegLoaded) {
      promises.push(initFfmpeg());
    }

    const getData = async () =>
      isDevice
        ? await getFirebaseController().Device.getDevice(params.id)
        : await getFirebaseController().Gallery.getGallery(params.id);

    promises.push(
      getData()
        .then(async (currentDevice) => {
          if (!currentDevice) {
            throw new Error(`${isDevice ? "Divice" : "Gallery"} not found.`);
          }

          if (
            (isDevice && !isAdmin) ||
            (!isDevice &&
              !isAdmin &&
              !isSiteAccessable((currentDevice as GalleryV2).jobSite))
          ) {
            navigate("/");
            return;
          }

          const imageHandler = new ImageHandlerV2(currentDevice, isDevice);

          await imageHandler.getImages().then(async (image) => {
            const latestDate = imageHandler.dateRange[1];

            setMaxDateRange(imageHandler.dateRange);
            setDateRange([
              moment
                .unix(latestDate)
                .subtract(1, "days")
                .startOf("days")
                .unix(),
              moment.unix(latestDate).endOf("days").unix(),
            ]);
          });

          setImageHandler(imageHandler);
        })
        .catch(() => {
          navigate("../");
        }),
    );

    Promise.all(promises)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setLoadingText = (text, isDownloading = false) => {
    const ref = isDownloading ? downloadingTextRef : loadingTextRef;

    if (ref.current) {
      if (text) {
        ref.current.style.display = "block";
      } else {
        ref.current.style.display = "none";
      }

      ref.current.innerText = text;
    }
  };

  const imageRefs = useRef<HTMLImageElement[]>([]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();

      loadImg.src = url;

      loadImg.onload = () => resolve(url);
      loadImg.crossOrigin = "anonymous";

      loadImg.onerror = (err) => reject(err);
    });
  };

  const commitedInfo = useMemo(() => {
    const isStartDateChanged = dateRange[0] !== committedDateRange[0];
    const isEndDateChanged = dateRange[1] !== committedDateRange[1];
    const isDateChanged = isStartDateChanged || isEndDateChanged;
    const isCommited = !(
      isDateChanged || committedBlendingOption !== blendingOption
    );

    return {
      isCommited,
      isDateChanged,
      isStartDateChanged,
      isEndDateChanged,
    };
  }, [dateRange, committedDateRange, committedBlendingOption, blendingOption]);

  const handleGenerateLoadingText = (
    stage: "load" | "start" | "fetch" | "process" | "compile",
    isPreview: boolean = true,
    value?: number,
  ) => {
    const title = {
      load: "Loading...",
      start: "Generating...",
      fetch: "Getting images...",
      process: "Processing images...",
      compile: "Generating video...",
    }[stage];

    const step = {
      load: undefined,
      start: undefined,
      fetch: 1,
      process: 2,
      compile: 3,
    }[stage];

    const totalSteps = isPreview ? 2 : 3;

    const arr: string[] = [];

    if (step) {
      arr.push(`(${step}/${totalSteps})`);
    }

    if (!_.isUndefined(value)) {
      arr.push(`${value.toFixed(0)}% -`);
    }

    if (title) {
      arr.push(title);
    }

    setLoadingText(arr.join(" "));
  };

  const getSelectedImages = async (useCache: boolean = false) => {
    if (imageHandler) {
      if (useCache) {
        return getDistributedFrames<Thumbnail<Image>>(
          imageHandler.thumbnails.reverse(),
          MAX_FRAMES_SIZE,
        );
      }

      await imageHandler.filter(dateRange[0], dateRange[1]);

      const thumbnails = getDistributedFrames<Thumbnail<Image>>(
        imageHandler.thumbnails.reverse(),
        MAX_FRAMES_SIZE,
      );

      return thumbnails;
    }

    return [];
  };

  const handleCompile = async (
    isDownload: boolean = false,
    isHighRes: boolean = false,
  ) => {
    console.log("Generating...");
    const startTime = window.performance.now();
    let fetchEndTime;
    let processEndTime;
    let compilingEndTime;

    setIsPreview(false);

    const isRegenerate = commitedInfo.isCommited;
    const isPreviewed = !isHighRes && isRegenerate && previewUrls.length > 0;

    setIsLoading(true);
    handleStop();
    handleGenerateLoadingText("load", false);
    setErrorMessage("");

    if (imageHandler && isFFmpegLoaded) {
      const files: string[] = [];

      try {
        handleGenerateLoadingText("start", false);

        const thumbnails = await getSelectedImages(isRegenerate);

        if (thumbnails.length === 0) {
          setIsLoading(false);

          setErrorMessage("Images not found, please adjust the dates.");

          return;
        }

        trackAction("compile", {
          frame_blending: _.toLower(
            FRAME_BLENDING_OPTIONS.find(
              (option) => option.value === blendingOption,
            )?.label,
          ),
          date_range_option: dateRangeOption,
          date_range: `${moment
            .unix(dateRange[0])
            .utc()
            .format("MMDDYYYY")}-${moment
            .unix(dateRange[1])
            .utc()
            .format("MMDDYYYY")}`,
        });

        await getImagesUrls(thumbnails, {
          isPreview: false,
          useCache: isPreviewed,
          isHighRes,
        }).then(async (images: string[]) => {
          fetchEndTime = window.performance.now();
          console.log(
            `1/3 completed in: ${(fetchEndTime - startTime) / 1000}s`,
          );

          if (images.length === 0) {
            setIsLoading(false);

            setErrorMessage("Images not found, please adjust the dates.");

            return;
          }

          const ffmpeg = ffmpegRef.current;

          const promises: Promise<any>[] = [];

          const total = images.length;
          let current = 0;

          images.forEach((data, index) => {
            const path = `image_${index}.jpg`;

            promises.push(
              limit(() => {
                return fetchFile(data)
                  .then(async (file) => {
                    await ffmpeg.writeFile(path, file);

                    files.push(path);

                    current += 1;

                    handleGenerateLoadingText(
                      "process",
                      false,
                      (current / total) * 100,
                    );
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }),
            );
          });

          await Promise.all(promises).then(async () => {
            processEndTime = window.performance.now();
            console.log(
              `2/3 completed in: ${(processEndTime - fetchEndTime) / 1000}s`,
            );

            handleGenerateLoadingText("compile", false, 0);

            await ffmpeg.on("progress", ({ progress, time }) => {
              const useProgress = progress > 1 ? 0 : progress;

              handleGenerateLoadingText("compile", false, useProgress * 100);
            });

            const filtergraph = [
              "pad=ceil(iw/2)*2:ceil(ih/2)*2",
              isHighRes ? "scale=-2:1080" : "",
              // isHighRes ? "scale=-2:2048" : "",
              // "setpts=2*PTS",
              ...(blendingOption ? [blendingOption] : []),
            ]
              .filter((f) => !!f)
              .join(",");

            abortController = new AbortController();

            await ffmpeg.exec(
              [
                "-r",
                `${FRAME_RATE}`,
                "-i",
                "image_%d.jpg",
                "-c:v",
                "libx264",
                "-crf",
                isHighRes ? "17" : "23",
                "-vf",
                filtergraph,
                "output.mp4",
              ],
              -1,
              {
                signal: abortController!.signal,
              },
            );

            const data: any = await ffmpeg.readFile("output.mp4");

            setTotalImageCount(files.length);

            const videoSrc = URL.createObjectURL(
              new Blob([data.buffer], { type: "video/mp4" }),
            );

            setVideoSrc(videoSrc);

            setIsEnded(false);

            if (isPlaying) {
              handlePlayPause();
            }

            setIsLoading(false);
            setCommittedDateRange([...dateRange]);
            setCommittedDateRangeOption(dateRangeOption);
            setCommittedBlendingOption(blendingOption);

            for (const file of files) {
              await ffmpeg.deleteFile(file);
            }

            if (isDownload) {
              handleDownlod(videoSrc);
            }
          });
        });
      } catch (err) {
        if (abortController?.signal.aborted) {
          console.error(abortController?.signal.reason);
        } else {
          console.error(err);
        }

        setIsLoading(false);
        setVideoDuration(0);

        setErrorMessage("Something went wrong.");

        setTotalImageCount(0);

        for (const file of files) {
          if (ffmpegRef.current.loaded) {
            await ffmpegRef.current.deleteFile(file);
          }
        }
      }
    }

    const endTime = window.performance.now();
    console.log(`3/3 completed in: ${(endTime - processEndTime) / 1000}s`);

    console.log(`Total duration: ${(endTime - startTime) / 1000}s`);
  };

  const handlePreview = async (isHighRes: boolean = false) => {
    setIsPreview(true);
    setIsLoading(true);
    handleStop();

    handleGenerateLoadingText("load", true);

    setErrorMessage("");
    _currentPreviewIndex = 0;
    _previousPreviewIndex = 0;

    if (imageHandler && isFFmpegLoaded) {
      try {
        handleGenerateLoadingText("start", true);

        const thumbnails = await getSelectedImages();

        if (thumbnails.length === 0) {
          setIsLoading(false);

          setErrorMessage("Images not found, please adjust the dates.");

          return;
        }

        // trackAction("compile", {
        //   frame_blending: _.toLower(
        //     FRAME_BLENDING_OPTIONS.find(
        //       (option) => option.value === blendingOption,
        //     )?.label,
        //   ),
        //   date_range_option: dateRangeOption,
        //   date_range: `${moment
        //     .unix(dateRange[0])
        //     .utc()
        //     .format("MMDDYYYY")}-${moment
        //     .unix(dateRange[1])
        //     .utc()
        //     .format("MMDDYYYY")}`,
        // });

        await getImagesUrls(thumbnails, {
          isPreview: true,
          isHighRes,
        }).then(async (images: string[]) => {
          if (images.length === 0) {
            setIsLoading(false);

            setErrorMessage("Images not found, please adjust the dates.");

            return;
          }

          const promises: Promise<any>[] = [];

          const total = images.length;
          let current = 0;

          images.forEach((data, index) => {
            promises.push(
              limit(() => {
                return loadImage(data)
                  .then(() => {
                    current += 1;

                    handleGenerateLoadingText(
                      "process",
                      true,
                      (current / total) * 100,
                    );
                  })
                  .catch((err) => [console.log(err)]);
              }),
            );
          });

          await Promise.all(promises).then(async () => {
            setPreviewUrls(images);

            setIsEnded(false);

            if (isPlaying) {
              handlePlayPause();
            }

            setIsLoading(false);
            setCommittedDateRange([...dateRange]);
            setCommittedDateRangeOption(dateRangeOption);
            setCommittedBlendingOption(blendingOption);

            setTotalImageCount(images.length);

            seekBarRef.current.setProgress(0);
            bottomToolBarRef.current.setTimeStamp(
              0,
              images.length / FRAME_RATE,
            );
          });
        });
      } catch (err) {
        setIsLoading(false);

        setErrorMessage("Something went wrong.");

        setTotalImageCount(0);
      }
    }
  };

  const handleGenerate = (
    isPreview: boolean = false,
    isHighRes: boolean = false,
  ) => {
    if (isPreview) {
      setIsPreview(isPreview);
      handlePreview(isHighRes);
    } else {
      setCompilingModalParams({
        isOpen: true,
        isDownload: false,
        isHighRes,
      });
    }
  };

  const getImagesUrls = async (
    thumbnails: Thumbnail<Image>[],
    {
      isPreview = false,
      useCache = false,
      isHighRes = false,
    }: {
      isPreview?: boolean;
      useCache?: boolean;
      isHighRes?: boolean;
    },
  ): Promise<string[]> => {
    if (useCache) {
      handleGenerateLoadingText("fetch", isPreview, 100);

      return previewUrls;
    }

    const promises: Promise<any>[] = [];
    const total = thumbnails.length;
    let current = 0;

    if (imageHandler) {
      if (isHighRes) {
        thumbnails.forEach((thumbnail) => {
          promises.push(
            limit(() => {
              return imageHandler.findFullRes(thumbnail).then((url) => {
                current += 1;

                handleGenerateLoadingText(
                  "fetch",
                  isPreview,
                  (current / total) * 100,
                );

                return url;
              });
            }),
          );
        });
      } else {
        switch (imageHandler.hostUsed) {
          case "0": {
            (thumbnails as Thumbnail<FirebaseImage>[]).forEach((thumbnail) => {
              promises.push(
                limit(() => {
                  return getFirebaseController()
                    .Image.getThumbnail(
                      thumbnail.image.storageLocation,
                      thumbnail.image.fileName,
                    )
                    .then((data) => {
                      current += 1;

                      handleGenerateLoadingText(
                        "fetch",
                        isPreview,
                        (current / total) * 100,
                      );

                      return data;
                    })
                    .catch((err) => {
                      console.error(err);
                      return null;
                    });

                  // return getFirebaseController()
                  //   .Image.getThumbnail(
                  //     thumbnail.image.storageLocation,
                  //     thumbnail.image.fileName,
                  //   )
                  //   .then((data) => {
                  //     current += 1;

                  //     handleGenerateLoadingText(
                  //       "fetch",
                  //       isPreview,
                  //       (current / total) * 100,
                  //     );

                  //     return data;
                  //   })
                  //   .catch((err) => {
                  //     console.error(err);
                  //     return null;
                  //   });
                }),
              );
            });

            break;
          }
          case "1":
          default: {
            promises.push(
              limit(() => {
                return getDropboxController()
                  .getImageThumbnails(
                    thumbnails.map((thumbnail) => {
                      return (thumbnail.image as DropBoxImage).path_display;
                    }),
                    (progress) => {
                      handleGenerateLoadingText("fetch", isPreview, progress);
                    },
                  )
                  .then((data) => {
                    current += data.length;

                    return data;
                  })
                  .catch((err) => {
                    console.error(err);

                    return null;
                  });
              }),
            );
          }
        }
      }
    }

    return Promise.all(promises)
      .then((data) => {
        // has to flat it, dropbox promise only has 1 array of all thumbmnails, firebase promise return array of thumbnails
        return data.flat().filter((data) => Boolean(data));
      })
      .catch((err) => {
        console.error(err);

        return [];
      });
  };

  const getHighResUrls = async () => {};

  const handleStop = () => {
    if (!isPreview) {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }

    cancelAnimationFrame(_animationId);
    _animationId = 0;

    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isLoading) {
      return;
    }

    if (isPreview) {
      if (imageRefs.current.length > 0) {
        setIsPlaying(!isPlaying);

        if (isPlaying) {
          cancelAnimationFrame(_animationId);

          _animationId = 0;
        } else {
          if (isEnded) {
            setIsEnded(false);

            _currentPreviewIndex = 0;
          }

          let last = 0;

          const total = imageRefs.current.length;
          const totalTime = total / FRAME_RATE;

          const update = (now: number = 0) => {
            if (!last || now - last >= (1 / FRAME_RATE) * 1000) {
              const prevIndex = _previousPreviewIndex;
              const nextIndex = _currentPreviewIndex + 1;

              imageRefs.current[prevIndex].style.opacity = "0";
              imageRefs.current[_currentPreviewIndex].style.opacity = "1";

              const progress = (_currentPreviewIndex / (total - 1)) * 100;

              seekBarRef.current.setProgress(progress);
              bottomToolBarRef.current.setTimeStamp(
                _currentPreviewIndex / FRAME_RATE,
                totalTime,
              );

              _previousPreviewIndex = _currentPreviewIndex;
              _currentPreviewIndex = nextIndex;
              last = now;
            }

            if (_currentPreviewIndex < total) {
              _animationId = requestAnimationFrame((now) => {
                return update(now);
              });
            } else {
              setIsEnded(true);
              setIsPlaying(false);
              seekBarRef.current.setProgress(100);
              bottomToolBarRef.current.setTimeStamp(totalTime, totalTime);
              _currentPreviewIndex = total - 1;
            }
          };

          update();
        }
      }
    } else {
      if (videoRef.current && videoSrc) {
        if (isPlaying) {
          trackAction("player pause");

          videoRef.current.pause();

          cancelAnimationFrame(_animationId);

          _animationId = 0;
        } else {
          trackAction("player play");

          videoRef.current.play();

          updateProgressBar(true);

          setDateRange([...committedDateRange]);
          setDateRangeOption(committedDateRangeOption);
          setBlendingOption(committedBlendingOption);
        }

        if (isEnded) {
          setIsEnded(false);
        }

        setIsPlaying(!isPlaying);
      }
    }
  };

  const updateProgressBar = (loop = false, reset = false, init = false) => {
    if (videoRef.current && seekBarRef.current && bottomToolBarRef.current) {
      if (init) {
        // not sure why video currentTime is not 0 on first load, hence this condition exist

        seekBarRef.current.setProgress(0);
        bottomToolBarRef.current.setTimeStamp(0, videoRef.current.duration);
      } else if (reset) {
        seekBarRef.current.setProgress(0);
        bottomToolBarRef.current.setTimeStamp(0, 0);
      } else {
        const currentTime = videoRef.current.currentTime;
        const duration = videoRef.current.duration;

        if (currentTime < duration) {
          // video playing
          const progress = (currentTime / duration) * 100;

          if (loop) {
            _animationId = requestAnimationFrame(() => updateProgressBar(loop));
          }

          seekBarRef.current.setProgress(progress);

          bottomToolBarRef.current.setTimeStamp(currentTime, duration);
        } else {
          // finish playing

          trackAction("player finished");

          seekBarRef.current.setProgress(100);
          bottomToolBarRef.current.setTimeStamp(duration, duration);
        }
      }
    }
  };

  const handleSkip = (time) => {
    if (isPreview) {
      const totalFrames = previewUrls.length;

      if (totalFrames > 0) {
        trackAction(time < 0 ? "player rewind" : "player fast forward");

        const skipIndexes = time * 30;

        let newIndex = _currentPreviewIndex + skipIndexes;

        if (newIndex > totalFrames - 1) {
          newIndex = totalFrames - 1;
        } else if (newIndex < 0) {
          newIndex = 0;
        }

        bottomToolBarRef.current.setTimeStamp(
          newIndex / FRAME_RATE,
          previewUrls.length / FRAME_RATE,
        );

        const progress = (newIndex / (totalFrames - 1)) * 100;

        seekBarRef.current.setProgress(progress);

        imageRefs.current[newIndex].style.opacity = "1";
        imageRefs.current[_currentPreviewIndex].style.opacity = "0";
        imageRefs.current[_previousPreviewIndex].style.opacity = "0";

        _previousPreviewIndex = _currentPreviewIndex;
        _currentPreviewIndex = newIndex;

        if (isEnded) {
          setIsEnded(false);
        }
      }
    } else {
      if (videoRef.current) {
        trackAction(time < 0 ? "player rewind" : "player fast forward");

        videoRef.current.currentTime += time;
        updateProgressBar();

        if (isEnded) {
          setIsEnded(false);
        }
      }
    }
  };

  const handleSeek = (value) => {
    if (isPreview) {
      if (previewUrls.length > 0) {
        trackAction("player seek");

        const newIndex = Math.floor((value / 100) * previewUrls.length);

        bottomToolBarRef.current.setTimeStamp(
          newIndex / FRAME_RATE,
          previewUrls.length / FRAME_RATE,
        );
        imageRefs.current[newIndex].style.opacity = "1";
        imageRefs.current[_currentPreviewIndex].style.opacity = "0";
        imageRefs.current[_previousPreviewIndex].style.opacity = "0";

        _previousPreviewIndex = _currentPreviewIndex;
        _currentPreviewIndex = newIndex;

        if (isEnded) {
          setIsEnded(false);
        }
      }
    } else {
      if (videoRef.current) {
        trackAction("player seek");

        const currentTime = (value / 100) * videoRef.current.duration;
        videoRef.current.currentTime = currentTime;
        bottomToolBarRef.current.setTimeStamp(
          currentTime,
          videoRef.current.duration,
        );

        if (isEnded) {
          setIsEnded(false);
        }
      }
    }
  };

  const buttons = [
    {
      name: "download",
      description: "Download in full resolution",
      icon: <DownloadIcon />,
      isDisabled:
        isLoading ||
        (isPreview && previewUrls.length === 0) ||
        (!isPreview && !videoSrc),
      isBlocked: !isAccessable(["0", "3"]),
      isActive: isDownloading,
      onClick: () => {
        if (isPreview) {
          setCompilingModalParams({
            isOpen: true,
            isDownload: true,
            isHighRes: false,
          });
        } else if (videoSrc) {
          handleDownlod(videoSrc);
        }
      },
    },
    {
      name: "back",
      description: "Back to Image Viewer",
      icon: <ArrowBackIcon />,
      onClick: () => {
        navigate(`../${params.id}/image-viewer`);
      },
    },
  ];

  const handleDownlod = (videoSrc: string) => {
    if (videoSrc && imageHandler) {
      const a = document.createElement("a");

      trackAction("download");

      const startDate = moment.unix(committedDateRange[0]).format("YYYYMMDD");
      const endDate = moment.unix(committedDateRange[1]).format("YYYYMMDD");

      const jobSiteName = currentGalleryJobsite?.name;
      const curentObjectName = imageHandler.getCurrentObjectName();

      const fullObjectName = `${
        jobSiteName ? jobSiteName + "-" : ""
      }${curentObjectName}`;

      const parsedObject = fullObjectName.replace(/\s+/g, "-");

      // eg: altona-distribution-centre-cam-01_20231108-20231109
      const fileName = `${parsedObject}_${startDate}-${endDate}`;

      a.href = videoSrc;
      a.download = fileName;
      a.click();
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      switch (e.key) {
        case "Spacebar":
        case " ": {
          handlePlayPause();
          break;
        }

        case "ArrowLeft": {
          handleSkip(-1);

          break;
        }

        case "ArrowRight": {
          handleSkip(1);

          break;
        }

        default:
          break;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePlayPause, handleSkip, handleSkip],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };

    // tslint-disable-next-line
  }, [handleKeyPress]);

  const handleClose = () => {
    setCompilingModalParams({
      ...compilingModalParams,
      isOpen: false,
    });
  };

  return (
    <Box
      sx={({ palette }) => ({
        width: "100vw",
        height: "100svh",
        overflow: "hidden",
        backgroundColor: palette.secondary.main,
        color: palette.white.main,
        position: "fixed",

        ".MuiIconButton-root": {
          color: palette.grey[400],
          transition: ({ transitions }) => transitions.create("all"),

          ":hover": {
            color: palette.white.main,
          },

          "&.Mui-disabled": {
            color: palette.grey[800],
          },
        },
      })}
    >
      <TopToolBar
        buttons={buttons}
        imageHandler={imageHandler}
        tracker={trackAction}
      />

      <Dialog
        open={compilingModalParams.isOpen}
        PaperProps={{
          sx: {
            margin: {
              xs: 2,
            },
          },
        }}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Ready to compile"}
          {compilingModalParams.isHighRes ? " (High Res)?" : "?"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {`Compile the video with blending mode: `}
          </DialogContentText>

          <Box sx={{ my: 1, display: "flex", alignItems: "center", gap: 0.25 }}>
            <Box
              sx={{
                fontSize: 12,
                color: ({ palette }) => palette.secondary.light,
                fontWeight: !blendingOption ? "bold" : "regular",
              }}
            >
              Off
            </Box>
            <Box>
              <Switch
                checked={!!blendingOption}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBlendingOption("tblend=all_mode=average");
                  } else {
                    setBlendingOption("");
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                fontSize: 12,
                color: ({ palette }) => palette.secondary.light,
                fontWeight: blendingOption ? "bold" : "regular",
              }}
            >
              On
            </Box>
          </Box>

          <DialogContentText>
            {`Please note that the compilation process might take some time.`}
            {compilingModalParams.isDownload &&
              " Download will start after compiling."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              handleCompile(
                compilingModalParams.isDownload,
                compilingModalParams.isHighRes,
              );
              handleClose();
            }}
            autoFocus
            sx={{
              color: "white !important",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        id="content-container"
        sx={({ customConfig }) => ({
          pt: customConfig.imageViewerNavbarHeight,
          pb: {
            xs: `calc(${customConfig.imageViewerNavbarHeight} * 2 + ${SEEK_BAR_HEIGHT}px)`,
            md: `calc(${customConfig.imageViewerNavbarHeight} + ${SEEK_BAR_HEIGHT}px)`,
          },
          height: "100svh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50px",
              right: "0",
              display: "flex",
              alignItems: "center",

              zIndex: 1,
              visibility: isDownloading ? "visible" : "hidden",

              width: "200px",
              p: 1.5,
              borderRadius: 1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              background: ({ palette }) => alpha(palette.white.main, 0.6),
            }}
          >
            <Stack width={"100%"} direction={"row"} alignItems={"center"}>
              <Box sx={{ width: "max-content", mr: 1.5 }}>
                <CircularProgress color="primary" size={14} />
              </Box>

              <Typography
                fontSize={10}
                ref={downloadingTextRef}
                style={{ display: "none" }}
                sx={{ color: ({ palette }) => palette.secondary.main }}
              />
            </Stack>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
              visibility: isLoading ? "visible" : "hidden",
            }}
          >
            <Stack justifyContent={"center"} alignItems={"center"}>
              <LDSRingLoader />

              <Typography
                mt={1}
                fontSize={13}
                ref={loadingTextRef}
                style={{ display: "none" }}
                textAlign={"center"}
              />
            </Stack>
          </Box>

          {isPreview && !errorMessage ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                opacity: isLoading ? 0.4 : 1,
              }}
            >
              {previewUrls.map((image, imageIndex) => {
                return (
                  <Box
                    ref={(el: HTMLImageElement) => {
                      imageRefs.current[imageIndex] = el;

                      return el;
                    }}
                    key={image}
                    component={"img"}
                    crossOrigin="anonymous"
                    src={image}
                    sx={{
                      pointerEvents: "none",
                      userSelect: "none",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      opacity: imageIndex === 0 ? 1 : 0,
                    }}
                  />
                );
              })}
            </Box>
          ) : videoSrc && !errorMessage ? (
            <video
              key={videoSrc}
              onLoadedMetadata={() => {
                updateProgressBar(false, false, true);
                setVideoDuration(videoRef.current!.duration);
              }}
              onClick={handlePlayPause}
              ref={videoRef}
              controls={false}
              style={{
                width: "100%",
                height: "100%",
                opacity: isLoading ? 0.4 : 1,
              }}
              muted={true}
              onEnded={() => {
                setIsEnded(true);
                setIsPlaying(false);
              }}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
                visibility: !isLoading ? "visible" : "hidden",
              }}
            >
              <Typography fontSize={13} textAlign={"center"}>
                {errorMessage ||
                  `Please select the dates and click "Generate" to generate preview or
              time-lapse video.`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <BottomToolBar
        seekBarRef={seekBarRef}
        isDisable={isLoading || (previewUrls.length === 0 && !videoSrc)}
        handleSeek={handleSeek}
        videoDuration={videoDuration}
        ref={bottomToolBarRef}
        isPlaying={isPlaying}
        isEnded={isEnded}
        handlePlay={handlePlayPause}
        handleSkip={handleSkip}
        handleGenerate={handleGenerate}
        dateRange={dateRange}
        setDateRange={setDateRange}
        dateRangeOption={dateRangeOption}
        setDateRangeOption={setDateRangeOption}
        isLoading={isLoading}
        isFFmpegLoaded={isFFmpegLoaded}
        videoSrc={videoSrc}
        totalImageCount={totalImageCount}
        blendingOption={blendingOption}
        setBlendingOption={setBlendingOption}
        imageHandler={imageHandler}
        isPreview={isPreview}
        previewUrls={previewUrls}
        commitedInfo={commitedInfo}
        maxDateRange={maxDateRange}
      />
    </Box>
  );
};

export default TimelapsePlayer;
