import {
  IconDefinition,
  faCloudMoon,
  faCloudRain,
  faCloudSun,
  faMoon,
  faSmog,
  faSnowflake,
  faSun,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { UnixEpoch, VisualCrossingWeather } from "database/DataTypes";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";

const caches: {
  [key: string]: {
    [key: string]: VisualCrossingWeather;
  };
} = {};
const cachesStack: string[] = [];
const MAX_CACHE = 10;

type UseWeatherOptions = {
  location: string;
  dateRange: UnixEpoch[];
  include?: string;
  cacheKey?: string;
};

type GetWeatherOptions = Omit<UseWeatherOptions, "location" | "dateRange"> & {
  timeZone?: string;
  stationId?: string;
};

export type WeatherStation = {
  distance: number;
  latitude: number;
  longitude: number;
  id: string;
  name: string;
  quality?: number;
  displayName?: string;
};

export type FaWeatherIconName =
  | "snow"
  | "rain"
  | "fog"
  | "wind"
  | "cloudy"
  | "partly-cloudy-day"
  | "partly-cloudy-night"
  | "clear-day"
  | "clear-night";

const useWeather = (options?: UseWeatherOptions) => {
  const [data, setData] = useState<VisualCrossingWeather | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");

  const getWeather = async (
    location: string,
    dateRange: UnixEpoch[],
    options: GetWeatherOptions = {},
  ) => {
    if (location && dateRange.length > 0) {
      const { include = "current", timeZone, cacheKey, stationId } = options;

      const requestLocationPath = `${
        stationId ? `stn:${stationId}` : location
      }/${dateRange.join("/")}`;

      const parsedCacheKey =
        cacheKey || `${requestLocationPath}/${include}/${timeZone}`;
      const cacheData = _.get(caches, parsedCacheKey) as unknown as
        | VisualCrossingWeather
        | undefined;

      if (cacheData) {
        setData(cacheData);
        return cacheData;
      }

      setIsLoading(true);
      setError("");
      setData(null);

      try {
        const url = new URL(
          `${process.env.REACT_APP_VISUAL_CROSSING_URL}/${requestLocationPath}/`,
        );

        url.searchParams.set(
          "key",
          `${process.env.REACT_APP_VISUAL_CROSSING_API_KEY}`,
        );
        url.searchParams.set("contentType", "json");
        url.searchParams.set("unitGroup", "metric");

        // const includes = [include, "obs", "remote", "fcst"];
        const includes = [include, "obs", "fcst"];

        url.searchParams.set("include", includes.join(","));

        if (timeZone) {
          url.searchParams.set("timeZone", timeZone);
        }

        if (stationId) {
          url.searchParams.set("maxStations", "1");
        }

        const response = await fetch(url);
        const json = await response.json();

        _.set(caches, [parsedCacheKey], json);
        cachesStack.push(parsedCacheKey);

        if (cachesStack.length > MAX_CACHE) {
          _.unset(caches, [cachesStack.shift()] as string[]);
        }

        setIsLoading(false);
        setData(json);

        return json;
      } catch (error) {
        setIsLoading(false);
        setError("Failed to get weather info.");

        return null;
      }
    }
  };

  useEffect(() => {
    const { location, dateRange, ...restOptions } = options || {};

    if (location && dateRange) {
      getWeather(location, dateRange, restOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return useMemo(() => {
    return { data, isLoading, error, getWeather };
  }, [data, isLoading, error]);
};

export const getFaWeatherColor = (weather: FaWeatherIconName): string => {
  switch (weather) {
    case "snow":
      return "#03a9f4";
    case "rain":
      return "#03a9f4";
    case "fog":
      return "#2ec1ff";
    case "wind":
      return "#def2ff";
    case "cloudy":
    case "partly-cloudy-day":
      return "#f28e02";
    case "partly-cloudy-night":
      return "#05304c";
    case "clear-day":
      return "#f28e02";
    case "clear-night":
      return "#05304c";
    default:
      return "";
  }
};

export const getFaWeatherIcon = (
  weather: FaWeatherIconName,
): IconDefinition | void => {
  switch (weather) {
    case "snow":
      return faSnowflake;
    case "rain":
      return faCloudRain;
    case "fog":
      return faSmog;
    case "wind":
      return faWind;
    case "cloudy":
    case "partly-cloudy-day":
      return faCloudSun;
    case "partly-cloudy-night":
      return faCloudMoon;
    case "clear-day":
      return faSun;
    case "clear-night":
      return faMoon;
    default:
  }
};

export default useWeather;
