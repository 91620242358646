import { where, query, limit, orderBy } from "firebase/firestore";
import { ref as refStore } from "firebase/storage";

import {
  FirebaseController,
  getDownloadURL,
} from "database/FirebaseController";

import {
  getDefaultImageExtras,
  getDefaultPhotoSentinelImage,
  getDefaultComment,
} from "database/DataDefaultValues";
import {
  Comment,
  UnixEpoch,
  FirebaseImage,
  PhotoSentielImage,
  ImageExtras,
  WebAppSetup,
} from "database/DataTypes";

import _ from "lodash";

export class _ImageController {
  private parent!: FirebaseController;

  constructor(parent: FirebaseController) {
    this.parent = parent;
  }

  async getThumbnail(location: string, fileName: string) {
    const thumbnailName = fileName.replace(".jpg", "_925x695.jpg");
    const thumbnailRef = refStore(
      this.parent.getStorageRef(),
      location + "thumbnails/" + thumbnailName,
    );

    return getDownloadURL(thumbnailRef);
  }

  async getImages(
    objectId: string | number,
    {
      isDevice,
      startDate,
      endDate,
      imageIds,
    }: {
      isDevice?: boolean;
      startDate?: UnixEpoch;
      endDate?: UnixEpoch;
      imageIds?: (string | number)[];
    },
  ) {
    let images: FirebaseImage[] = [];

    const field = isDevice ? "deviceId" : "galleryId";

    const queries: any[] = [where(field, "==", objectId)];

    if (!_.isUndefined(startDate)) {
      queries.push(where("epochTime", ">=", startDate));
    }

    if (!_.isUndefined(endDate)) {
      queries.push(where("epochTime", "<=", endDate));
    }

    if (imageIds) {
      if (imageIds.length > 0) {
        const promises: Promise<FirebaseImage[]>[] = [];

        // Firestore "in" operator only supports up to 30 entries, so multiple fetch is needed
        const idsChuck = _.chunk(imageIds, 30);

        idsChuck.forEach((ids) => {
          promises.push(
            this.parent.getDocumentListWithQuery<FirebaseImage>(
              query(
                this.parent.getColRef("images"),
                ...queries,
                where("id", "in", ids),
              ),
            ),
          );
        });

        // have sort on client side as we doing batch fetching
        await Promise.all(promises).then((data) => {
          images = data.flat();

          images = _.orderBy(images, "epochTime", "desc");
        });
      } else {
        return [];
      }
    } else {
      queries.push(orderBy("epochTime", "desc"));

      images = await this.parent.getDocumentListWithQuery<FirebaseImage>(
        query(this.parent.getColRef("images"), ...queries),
      );
    }

    return images;
  }

  async getFirstImage(objectId: string | number, isDevice: boolean = true) {
    const key = isDevice ? "deviceId" : "galleryId";

    const q = query(
      this.parent.getColRef("images"),
      where(key, "==", objectId),
      orderBy("epochTime", "asc"),
      limit(1),
    );

    return this.parent.getDocumentWithQuery<FirebaseImage>(q);
  }

  async getLastImage(objectId: string | number, isDevice: boolean = true) {
    const key = isDevice ? "deviceId" : "galleryId";

    const q = query(
      this.parent.getColRef("images"),
      where(key, "==", objectId),
      orderBy("epochTime", "desc"),
      limit(1),
    );

    return this.parent.getDocumentWithQuery<FirebaseImage>(q);
  }

  async getPhotoSentinelImages(imageIds: number[]) {
    const promises: Promise<PhotoSentielImage[]>[] = [];

    const idsChuck = _.chunk(imageIds, 30);

    idsChuck.forEach((ids) => {
      promises.push(
        this.parent.getDocumentListWithQuery<PhotoSentielImage>(
          query(
            this.parent.getColRef("photoSentinelImages"),
            where("photo_id", "in", ids),
          ),
        ),
      );
    });

    return Promise.all(promises).then((data) => {
      return _.orderBy(data.flat(), "datetime_taken_local", "desc");
    });
  }

  async getImageExtras(
    imageId: number | string,
    {
      objectId,
      isDevice,
    }: {
      objectId?: string | number;
      isDevice?: boolean;
    } = {},
  ) {
    const queries: any[] = [where("imageApplied", "==", imageId)];

    if (objectId) {
      if (isDevice) {
        queries.push(where("deviceId", "==", objectId));
      } else {
        queries.push(where("galleryId", "==", objectId));
      }
    }

    return this.parent.getDocumentListWithQuery<ImageExtras>(
      query(this.parent.getColRef("imageExtras"), ...queries),
    );
  }

  async getAllImageExtras({
    objectId,
    isDevice = false,
    userId,
    isCommented,
    isFavorited,
    isTagged,
  }: {
    objectId: string | number;
    isDevice: boolean;
    userId?: number;
    isCommented?: boolean;
    isFavorited?: boolean;
    isTagged?: boolean;
  }) {
    const queries: any[] = [];

    if (isDevice) {
      queries.push(where("deviceId", "==", objectId));
    } else {
      queries.push(where("galleryId", "==", objectId));
    }

    if (userId) {
      queries.push(where("associatedUser", "==", userId));
    }

    if (!_.isUndefined(isCommented)) {
      queries.push(where("comments", isCommented ? "!=" : "==", []));
    }

    if (!_.isUndefined(isFavorited)) {
      queries.push(where("favorited", "==", isFavorited));
    }

    if (!_.isUndefined(isTagged)) {
      queries.push(where("tags", isTagged ? "!=" : "==", []));
    }

    return this.parent.getDocumentListWithQuery<ImageExtras>(
      query(this.parent.getColRef("imageExtras"), ...queries),
    );
  }

  async initDropboxRefreshToken(): Promise<string> {
    return await this.parent
      .getDocumentWithId<WebAppSetup>("webAppSetup", "Main")
      .then((data) => {
        if (data) {
          this.parent.dropboxRefreshToken = data.refreshToken;

          return data.refreshToken;
        } else {
          throw new Error("WebAppSetup not found.");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async createOrUpdateImageExtras(
    objectId: string | number,
    isDevice: boolean = false,
    imageExtras: Partial<ImageExtras>,
    isInitDefault: boolean = true,
  ) {
    const id = imageExtras.id || this.parent.getNewDocumentId();

    const newImageExtras = {
      ...(isInitDefault ? getDefaultImageExtras() : {}),
      ...imageExtras,
      ...(isDevice ? { deviceId: objectId } : { galleryId: objectId }),
      associatedUser: this.parent.currentUser!.id || null,
      id,
    };

    return await this.parent
      .createOrUpdateDocument(newImageExtras, "imageExtras", id)
      .then(async () => {
        return await this.parent.getDocumentWithId<ImageExtras>(
          "imageExtras",
          id,
        );
      });
  }

  async addPhotoSentinelImage(photoData: PhotoSentielImage) {
    const newPhotoData = {
      ...getDefaultPhotoSentinelImage(),
      ...photoData,
    };

    return await this.parent.createOrUpdateDocument(
      newPhotoData,
      "photoSentinelImages",
      photoData.photo_id as number,
    );
  }

  async addComment(comment: Partial<Comment>) {
    const id = comment.id || this.parent.getNewDocumentId();

    const newComment = {
      ...getDefaultComment(),
      ...comment,
      id,
    };

    return await this.parent.createOrUpdateDocument(newComment, "comments", id);
  }

  async getComment(commentId) {
    return this.parent.getDocumentWithId<Comment>("comments", commentId);
  }

  async getComments(commentIds) {
    return this.parent.getDocumentListWithValue<Comment>(
      "comments",
      "id",
      commentIds,
    );
  }
}
