import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  alpha,
} from "@mui/material";

import { AuthBasePropsType } from "./AuthWindow";
import { PasswordTextField } from "Windows/User/UserEditWindow";

interface SignInWindowPropsType extends AuthBasePropsType {
  handleSignIn: () => void;
}

const SignInWindow = (props: SignInWindowPropsType) => {
  const {
    isLoading,
    isAuthLoading,
    handleSignIn,
    inputValues,
    handleInputChange,
    inputBaseProps,
  } = props;

  return (
    <Box>
      <Box mt={4}>
        <Box>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                {...inputBaseProps}
                type="text"
                name="username"
                placeholder="Username"
                value={inputValues.username}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item>
              <PasswordTextField
                {...inputBaseProps}
                type="password"
                name="password"
                placeholder="Password"
                value={inputValues.password}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item pt={"8px !important"}>
              <Box width="max-content">
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      size="small"
                      name="isRemember"
                      checked={inputValues.isRemember}
                      onChange={handleInputChange}
                      disabled={isLoading || isAuthLoading}
                      sx={({ palette }) => ({
                        color: alpha(palette.white.main, 0.8),

                        "&.Mui-checked": {
                          color: palette.white.main,
                        },
                      })}
                    />
                  }
                  labelPlacement="end"
                  label={
                    <Typography
                      component="span"
                      variant="body2"
                      sx={({ palette }) => ({
                        color: inputValues.isRemember
                          ? `${palette.white.main} !important`
                          : alpha(palette.white.main, 0.8),
                      })}
                    >
                      Remember Me
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Grid container direction="column">
            <Button
              disabled={isLoading || isAuthLoading}
              sx={({ palette }) => ({
                background: palette.primary.main,
                color: palette.white.main,

                "&:hover, &:focus, &.Mui-focusVisible": {
                  transform: "none",
                  background: `${palette.primary.light} !important`,
                },

                "&.Mui-disabled": {
                  background: `${palette.primary.dark} !important`,
                },
              })}
              fullWidth
              onClick={handleSignIn}
            >
              SIGN IN
            </Button>

            {/* <Box sx={{ fontSize: 16, color: "white !important", mt: 1 }}>
              <Typography textAlign="center">
                Don't have an account?{" "}
                <Box
                  sx={({ palette }) => ({
                    color: palette.primary.main,
                    cursor: "pointer",

                    "&:hover": {
                      color: palette.primary.light,
                    },
                  })}
                  component="span"
                  onClick={() => props.setCurrentPage(1)}
                >
                  Sign up
                </Box>
              </Typography>

              <Typography textAlign="center">
                Forgot{" "}
                <Box
                  sx={({ palette }) => ({
                    color: palette.primary.main,
                    cursor: "pointer",

                    "&:hover": {
                      color: palette.primary.light,
                    },
                  })}
                  component="span"
                  onClick={() => props.setCurrentPage(2)}
                >
                  password?
                </Box>
              </Typography>
            </Box> */}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInWindow;
