import { createContext, useContext, useState } from "react";
import _ from "lodash";
import moment from "moment";

interface SnackbarPropsType {
  severity?: "error" | "info" | "success" | "warning";
  title?: string;
  content: string;
  close?: () => void;
  open: boolean;
  key?: string;
}

interface SnackbarContextType {
  snackbarProps: SnackbarPropsType;
  setSnackbarProps: (snackbarProps: SnackbarPropsType) => void;
  resetSnackbarProps: () => void;
}

const DEFAULT_SNACKBAR_PROPS: SnackbarPropsType = {
  severity: "success",
  title: "",
  content: "",
  close: () => {},
  open: false,
  key: "",
};

const SnackbarContext = createContext<SnackbarContextType>({
  snackbarProps: _.cloneDeep(DEFAULT_SNACKBAR_PROPS),
  setSnackbarProps: () => {},
  resetSnackbarProps: () => {},
});

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = (props) => {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarPropsType>(
    _.cloneDeep(DEFAULT_SNACKBAR_PROPS),
  );

  const setSnackbarPropsHandler = (snackbarProps: SnackbarPropsType) => {
    setSnackbarProps({
      ..._.cloneDeep(DEFAULT_SNACKBAR_PROPS),
      ...snackbarProps,
      key: moment().valueOf().toString(),
    });
  };

  const resetSnackbarPropsHandler = () => {
    setSnackbarProps(_.cloneDeep(DEFAULT_SNACKBAR_PROPS));
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbarProps,
        setSnackbarProps: setSnackbarPropsHandler,
        resetSnackbarProps: resetSnackbarPropsHandler,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};
