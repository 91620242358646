/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

/**
  The linearGradient() function helps you to create a linear gradient color background
 */

export type TripleLinearGradientFunction = (
  color: number,
  colorState: string,
  colorStateSecondary: string,
  angle?: number,
) => string;

const tripleLinearGradient: TripleLinearGradientFunction = (
  color,
  colorState,
  colorStateSecondary,
  angle,
) => {
  if (angle === undefined) {
    angle = 310;
  }

  return `linear-gradient(${angle}deg, ${color}, ${colorState}, ${colorStateSecondary})`;
};

export default tripleLinearGradient;
