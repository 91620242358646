import { useCallback, useMemo } from "react";

import { useAtomValue } from "jotai/react";
import {
  currentClientState,
  currentUserRoleState,
  currentUserState,
} from "states/auth";

import ReactGA from "react-ga4";

export const ENABLE_TRACKER = false;
const TRACKER_PREFIX = "rlvit_";

type EventTrackerOptions = {
  [key: string]: any;
};

type EventName =
  | "dashboard_view"
  | "dashboard_action"
  | "device_view"
  | "device_action"
  | "gallery_view"
  | "image_viewer_view"
  | "image_viewer_action"
  | "image_view"
  | "timelapse_player_view"
  | "timelapse_player_action"
  | "login"
  | "logout";

let _gaClientId = "";

export const initTracker = () => {
  if (process.env.NODE_ENV === "production" || ENABLE_TRACKER) {
    if (process.env.REACT_APP_MEASUREMENTID) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENTID);

      ReactGA.ga((tracker) => {
        _gaClientId = tracker.get("clientId");
      });
    }
  }
};

export const baseEventTracker = (
  eventName: EventName,
  eventOptions: EventTrackerOptions,
) => {
  if (process.env.NODE_ENV === "production" || ENABLE_TRACKER) {
    const pEventName = `${TRACKER_PREFIX}${eventName}`;
    const pEventOptions = Object.fromEntries(
      Object.entries(eventOptions).map(([k, v]) => [
        `${TRACKER_PREFIX}${k}`,
        v,
      ]),
    );
    ReactGA.event(pEventName, pEventOptions);
  }
};

const useEventTracker = () => {
  const currentUser = useAtomValue(currentUserState);
  const currentUserRole = useAtomValue(currentUserRoleState);
  const currentClient = useAtomValue(currentClientState);

  const userInfo = useMemo(() => {
    if (currentUser && currentUserRole && currentClient && _gaClientId) {
      return {
        user_name: currentUser.username,
        user_id: currentUser.id as number,
        user_client_id: currentClient.id,
        user_client_name: currentClient.name,
        user_access_level: currentUserRole.accessLevel,
        ga_client_id: _gaClientId.toString(),
      };
    } else {
      return {};
    }
  }, [currentUser, currentUserRole, currentClient]);

  const trackEvent = useCallback(
    (eventName: EventName, eventOptions: EventTrackerOptions) => {
      baseEventTracker(eventName, { ...eventOptions, ...userInfo });
    },
    [userInfo],
  );

  return useMemo(() => ({ userInfo, trackEvent }), [userInfo, trackEvent]);
};

export default useEventTracker;
