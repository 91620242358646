import { Box } from "@mui/material";

import SideNavbar from "components/Layouts/SideNavbar";

const DashboardLayout = ({ children }) => {
  return (
    <Box>
      <SideNavbar />

      <Box>{children}</Box>
    </Box>
  );
};

export default DashboardLayout;
