const getUrlWithParms = (
  baseUrl: string,
  params: { [key: string]: string },
) => {
  const url = new URL(baseUrl);

  url.searchParams.set(
    "apiKey",
    process.env.REACT_APP_GEOAPIFY_API_KEY as string,
  );

  url.searchParams.set("format", `json`);

  for (const key in params) {
    url.searchParams.set(key, params[key]);
  }

  return url;
};

export const getGeocode = async (addressInput: string): Promise<any> => {
  const url = getUrlWithParms(`https://api.geoapify.com/v1/geocode/search`, {
    text: addressInput,
  });

  return await fetch(url).then((res) => res.json());
};

export const getAdressAutoComplete = async (
  addressInput: string,
): Promise<any> => {
  const url = getUrlWithParms(
    `https://api.geoapify.com/v1/geocode/autocomplete`,
    {
      text: addressInput,
    },
  );

  return await fetch(url).then((res) => res.json());
};

// doens't really nid hook for this now
const useGeoapify = () => {
  return {};
};

export default useGeoapify;
