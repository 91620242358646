import { Box, Typography } from "@mui/material";
import IntervalBase from "@/assets/BEERAssets/ReliveIt/IntervalBase.png";

const IntervalIcon = ({
  amount,
  unit,
}: {
  amount: number;
  unit: "Mins" | "Secs";
}) => {
  const height = 50;
  const width = 50;

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box>
        <Typography
          color="secondary"
          sx={{
            display: "inline",
            fontWeight: "bold",
            fontSize: 16,
            textWrap: "nowrap",
          }}
        >
          {amount || "-"}
        </Typography>

        {amount && (
          <Typography
            color="secondary"
            sx={{
              display: "inline",
              fontWeight: "bold",
              fontSize: 12,
              textWrap: "nowrap",
            }}
          >
            {` ${unit}`}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-35%, -25%)",
        }}
      >
        <Box
          component={"img"}
          src={IntervalBase}
          sx={{ width, height, objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
};

export default IntervalIcon;
