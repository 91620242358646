import _ from "lodash";

/**
 * To get edited fields, for react-hook-form usage only.
 * @param dirtyFields - formState.dirtyFields
 * @param allValues - data, the form values got from the formState.handleSubmit
 * @return - same as the form values but only just edited fields.
 */
export const getDirtyFields = (
  dirtyFields: object | boolean,
  allValues: object,
): any => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFields(dirtyFields[key], allValues[key]),
    ]),
  );
};

/**
 * To get keys name of an objects in string. Eg: "a, a.1, a.2, b"
 * @param data - object
 */
export const getFieldNames = (data, prefix = "") => {
  let result: string[] = [];

  for (const key in data) {
    const fullPath = prefix ? `${prefix}.${key}` : key;

    if (typeof data[key] === "object" && data[key] !== null) {
      const nestedChanges = getFieldNames(data[key], fullPath);
      result = result.concat(nestedChanges);
    } else {
      result.push(fullPath);
    }
  }

  return result;
};

/**
 * To get the difference between 2 ararys.
 * @param arr1 - previous array
 * @param arr2 - updated array
 */
export const getDifferences = <T extends any>(
  arr1: T[],
  arr2: T[],
): [T[], T[]] => {
  return [_.difference(arr2, arr1), _.difference(arr1, arr2)];
};
