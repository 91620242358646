import boxShadow, { BoxShadowFunction } from "./boxShadow";
import linearGradient, { LinearGradientFunction } from "./linearGradient";
import pxToRem, { PxToRemFunction } from "./pxToRem";
import radialGradient, { RadialGradientFunction } from "./radialGradient";
import tripleLinearGradient, {
  TripleLinearGradientFunction,
} from "./tripleLinearGradient";

export type Functions = {
  boxShadow: BoxShadowFunction;
  linearGradient: LinearGradientFunction;
  pxToRem: PxToRemFunction;
  radialGradient: RadialGradientFunction;
  tripleLinearGradient: TripleLinearGradientFunction;
};

const functions: Functions = {
  boxShadow,
  linearGradient,
  pxToRem,
  radialGradient,
  tripleLinearGradient,
};

export default functions;
