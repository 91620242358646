import { atomWithReset } from "jotai/utils";

import { RouteInfoType } from "components/RoutesContainer/RoutesContainer";

export const routeInfoState = atomWithReset<RouteInfoType>({
  key: "",
  localeKey: "",
  title: "",
  name: "",
  icon: "",
});

export const isNavbarShowState = atomWithReset<boolean>(false);
export const isSidenavShowState = atomWithReset<boolean>(false);
