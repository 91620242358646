import {
  Modal,
  Box,
  ModalProps,
  Button,
  Typography,
  alpha,
} from "@mui/material";
import _ from "lodash";

import { MouseEvent, ReactNode, memo } from "react";

type ConfirmationModalProps = Omit<ModalProps, "children" | "onClose"> & {
  title: string;
  message: string;
  onCancel?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "cancelClick",
  ) => void;
  onDone: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  actions?: ReactNode[];
};

const BaseConfirmationModal = ({ open, onClose, children }: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          overflow: "auto",

          maxHeight: "100vh",
          maxWidth: 700,

          width: {
            xs: "100vw",
            sm: "70vw",
            md: "60vw",
            lg: "50vw",
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

const ConfirmationModalHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        background: ({ palette }) => alpha(palette.error.light, 0.5),
        color: ({ palette }) => palette.error.dark,
      }}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </Box>
  );
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open,
    onClose,
    title,
    message,
    onCancel,
    onDone,
    disabled,
    actions,
    ...restProps
  } = props;

  return (
    <BaseConfirmationModal open={open} onClose={onClose} {...restProps}>
      <Box>
        <ConfirmationModalHeader>
          <Typography sx={{ fontSize: 20, lineHeight: 1, fontWeight: "bold" }}>
            {title}
          </Typography>
        </ConfirmationModalHeader>

        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ fontSize: 16 }}>{message}</Typography>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {actions ? (
              { actions }
            ) : (
              <>
                <Button
                  size="small"
                  color="secondary"
                  variant="text"
                  disableElevation
                  onClick={(e) =>
                    onCancel ? onCancel(e) : onClose(e, "cancelClick")
                  }
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 1.5, color: "white !important" }}
                  size="small"
                  color="error"
                  variant="contained"
                  disableElevation
                  onClick={(e) => {
                    onDone(e);
                    onClose(e, "cancelClick");
                  }}
                  disabled={disabled}
                >
                  Yes
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </BaseConfirmationModal>
  );
};

export default memo(ConfirmationModal, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
