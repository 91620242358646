import _ from "lodash";

/**
 * Fetches data from the specified URL with retry mechanism.
 * @param {string} url - The URL to fetch data from.
 * @param {RequestInit} [fetchOptions] - The options to be passed to the fetch function.
 * @param {number} [retryCount=2] - The number of times to retry the fetch operation in case of failure.
 * @param {number} [retryAfter=3000 to 1500] - The time to wait (in milliseconds) before retrying the fetch operation.
 * @returns {Promise<Response|Error>} A promise that resolves with the response if the fetch is successful, or rejects with an error if all retries fail.
 */
export const fetchWithRetry = (
  url: string,
  fetchOptions?: RequestInit,
  retryCount: number = 2,
  retryAfter: number = _.random(3, 15) * 1000,
) => {
  return fetch(url, fetchOptions).catch((error) => {
    if (retryCount === 0) {
      return error;
    }

    return new Promise((resolve) => {
      setTimeout(resolve, retryAfter);
    }).then(async () => {
      return await fetchWithRetry(
        url,
        fetchOptions,
        retryCount - 1,
        retryAfter,
      );
    });
  });
};
