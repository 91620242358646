import { FirebaseController } from "database/FirebaseController";
import { Feedback } from "database/DataTypes";

export class _FeedbackController {
  private parent!: FirebaseController;

  constructor(parent: FirebaseController) {
    this.parent = parent;
  }

  async addFeedBack(
    feedback: Pick<Feedback, "email" | "firstName" | "lastName" | "message">,
  ) {
    if (!this.parent.currentUser) {
      throw new Error("User not found");
    }

    return await this.parent.Callable.postFeedback({
      feedback,
      userId: this.parent.currentUser.id as number,
    })
      .then(({ status }) => {
        if (status) {
          return status;
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
