import { FirebaseController } from "database/FirebaseController";
import {
  GetAnalyticsReportParams,
  GetDetailReportParams,
} from "./_FunctionController";

export class _AnalyticController {
  private parent!: FirebaseController;

  constructor(parent: FirebaseController) {
    this.parent = parent;
  }

  async getAnalyticsReport(params: GetAnalyticsReportParams) {
    return await this.parent.Callable.getAnalyticsReport(params);
  }

  async getDetailReport(params: GetDetailReportParams) {
    // return await fetch(
    //   "http://127.0.0.1:5001/demo-project-snappy-staging/australia-southeast1/getUserReport",
    //   {
    //     method: "POST",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       data: params,
    //     }),
    //   },
    // ).then((res) => res.json());

    return await this.parent.Callable.getDetailReport(params);
  }
}
