import { RefObject, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";
import { jobSitesListCacheState } from "states/caches";

import { Box } from "@mui/material";

import { Client, GalleryV2, JobSite } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import DashboardContainer from "components/Dashboard/DashboardContainer";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";
import { Column } from "components/Dashboard/SettingsTable";
import DataChips from "components/Dashboard/DataChips";
import DashboardListTable from "components/Dashboard/DashboardListTable";

import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";

import _ from "lodash";
import { orderByIgnoreCase } from "utils/display";

const SORT_OPTIONS = ["Name"];

const ListView = ({
  displayClientList,
  headerRef,
  isLoading,
  jobSitesLookup,
}: {
  displayClientList: Client[];
  headerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  jobSitesLookup: { [id: number]: JobSite };
}) => {
  const navigate = useNavigate();

  const columns: Column[] = [
    { id: "name", label: "Name", width: "40%" },
    { id: "jobSite", label: "Job Site", width: "60%" },
  ];

  const rows = displayClientList.map((client, clientIndex) => {
    const activeJobSites = orderByIgnoreCase(
      client.sites.reduce<JobSite[]>((sites, siteId) => {
        const jobSite = jobSitesLookup[siteId as number];

        if (jobSite && !jobSite.archived) {
          sites.push(jobSite);
        }

        return sites;
      }, []),
      "name",
    );

    return {
      id: client.id?.toString() || clientIndex,
      name: client.name || "-",
      jobSite: (
        <DataChips
          items={activeJobSites}
          labelkey="name"
          isItemActive={(item) => {
            return item.associatedClients[0] === client.id;
          }}
          itemLink={(item) => {
            return `/job-sites/${item.id}`;
          }}
          openNewTab={false}
        />
      ),
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <DashboardListTable
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        noDataText="No client found."
        rowOnClick={(row) => {
          navigate(`/clients/${row.id}`);
        }}
      />
    </Box>
  );
};

const ClientIndexWindow = () => {
  const [displayClientList, setDisplayClientList] = useState<Client[]>([]);
  const [clientList, setClientList] = useState<Client[]>([]);

  const [currentSortType, setCurrentSortType] = useState<SortType>({
    type: SORT_OPTIONS[0],
    isAsc: true,
  });

  const jobSitesList = useAtomValue(jobSitesListCacheState);

  const jobSitesLookup = useMemo(() => {
    return _.keyBy(jobSitesList, "id");
  }, [jobSitesList]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const search = useAtomValue(searchState);

  const { trackAction } = useDashboardTracker("clients", true);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch]);

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    setIsLoading(true);
    try {
      const clientList = await getFirebaseController().Client.getClients();

      const sortedList = orderByIgnoreCase(clientList, "name");

      setDisplayClientList(sortedList);
      setClientList(sortedList);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleSearch = () => {
    let newClientList = _.cloneDeep(clientList);

    if (search.value) {
      trackAction("search");

      newClientList = newClientList.filter((client) => {
        const jobSiteNames = client.sites.map((siteId) => {
          return jobSitesLookup[siteId as number].name;
        });

        const searchFieldValues = [client.name, ...jobSiteNames];

        return searchFieldValues.some((value) => {
          return (
            value &&
            _.toLower(value.toString()).includes(_.toLower(search.value))
          );
        });
      });
    }

    setDisplayClientList(newClientList);
  };

  const handleSort = (sortType: string) => {
    const isAsc =
      currentSortType.type === sortType ? !currentSortType.isAsc : false;
    const order = isAsc ? "asc" : "desc";

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    let newClientList = _.cloneDeep(displayClientList);

    switch (sortType) {
      case "Name":
        trackAction("sort name");

        newClientList = orderByIgnoreCase(newClientList, "name", order);
        break;

      case "Email":
        trackAction("sort email");

        newClientList = orderByIgnoreCase(newClientList, "email", order);
        break;

      default:
        break;
    }

    setDisplayClientList(newClientList);
  };

  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <DashboardContainer NavbarProps={{ title: "Client Dashboard" }}>
      <DashboardHeader
        sortOptions={SORT_OPTIONS}
        currentSortType={currentSortType}
        handleSort={handleSort}
        buttons={[
          {
            label: "+ Add New Client",
            onClick: () => navigate("./new"),
          },
        ]}
      />

      <ListView
        displayClientList={displayClientList}
        headerRef={headerRef}
        isLoading={isLoading}
        jobSitesLookup={jobSitesLookup}
      />
    </DashboardContainer>
  );
};

export default ClientIndexWindow;
