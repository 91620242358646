import {
  Client,
  DeviceV2,
  GalleryV2,
  JobSite,
  User,
  UserRole,
} from "database/DataTypes";
import { atomWithReset } from "jotai/utils";

// all clients if admin user, else will only have the one from the current user role.
export const clientListCacheState = atomWithReset<Client[]>([]);

// all jobsites if admin user (not only the current user role logged in as), else will only have those from the current user role.
export const jobSitesListCacheState = atomWithReset<JobSite[]>([]);

export const deviceListCacheState = atomWithReset<DeviceV2[]>([]);
export const galleryListCacheState = atomWithReset<GalleryV2[]>([]);

export const userListCacheState = atomWithReset<User[]>([]);
export const userRolesListCacheState = atomWithReset<UserRole[]>([]);

export const galleryJobSiteLookupCacheState = atomWithReset<{
  [key: number]: JobSite;
}>({});
