import {
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  SxProps,
  useMediaQuery,
  Theme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

import { Link } from "react-router-dom";
import _ from "lodash";
import { Fragment, memo, useMemo, useState } from "react";

export type Breadcrumb = {
  label: string;
  path?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
};

const Breadcrumbs = ({
  breadcrumbs,
  sx,
}: {
  breadcrumbs: Breadcrumb[];
  sx?: SxProps;
}) => {
  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const [renderBreadcrumbs, menuBreadcrumbs]: [Breadcrumb[], Breadcrumb[]] =
    useMemo(() => {
      const displayMore = {
        label: "...",
        onClick: (e) => {
          setBreadcrumbsMenuAnchorEl(e.currentTarget);
        },
      };

      let a: Breadcrumb[] = [];
      let b: Breadcrumb[] = [];

      const count = 2;

      if (smallScreenAndUp || breadcrumbs.length <= count) {
        a = [...breadcrumbs];
      } else {
        a = [displayMore, ..._.drop(breadcrumbs, count)];
        b = _.take(breadcrumbs, count);
      }

      return [a, b];
    }, [breadcrumbs, smallScreenAndUp]);

  const [breadcrumbsMenuAnchorEl, setBreadcrumbsMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  return (
    <>
      <Menu
        anchorEl={breadcrumbsMenuAnchorEl}
        open={Boolean(breadcrumbsMenuAnchorEl)}
        onClose={() => setBreadcrumbsMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuBreadcrumbs.map((breadcrumb, breadcrumbIndex) => {
          return (
            <MenuItem
              key={breadcrumbIndex}
              dense
              sx={{
                py: 0,
                px: 0,
                fontSize: 12,
              }}
              onClick={() => {
                setBreadcrumbsMenuAnchorEl(null);
              }}
            >
              <MuiLink
                key={`${breadcrumb.path}_${breadcrumbIndex}`}
                component={Link}
                color={"inherit"}
                to={breadcrumb.path || ""}
                sx={{
                  px: 2,
                  width: "100%",
                  display: "flex",

                  lineHeight: 1,

                  ":hover": {
                    color: ({ palette }) => palette.white.main,
                    textDecoration: "underline !important",
                  },
                }}
              >
                {breadcrumb.label}
              </MuiLink>
            </MenuItem>
          );
        })}
      </Menu>

      <MuiBreadcrumbs
        id="breadcrumb-container"
        sx={{
          ...sx,
          overflow: "auto",

          ".MuiBreadcrumbs-ol": {
            flexWrap: "nowrap",
          },
        }}
        separator={
          <NavigateNext
            sx={{
              fontSize: 20,
              color: ({ palette }) => palette.grey[100],
            }}
          />
        }
      >
        {renderBreadcrumbs.map((breadcrumb, breadcrumbIndex) => {
          const isLast = breadcrumbIndex === breadcrumbs.length - 1;

          return [
            breadcrumb.onClick ? (
              <Typography
                key={`${breadcrumb.path}_${breadcrumbIndex}`}
                onClick={breadcrumb.onClick}
                sx={{
                  color: ({ palette }) => palette.grey[100],

                  fontSize: 12,
                  lineHeight: 1,

                  ":hover": {
                    color: ({ palette }) => palette.white.main,
                    textDecoration: "underline !important",
                  },

                  cursor: "pointer",
                }}
              >
                {breadcrumb.label}
              </Typography>
            ) : (
              <MuiLink
                key={`${breadcrumb.path}_${breadcrumbIndex}`}
                component={Link}
                color={"inherit"}
                to={breadcrumb.path || ""}
                sx={{
                  display: "flex",

                  color: ({ palette }) =>
                    isLast ? palette.white.main : palette.grey[100],

                  fontSize: isLast ? 14 : 12,
                  lineHeight: 1,

                  ":hover": {
                    color: ({ palette }) => palette.white.main,
                    textDecoration: "underline !important",
                  },

                  pointerEvents:
                    breadcrumb.disabled || isLast ? "none" : "inherit",
                }}
              >
                {breadcrumb.label}
              </MuiLink>
            ),
          ];
        })}
      </MuiBreadcrumbs>
    </>
  );
};

export default memo(Breadcrumbs, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
