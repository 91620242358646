import { ReactNode } from "react";

import { Box, Container } from "@mui/material";

import Navbar, { NavbarProps } from "components/Layouts/Navbar";

const DashboardContainer = ({
  children,
  NavbarProps,
  disabled,
}: {
  children: ReactNode;
  NavbarProps: NavbarProps;
  disabled?: boolean;
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Box>
      <Navbar {...NavbarProps} />

      <Box
        sx={({ customConfig, palette }) => ({
          pt: `calc(${customConfig.navbarHeight} + ${customConfig.subNavbarHeight})`,
          color: palette.white.main,
        })}
      >
        <Container
          fixed
          maxWidth={"xl"}
          sx={{
            height: "100%",
            p: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardContainer;
