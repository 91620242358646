import { ImageViewerAction } from "hooks/eventTracker/useImageViewerTracker";
import { TimelapsePlayerAction } from "hooks/eventTracker/useTimelapsePlayerTracker";

type HeatmapData<T> = {
  name: T;
  pos: {
    top: string;
    left: string;
  };
  count: number;
};

export const IMAGE_VIEWER_ACTIONS_NAMES: ImageViewerAction[] = [
  "item toggle",
  "item search",
  "favorite",
  "comments",
  "tags",
  "weather info",
  "image info",
  "download original",
  "download with timestamp",
  "compare",
  "timelapse player",
  "all images",
  "settings",
  "previous image",
  "next image",
  "thumbnails slider",
  "previous day bottom",
  "previous image bottom",
  "date picker",
  "next image bottom",
  "next day bottom",
  "zoom slider",
];

export const TIMELAPSE_PLAYER_ACTIONS_NAMES: TimelapsePlayerAction[] = [
  "item toggle",
  "item search",
  "download",
  "date picker",
  "player seek",
  "player finished",
  "player rewind",
  "player play",
  "player pause",
  "player fast forward",
  "compile",
];

export const IMAGE_VIEWER_HEAT_MAP_DATA: HeatmapData<
  ImageViewerAction | "download"
>[] = [
  {
    name: "item toggle",
    pos: {
      top: "2.5%",
      left: "50%",
    },
    count: 0,
  },
  {
    name: "settings",
    pos: {
      top: "2.5%",
      left: "98.9%",
    },
    count: 0,
  },
  {
    name: "all images",
    pos: {
      top: "2.5%",
      left: "96.6%",
    },
    count: 0,
  },
  {
    name: "timelapse player",
    pos: {
      top: "2.5%",
      left: "94.3%",
    },
    count: 0,
  },
  {
    name: "compare",
    pos: {
      top: "2.5%",
      left: "92%",
    },
    count: 0,
  },
  {
    name: "download",
    pos: {
      top: "2.5%",
      left: "89.7%",
    },
    count: 0,
  },
  {
    name: "image info",
    pos: {
      top: "2.5%",
      left: "87.4%",
    },
    count: 0,
  },
  {
    name: "weather info",
    pos: {
      top: "2.5%",
      left: "85.1%",
    },
    count: 0,
  },
  {
    name: "tags",
    pos: {
      top: "2.5%",
      left: "82.8%",
    },
    count: 0,
  },
  {
    name: "comments",
    pos: {
      top: "2.5%",
      left: "80.5%",
    },
    count: 0,
  },
  {
    name: "favorite",
    pos: {
      top: "2.5%",
      left: "78.2%",
    },
    count: 0,
  },
  {
    name: "next image",
    pos: {
      top: "50%",
      left: "98.5%",
    },
    count: 0,
  },
  {
    name: "previous image",
    pos: {
      top: "50%",
      left: "1.5%",
    },
    count: 0,
  },
  {
    name: "thumbnails slider",
    pos: {
      top: "97.75%",
      left: "9%",
    },
    count: 0,
  },
  {
    name: "previous day bottom",
    pos: {
      top: "97.75%",
      left: "41.5%",
    },
    count: 0,
  },
  {
    name: "previous image bottom",
    pos: {
      top: "97.75%",
      left: "43.3%",
    },
    count: 0,
  },
  {
    name: "date picker",
    pos: {
      top: "97.75%",
      left: "45%",
    },
    count: 0,
  },
  {
    name: "next image bottom",
    pos: {
      top: "97.75%",
      left: "56.7%",
    },
    count: 0,
  },
  {
    name: "next day bottom",
    pos: {
      top: "97.75%",
      left: "58.5%",
    },
    count: 0,
  },
  {
    name: "zoom slider",
    pos: {
      top: "97.75%",
      left: "81%",
    },
    count: 0,
  },
];

export const TIMELAPSE_PLAYER_HEAT_MAP_DATA: HeatmapData<TimelapsePlayerAction>[] =
  [
    {
      name: "item toggle",
      pos: {
        top: "2.5%",
        left: "50%",
      },
      count: 0,
    },
    {
      name: "download",
      pos: {
        top: "2.5%",
        left: "96.6%",
      },
      count: 0,
    },
    {
      name: "player seek",
      pos: {
        top: "95.25%",
        left: "30%",
      },
      count: 0,
    },
    {
      name: "player finished",
      pos: {
        top: "95.25%",
        left: "98.5%",
      },
      count: 0,
    },
    //   {
    //     name: "date picker",
    //     pos: {
    //       top: "97.75%",
    //       left: "35.5%",
    //     },
    //     count: 0,
    //   },
    {
      name: "date picker",
      pos: {
        top: "97.75%",
        left: "41%",
      },
      count: 0,
    },
    {
      name: "player rewind",
      pos: {
        top: "97.75%",
        left: "47.5%",
      },
      count: 0,
    },
    {
      name: "player pause",
      pos: {
        top: "95.25%",
        left: "50%",
      },
      count: 0,
    },
    {
      name: "player play",
      pos: {
        top: "97.75%",
        left: "50%",
      },
      count: 0,
    },
    {
      name: "player fast forward",
      pos: {
        top: "97.75%",
        left: "52.5%",
      },
      count: 0,
    },
    //   {
    //     name: "next image bottom",
    //     pos: {
    //       top: "97.75%",
    //       left: "59.7%",
    //     },
    //     count: 0,
    //   },
    {
      name: "compile",
      pos: {
        top: "97.75%",
        left: "64.5%",
      },
      count: 0,
    },
    //   {
    //     name: "blending",
    //     pos: {
    //       top: "97.75%",
    //       left: "67.3%",
    //     },
    //     count: 0,
    //   },
  ];
