import {
  Auth,
  Persistence,
  User as AuthUser,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { query, where } from "firebase/firestore";

import { FirebaseController } from "database/FirebaseController";

import { User } from "database/DataTypes";

import _ from "lodash";

export class _AuthController {
  private parent!: FirebaseController;
  auth!: Auth;

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.auth = this.parent.getAuth();
  }

  async signIn(username, password) {
    return await this.parent
      .getDocumentWithValue<AuthUser>("users", "username", username)
      .then(async (user) => {
        if (user) {
          return await signInWithEmailAndPassword(
            this.auth,
            user.email as string,
            password,
          )
            .then((credentials) => {
              credentials.user.getIdToken(true);

              this.parent.signedIn = true;

              return credentials;
            })
            .catch(() => {
              throw new Error("Sign in Failed.");
            });
        } else {
          throw new Error("User not found.");
        }
      });
  }

  async logOut() {
    return await this.auth
      .signOut()
      .then(() => {
        this.parent.signedIn = false;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async listenUser(uid: string, callback: (error: any, user: User) => void) {
    this.parent.listenDocumentWithQuery<User>(
      query(this.parent.getColRef<User>("users"), where("userId", "==", uid)),
      async (err, data) => {
        if (err || !data) {
          return;
        }

        const authUser = this.auth.currentUser;
        let user = data;

        if (!user.username) {
          user = {
            ...user,
            username:
              authUser!.displayName || this.emailToName(authUser!.email),
          };

          // await this.updateUser(user.id, user);
        }
        this.parent.currentUser = user;

        callback(null, user);
      },
    );
  }

  async resetPassword(authUserId, password) {
    return await this.parent.Callable.resetPassword({ authUserId, password });
  }

  async setCurrentPersistence(newPersistence: Persistence) {
    return setPersistence(this.auth, newPersistence);
  }

  private emailToName(email) {
    const name = email.substring(0, email.indexOf("@"));

    return _.startCase(_.lowerCase(name));
  }
}
