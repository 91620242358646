import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import reliveItTheme from "theme/reliveItTheme";

import RoutesContainer from "components/RoutesContainer/RoutesContainer";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import AuthWindow from "Windows/Auth/AuthWindow";

import { SnackbarProvider } from "context/Snackbar/SnackbarContext";
import useAccess from "hooks/useAccess";
import { AuthProvider, useAuth } from "context/Auth/AuthContext";

import { initTracker } from "hooks/eventTracker/useEventTracker";
import GlobalSnackbar from "components/Snackbar/GlobalSnackbar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getFirebaseController } from "database/FirebaseController";
import { getDropboxController } from "database/DropboxController";

initTracker();

const Content = () => {
  const { isLoading, currentUser } = useAuth();
  const { isAdmin } = useAccess();

  return (
    <>
      {!isLoading && (
        <>
          {currentUser ? (
            <DashboardLayout>
              <RoutesContainer isAdmin={isAdmin || false} />
            </DashboardLayout>
          ) : (
            <AuthWindow />
          )}
        </>
      )}
    </>
  );
};

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // init when app start
    getFirebaseController();
    // getDropboxController().setupToken();
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement!.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;
  }, [pathname]);

  return (
    <SnackbarProvider>
      <AuthProvider>
        <ThemeProvider theme={createTheme(reliveItTheme)}>
          <CssBaseline />

          <Content />

          <GlobalSnackbar />
        </ThemeProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
