import { Fragment, ReactNode, forwardRef, memo } from "react";

import {
  Grid,
  Typography,
  Button,
  Box,
  alpha,
  useMediaQuery,
} from "@mui/material";

import _ from "lodash";
import { Theme } from "@mui/material/styles";

export interface DashboardHeaderButton {
  label: string;
  onClick?: () => void;
  isHidden?: boolean;
  component?: ReactNode;
}

export interface SortType {
  type: string;
  isAsc: boolean;
}

export interface ViewOption {
  label: string;
  onClick: () => void;
  key: string;
}

interface DashboardHeaderProps {
  sortOptions: string[];
  displayOptions?: string[];
  handleSort: (sortType: string) => void;
  handleDisplay?: (displayOption) => void;
  currentSortType: SortType;
  currentDisplayOptions?: string[];
  buttons?: DashboardHeaderButton[];
  viewOptions?: ViewOption[];
  currentViewType?: string;
  disabled?: boolean;
}

const DashboardHeader = forwardRef<HTMLDivElement, DashboardHeaderProps>(
  (
    {
      sortOptions,
      displayOptions,
      handleSort,
      handleDisplay,
      currentSortType,
      currentDisplayOptions,
      buttons,
      viewOptions,
      currentViewType,
      disabled,
    },
    ref,
  ) => {
    const smallScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm"),
    );
    if (disabled) {
      return null;
    }

    return (
      <Grid
        ref={ref}
        container
        mb={{
          xs: 2,
          sm: 3,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        columnSpacing={4}
        rowSpacing={2}
        sx={{
          overflow: "hidden",
        }}
      >
        <Grid
          item
          sx={{
            minWidth: {
              xs: "100%",
              sm: 0,
            },
          }}
        >
          <Grid container alignItems={"center"} spacing={1}>
            {smallScreenAndUp && (
              <Grid item>
                <Typography>Sort:</Typography>
              </Grid>
            )}

            <Grid
              item
              sx={{
                flex: 1,
                overflow: "hidden",
              }}
            >
              <Grid
                container
                gap={1}
                sx={{
                  flexWrap: "nowrap !important",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                }}
              >
                {sortOptions.map((option, optionIndex) => {
                  const isActive = currentSortType.type === option;

                  return (
                    <Grid item key={optionIndex}>
                      <Button
                        onClick={() => handleSort(option)}
                        color="white"
                        size="small"
                        variant="outlined"
                        sx={{
                          height: "100%",
                          width: "max-content",
                          lineHeight: 1,
                          p: 1,
                          textTransform: "none",
                          backgroundColor: ({ palette }) =>
                            isActive ? palette.secondary.light : null,
                          border: isActive ? "1px solid white" : null,

                          ":hover": {
                            background: ({ palette }) =>
                              isActive ? palette.grey[700] : "inherit",
                          },
                        }}
                      >
                        {option}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {displayOptions && (
          <Grid
            item
            sx={{
              flex: 1,
              minWidth: {
                xs: "100%",
                sm: 0,
              },
            }}
          >
            <Grid container alignItems={"center"} spacing={1}>
              {smallScreenAndUp && (
                <Grid item>
                  <Typography>Show:</Typography>
                </Grid>
              )}

              <Grid
                item
                sx={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <Grid
                  container
                  gap={1}
                  sx={{
                    flexWrap: "nowrap !important",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                  }}
                >
                  {displayOptions.map((option, optionIndex) => {
                    const isActive = currentDisplayOptions!.includes(option);

                    return (
                      <Grid item key={optionIndex}>
                        <Button
                          onClick={() => handleDisplay!(option)}
                          color="white"
                          size="small"
                          variant="outlined"
                          sx={{
                            height: "100%",
                            width: "max-content",
                            lineHeight: 1,
                            p: 1,
                            textTransform: "none",
                            backgroundColor: ({ palette }) =>
                              isActive ? palette.secondary.light : null,
                            border: isActive ? "1px solid white" : null,
                            ":hover": {
                              background: ({ palette }) =>
                                isActive ? palette.grey[700] : "inherit",
                            },
                          }}
                        >
                          {option}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {viewOptions && viewOptions.length > 0 && (
          <Grid item>
            <Box
              sx={{
                background: ({ palette }) => alpha(palette.secondary.dark, 0.5),
                borderRadius: 1,
              }}
            >
              {viewOptions.map((optionButton, optionButtonIndex) => {
                const isActive = currentViewType === optionButton.key;

                return (
                  <Fragment key={optionButton.key}>
                    <Button
                      onClick={optionButton.onClick}
                      size="small"
                      color="white"
                      sx={{
                        textTransform: "none",
                        fontWeight: isActive ? "bold" : "normal",
                        color: ({ palette }) =>
                          isActive
                            ? palette.white.main
                            : alpha(palette.white.main, 0.8),

                        px: 2,
                        width: "max-content",
                      }}
                    >
                      {optionButton.label}
                    </Button>

                    {optionButtonIndex !== viewOptions.length - 1 && (
                      <Typography
                        display={"inline"}
                        sx={{
                          color: ({ palette }) =>
                            alpha(palette.white.main, 0.9),
                        }}
                      >
                        |
                      </Typography>
                    )}
                  </Fragment>
                );
              })}
            </Box>
          </Grid>
        )}

        {buttons?.map((button, buttonIndex) => {
          return (
            <Fragment key={buttonIndex}>
              {!button.isHidden && (
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "max-content",
                    },
                  }}
                >
                  {button.component ? (
                    button.component
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="light"
                      onClick={button.onClick}
                      sx={{
                        width: "100%",
                        display: "block",
                        px: 2,
                        textTransform: "none",
                        color: ({ palette }) => palette.secondary.main,
                      }}
                    >
                      {button.label}
                    </Button>
                  )}
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
    );
  },
);

export default memo(DashboardHeader, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
