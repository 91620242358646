import { createContext, useContext, useEffect, useState } from "react";

import { User as AuthUser, onAuthStateChanged } from "firebase/auth";

import { useAtom, useSetAtom } from "jotai";
import {
  currentUserState,
  currentUserRoleState,
  userRolesState,
  currentJobSitesState,
  currentClientState,
} from "states/auth";
import { isSidenavShowState } from "states/layout";

import { Client, JobSite, User, UserRole } from "database/DataTypes";

import { useSnackbar } from "context/Snackbar/SnackbarContext";
import useLocalStorage from "hooks/useLocalStorage";
import { useResetAtom } from "jotai/utils";
import {
  clientListCacheState,
  deviceListCacheState,
  jobSitesListCacheState,
} from "states/caches";
import {
  gridComponentCahcesState,
  imageViewerComponentCahcesState,
} from "states/imageViewer";
import { isSubmittedState } from "states/feedback";
import { baseEventTracker } from "hooks/eventTracker/useEventTracker";

import { auth, getFirebaseController } from "database/FirebaseController";
import _ from "lodash";
import { orderByIgnoreCase } from "utils/display";

interface AuthContextType {
  isLoading: boolean;
  currentUser: User | null;
  handleUserRoleChanged: (userRoleId: UserRole["id"], client?: Client) => void;
  handleClientChanged: (client: Client) => void;
}

let _clientJobSitesLookupCaches: { [key: number]: JobSite[] } = {};

const AuthContext = createContext<AuthContextType>({
  isLoading: true,
  currentUser: null,
  handleUserRoleChanged: () => {},
  handleClientChanged: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

let _userEventInfoCache = {};

export const AuthProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const [userRoles, setUserRoles] = useAtom(userRolesState);
  const setCurrentUserRole = useSetAtom(currentUserRoleState);
  const setCurrentJobSites = useSetAtom(currentJobSitesState);
  const setCurrentClient = useSetAtom(currentClientState);
  const setClientListCache = useSetAtom(clientListCacheState);
  const setJobSitesListCache = useSetAtom(jobSitesListCacheState);

  const resetClientListCache = useResetAtom(clientListCacheState);
  const resetJobSiteListCache = useResetAtom(jobSitesListCacheState);

  const resetDeviceListCache = useResetAtom(deviceListCacheState);
  const resetImageViewerComponentCahces = useResetAtom(
    imageViewerComponentCahcesState,
  );
  const resetImagesGridComponentCahces = useResetAtom(gridComponentCahcesState);
  const resetIsFeedbackSubmitted = useResetAtom(isSubmittedState);

  const isSidenavShow = useSetAtom(isSidenavShowState);

  const { setSnackbarProps } = useSnackbar();
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage<boolean>(
    "logged-in",
    false,
  );

  const [userRoleIdCache, setUserRoleIdCache] = useLocalStorage<number | null>(
    "current-user-role-id",
    null,
    true,
  );
  const [clientIdCache, setClientIdCache] = useLocalStorage<number | null>(
    "current-client-id",
    null,
    true,
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: AuthUser | null) => {
      handleAuthChanged(user, isLoggedIn);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleUserRoleChanged = async (
    userRoleId: UserRole["id"],
    client?: Client,
  ) => {
    const newUserRole = userRoles.find(
      (userRole) => userRole.id === userRoleId,
    );

    if (newUserRole) {
      const firebaseController = getFirebaseController();

      const newClient: Client | undefined =
        client ||
        (await firebaseController.Client.getClient(
          newUserRole.associatedClient,
        ));

      const newJobSites: JobSite[] =
        _clientJobSitesLookupCaches[newClient?.id as number] ||
        (await firebaseController.JobSite.getJobSites({
          ids: newUserRole.accessableJobSites as number[],
        }));

      if (newClient && newJobSites) {
        setCurrentUserRole(newUserRole);
        setCurrentJobSites(newJobSites);
        setCurrentClient(newClient);
        setUserRoleIdCache(newUserRole.id);

        _clientJobSitesLookupCaches[newClient.id as number] = newJobSites;
      }
    }
  };

  const handleClientChanged = async (client: Client) => {
    if (client) {
      const jobSites =
        _clientJobSitesLookupCaches[client.id as number] ||
        (await getFirebaseController().JobSite.getJobSites({
          ids: client.sites as number[],
        }));

      _clientJobSitesLookupCaches[client.id as number] = jobSites;

      setCurrentClient(client);
      setCurrentJobSites(jobSites);
      setClientIdCache(client.id);
    }
  };

  const clearLocalStorage = () => {
    // window.localStorage.removeItem("gallery-display-type");

    window.localStorage.clear();
  };

  const clearCaches = () => {
    resetClientListCache();
    resetJobSiteListCache();
    resetDeviceListCache();
    resetImageViewerComponentCahces();
    resetImagesGridComponentCahces();
    setClientListCache([]);

    _clientJobSitesLookupCaches = {};
  };

  const cleanUp = () => {
    setCurrentUser(null);
    setUserRoles([]);
    setCurrentUserRole(null);
    setCurrentJobSites([]);
    setCurrentClient(null);
    setIsLoading(false);
    isSidenavShow(false);
    setIsLoggedIn(false);
    setUserRoleIdCache(null);
    resetIsFeedbackSubmitted();

    clearCaches();
    clearLocalStorage();

    baseEventTracker("logout", _userEventInfoCache);

    _userEventInfoCache = {};
  };

  const handleAuthChanged = async (
    authUser: AuthUser | null,
    isLoggedIn: boolean,
  ) => {
    setIsLoading(true);

    const firebaseController = getFirebaseController();

    if (authUser) {
      const previousVersion = localStorage.getItem("app-version");
      const latestVersion = process.env.REACT_APP_VERSION;
      const appUpdated = !previousVersion || previousVersion !== latestVersion;

      if (appUpdated) {
        // clear old cahces to prevent it from breaking the new version

        clearLocalStorage();

        localStorage.setItem("app-version", latestVersion as string);

        setIsLoggedIn(true, appUpdated);
      }

      firebaseController.Auth.listenUser(authUser.uid, async (error, user) => {
        try {
          if (error) {
            throw new Error("User not found.");
          }

          const userRoles: UserRole[] =
            await firebaseController.User.getUserRolesByUserId(
              user.id as number,
            );

          let userRole = userRoles[0];

          if (userRoleIdCache) {
            const cacheUserRole = userRoles.find(
              (userRole) => userRole.id === userRoleIdCache,
            );

            if (cacheUserRole) {
              userRole = cacheUserRole;
            }
          }

          const isAdmin = userRole.accessLevel === "3";
          const isNoAccess = userRole.accessLevel === "2";

          if (isNoAccess) {
            return await firebaseController.Auth.logOut().then(() => {
              setSnackbarProps({
                open: true,
                content: "Access denied!",
                severity: "error",
              });
            });
          }

          let currentClient: Client;
          let currentJobSites: JobSite[] = [];

          if (isAdmin) {
            currentClient = await firebaseController.Client.getClients().then(
              (clients) => {
                const sortedClients = orderByIgnoreCase(clients, "name");

                setClientListCache(sortedClients);

                let cacheClient;
                let userClient;

                clients.forEach((client) => {
                  if (client.id === clientIdCache) {
                    cacheClient = client;
                  } else if (client.id === userRole.associatedClient) {
                    userClient = client;
                  }
                });

                return cacheClient || userClient;
              },
            );

            currentJobSites =
              await firebaseController.JobSite.getJobSites().then(
                (jobSites) => {
                  const sortedJobSites = orderByIgnoreCase(jobSites, "name");

                  setJobSitesListCache(sortedJobSites);

                  return jobSites.filter((jobSite) => {
                    return currentClient.sites.includes(jobSite.id);
                  });
                },
              );
          } else {
            currentClient = (await firebaseController.Client.getClient(
              userRole.associatedClient,
            )) as Client;

            currentJobSites = await firebaseController.JobSite.getJobSites({
              ids: userRole.accessableJobSites as number[],
            });

            setClientListCache([currentClient]);
            setJobSitesListCache(currentJobSites);
          }

          if (!isLoggedIn) {
            // first time login, not from refresh

            setIsLoggedIn(true);

            setSnackbarProps({
              open: true,
              content: `Login successfully!`,
            });
          }

          firebaseController.Image.initDropboxRefreshToken();

          _userEventInfoCache = {
            user_name: user.username,
            user_id: user.id as number,
            user_client_id: currentClient.id,
            user_client_name: currentClient.name,
            user_access_level: userRole.accessLevel,
          };

          baseEventTracker("login", _userEventInfoCache);

          setCurrentUser(user);
          setUserRoles(userRoles);
          setCurrentUserRole(userRole);
          setUserRoleIdCache(userRole.id, appUpdated);
          setClientIdCache(currentClient.id, appUpdated);
          setCurrentJobSites(currentJobSites);
          setCurrentClient(currentClient);
          setIsLoading(false);
        } catch (error) {
          console.error(error);

          cleanUp();
        }
      });
    } else {
      // logout

      cleanUp();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        currentUser,
        handleUserRoleChanged,
        handleClientChanged,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
