import _, { LoDashStatic } from "lodash";

export const getDisplayValue = <T = string | number | any[]>(
  value: T,
  formater?: (value: T) => string | number,
) => {
  return _.isUndefined(value) || _.isNull(value) || value === ""
    ? "-"
    : formater
    ? formater(value)
    : value;
};

export const orderByIgnoreCase = <T>(
  data: T[],
  key: string,
  order: "asc" | "desc" = "asc",
) => {
  return _.orderBy<T>(
    data,
    (item) => {
      return item[key]?.toLocaleLowerCase();
    },
    order,
  );
};
