import React, { RefObject, useCallback, useEffect, useState } from "react";

const THRESHOLD = 1;

type UseIsScrollCompleteOptions<TElement> = {
  ref: RefObject<TElement>;
  querySelector?: string;
  removeOnComplete?: boolean;
  trigger?: boolean;
};

/**
 * A function that takes an object with options a and b.
 * @param {Object} options - An object containing options.
 * @param {*} options.ref - Rej Object.
 * @param {*} options.removeOnComplete - If true, the scroll event listener will be removed once the scroll is completed.
 * @param {*} options.trigger - Re-calculate the scroll whenever this value changed, can be use to re-calculate when the ref content changed.
 */
const useIsScrollComplete = <TElement extends HTMLElement>({
  ref,
  querySelector,
  removeOnComplete = false,
  trigger,
}: UseIsScrollCompleteOptions<TElement>) => {
  const [isScrollComplete, setIsScrollComplete] = useState(false);

  const onScroll: EventListener = useCallback(({ currentTarget }) => {
    const { scrollHeight, clientHeight, scrollTop } = currentTarget as TElement;

    if (Math.abs(scrollHeight - clientHeight - scrollTop) < THRESHOLD) {
      setIsScrollComplete(true);
    } else {
      setIsScrollComplete(false);
    }
  }, []);

  useEffect(() => {
    setIsScrollComplete(false);
  }, [trigger]);

  useEffect(() => {
    const element = ref.current;
    const targetElement = querySelector
      ? element?.querySelector(querySelector)
      : element;

    if (targetElement) {
      const { scrollHeight, clientHeight } = targetElement;

      if (scrollHeight === clientHeight) {
        // set scroll is complete if there is no scroll
        setIsScrollComplete(true);
      }

      targetElement.addEventListener("scroll", onScroll);

      if (isScrollComplete && removeOnComplete) {
        targetElement.removeEventListener("scroll", onScroll);
      }
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener("scroll", onScroll);
      }
    };
  }, [
    isScrollComplete,
    removeOnComplete,
    onScroll,
    querySelector,
    ref,
    trigger,
  ]);

  return { isScrollComplete };
};

export default useIsScrollComplete;
