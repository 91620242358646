/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Vision UI Dashboard PRO React helper functions

import { alpha } from "@mui/material";
import pxToRem from "theme/functions/pxToRem";

export type BoxShadowFunction = (
  offset: number[],
  radius: number[],
  color: string,
  opacity: number,
  inset: string,
) => string;

const boxShadow: BoxShadowFunction = (
  offset = [],
  radius = [],
  color,
  opacity,
  inset = "",
) => {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread,
  )} ${alpha(color, opacity)}`;
};

export default boxShadow;
