//https://dropbox.github.io/dropbox-api-v2-explorer/
// let dropBox = new DropboxRequests()
// dropBox.getExampleImage().then((val)=>{
//     this.testImage = val;
//     this.setState({refreshTestImage:!this.state.refreshTestImage})
// })

// TODO: refactor this file

import { fetchWithRetry } from "utils/fetcher";
import { getFirebaseController } from "./FirebaseController";

export var DropboxObject = null;
export function getDropboxObject() {
  if (DropboxObject == null) {
    DropboxObject = new DropboxRequests();
  }
  return DropboxObject;
}

export class DropboxRequests {
  constructor(props) {
    if (DropboxObject != null) {
      return DropboxObject;
    }
    this.token = null;
    this.findingToken = false;
    this.searchingPromise = null;
  }

  generateAuthToken() {
    return this.generateUserAuthToken();
  }

  //makes sure the token has been generated. If it has already started / finished being generated, return the promise for the token generation.
  async setupToken() {
    if (
      (this.token == null || this.token.access_token == null) &&
      this.findingToken == false
    ) {
      console.log("Getting new token");
      return this.generateFromRefreshToken();
    } else {
      return this.searchingPromise;
    }
  }

  //Generates a token using the provided refresh token
  async generateFromRefreshToken() {
    if (this.findingToken) {
      return this.searchingPromise;
    }

    this.findingToken = true;
    this.searchingPromise = new Promise((res, err) => {
      //Don't know why, but they wanted it as form data. Tried as json but it didn't work.
      let formData = new FormData();
      formData.append(
        "refresh_token",
        getFirebaseController().dropboxRefreshToken,
      );
      formData.append("client_id", process.env.REACT_APP_DROPBOXV1KEY);
      formData.append("client_secret", process.env.REACT_APP_DROPBOXV1SECRET);
      formData.append("grant_type", "refresh_token");
      const resp = fetch("https://api.dropboxapi.com/oauth2/token", {
        method: "POST",
        body: formData,
      })
        .then((val) => {
          //if successful, convert to json and return
          val.json().then((jsonVal) => {
            this.token = jsonVal;
            this.findingToken = false;
            this.refreshTimer();
            res(jsonVal);
          });
        })
        .catch((error) => {
          console.log("Auth error was" + error);
        });
    });
    return this.searchingPromise;
  }

  //Create a refresh token from a given access code (generated from generateRefreshTokenWindow)
  async createRefreshToken(dropBoxKey) {
    return new Promise((res, err) => {
      let formData = new FormData();
      formData.append("code", dropBoxKey);
      formData.append("client_id", process.env.REACT_APP_DROPBOXV1KEY);
      formData.append("client_secret", process.env.REACT_APP_DROPBOXV1SECRET);
      formData.append(
        "redirect_uri",
        "https://project-snappy-staging.web.app/Settings/",
      );
      formData.append("grant_type", "authorization_code");
      const resp = fetch("https://api.dropboxapi.com/oauth2/token", {
        method: "POST",
        body: formData,
      })
        .then((val) => {
          //if successful, convert to json and return
          val.json().then((jsonVal) => {
            console.log(jsonVal.refreshToken);
            res(jsonVal.refresh_token);
          });
        })
        .catch((error) => {
          console.log("Auth error was" + error);
          err(error);
        });
    });
  }

  //Gets access to a user's dropbox. This can then be used to generate a refresh token.
  async generateRefreshTokenWindow(returnLocation) {
    //gets the user to sign in and allow access
    window.open(
      `https://www.dropbox.com/oauth2/authorize?client_id=${process.env.REACT_APP_DROPBOXV1KEY}&token_access_type=offline&response_type=code&redirect_uri=${returnLocation}`,
      "_self",
    );
  }

  //Refreshes the access token when it expires.
  async refreshTimer() {
    if (this.token.expires_in == null) {
      this.token.expires_in = 600;
    }
    console.log("new token in " + this.token.expires_in / 3600 + " hours");
    setTimeout(
      () => {
        console.log("generating new token");
        this.generateFromRefreshToken();
      },
      (this.token.expires_in - 60) * 1000,
    ); //in ms
  }

  //Gets image at given directory
  async getImage(directory, fileName) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }
    return new Promise((res, err) => {
      if (this.token.access_token == null) {
        err("No access token");
        return;
      }
      console.log("Downloading " + directory + fileName);
      const resp = fetch(`https://content.dropboxapi.com/2/files/download`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token.access_token}`,
          "Dropbox-API-Arg": `{"path":"${directory}"}`, //They wanted this one in the header, not the body
        },
      })
        .then((resp) => {
          resp.blob().then((val) => {
            val = val.slice(0, val.size, "image/jpeg");

            const testImage = URL.createObjectURL(val);
            const link = document.createElement("a");

            link.href = testImage;

            const extension = fileName.split(".").pop();
            const name = fileName.replace(`.${extension}`, "");

            link.download = `${name}.jpg`;
            res({ url: testImage, file: link, blob: val });
          });
        })
        .catch((error) => {
          console.log("Response error:", error);
          err(error);
        });
    });
  }

  async getImageThumbnails(directories) {
    return await fetchWithRetry(
      "https://content.dropboxapi.com/2/files/get_thumbnail_batch",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token.access_token}`,
        },
        body: JSON.stringify({
          entries: directories.map((directory) => {
            return {
              path: directory,
              size: "w1024h768",
            };
          }),
        }),
      },
    )
      .then((data) => data.json())
      .then((data) => {
        const images = data.entries.map((data) => {
          const byteCharacters = atob(data.thumbnail);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "image/jpg" });

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        });

        return images;
      })
      .catch(function (error) {
        console.error(error.error || error);

        return [];
      });
  }

  // gets thumbnail at given directory
  async getImageThumbnail(directory) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }

    if (this.token.access_token == null) {
      throw new Error("No access token");
    }

    // retry the request if it fails due to Dropbox request rate limit.
    return await fetchWithRetry(
      `https://content.dropboxapi.com/2/files/get_thumbnail`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token.access_token}`,
          "Dropbox-API-Arg": `{"path":"${directory}", "size" : "w256h256"}`,
        },
      },
      2,
    )
      .then((resp) => {
        return resp.blob().then((val) => {
          const returnImage = URL.createObjectURL(val);
          return returnImage;
        });
      })
      .catch((error) => {
        return error;
      });
  }

  //list files and folders in given directory
  async listDirectory(directory, recursive = false) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }
    return new Promise((res, err) => {
      if (this.token.access_token == null) {
        err("No access token");
        return;
      }
      const resp = fetchWithRetry(
        `https://api.dropboxapi.com/2/files/list_folder`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token.access_token}`,
            "Content-Type": "application/json",
            // "Dropbox-API-Arg": `{"path":"${directory}"}`
          },
          body: JSON.stringify({ path: directory, recursive: false }), // because they wanted this one as a json in the body
        },
      )
        .then((resp) => {
          resp
            .json()
            .then((val) => {
              if (val != null && val.entries != null) {
                //get the whole file content
                this.searchUntilComplete(val)
                  .then((resultSearch) => {
                    if (!recursive) {
                      res(resultSearch);
                    } else {
                      let finalArray = resultSearch;
                      let count = 0;
                      let totalCount = resultSearch.length;
                      //for each item in the directory,
                      resultSearch.forEach((element) => {
                        if (element[".tag"] == "folder") {
                          //re-do the search using the folder's directory
                          this.listDirectory(element.path_display, true)
                            .then((result) => {
                              //and add the results to the returned array.
                              finalArray = finalArray.concat(result);
                              count++;

                              //If there is nothing else to be searched, return the final array.
                              if (count == totalCount) {
                                res(finalArray);
                              }
                            })
                            .catch((error) => {
                              //If there was an error, log it and continue as normal.
                              console.log("File search errored " + error);
                              count++;
                              if (count == totalCount) {
                                res(finalArray);
                              }
                            });
                        } else {
                          //For files, continue as normal. It will have been added to the array already.
                          count++;
                          if (count == totalCount) {
                            res(finalArray);
                          }
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    err(error);
                  });
              } else {
                console.log(`Directory ${directory} empty: `, val);
                err("no values");
              }
            })
            .catch((error) => {
              err(error);
            });
        })
        .catch((error) => {
          console.log("Directory Response error:", error);
          err(error);
        });
    });
  }

  //Continues a search until it has all the files in a directory. This was attempted in list directory, but JS is dumb and doesn't allow "await"
  //in most cases.
  async searchUntilComplete(val) {
    let storeArray = val.entries;
    let currentItteration = val;
    //while there is still more to get
    while (currentItteration != null && currentItteration.has_more) {
      //get the next search results
      currentItteration = await this.continueSearch(currentItteration.cursor);
      //If it did return something, add it to the return array.
      if (currentItteration != null) {
        storeArray = storeArray.concat(currentItteration.entries);
      }
    }
    return storeArray;
  }

  //Continues a directory search request
  async continueSearch(cursor) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }
    return new Promise((res, err) => {
      const resp = fetchWithRetry(
        `https://api.dropboxapi.com/2/files/list_folder/continue`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token.access_token}`,
            "Content-Type": "application/json",
            // "Dropbox-API-Arg": `{"path":"${directory}"}`
          },
          body: JSON.stringify({ cursor: cursor }),
        },
      )
        .then((resp) => {
          resp
            .json()
            .then((val) => {
              //If there was a valid return from the request, return it.
              if (val != null && val.entries != null) {
                res(val);
              } else {
                console.error("Directory is empty: ", val);
                err("no values");
              }
            })
            .catch((error) => {
              console.error("Directory Response to json error:", error);
              err(error);
            });
        })
        .catch((error) => {
          console.error("Directory Response error:", error);
          err(error);
        });
    });
  }

  async getImages(directory) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }
    return new Promise((res, err) => {
      //Get all files in the given directory
      this.listDirectory(directory, true)
        .then((snapshot) => {
          if (snapshot != null) {
            //if something was returned, filter then return the array.
            snapshot = snapshot.filter((val) => {
              return val[".tag"] == "file";
            });
            res(snapshot);
          } else {
            //no data retrieved, reject.
            console.log("No data available for " + directory);
            err({});
          }
        })
        .catch((error) => {
          console.log("Error getting images " + error);
          err({});
        });
    });
  }

  async getLastImage(directory) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }

    let continueLoop = true;
    let currentDirectory = directory;
    let timeout = 0;
    let object = null;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!currentDirectory) {
        reject(new Error("Empty directory"));
        return;
      }

      // Get all folder in the given directory, and retrive the last image of the last folder
      while (continueLoop) {
        // Not sure why this was implemented
        timeout++;

        if (timeout >= 3) {
          continueLoop = false;

          reject(new Error("Timeout"));
          break;
        } else {
          await this.listDirectory(currentDirectory, false)
            // eslint-disable-next-line no-loop-func
            .then((snapshot) => {
              if (snapshot) {
                if (snapshot.length > 0) {
                  // There are objects in this directory, use last object here
                  object = snapshot[snapshot.length - 1];

                  if (object[".tag"] === "file") {
                    // not a directory, return

                    continueLoop = false;
                    resolve(object);
                  } else {
                    // is a directory, continue with new directory
                    currentDirectory = object.path_display + "/";
                  }
                } else {
                  continueLoop = false;

                  reject(new Error("No entries in searching folder"));
                }
              } else {
                // no data retrieved, reject.

                reject(new Error("No data available for " + directory));
              }
            })
            // eslint-disable-next-line no-loop-func
            .catch((error) => {
              continueLoop = false;

              reject(new Error("Error getting images " + error));
            });
        }
      }
    });
  }

  async getZip(directory, progressCallback) {
    if (this.token == null) {
      await this.generateFromRefreshToken();
    }
    console.log(directory);
    // const link = document.createElement("a");
    // link.href = `https://content.dropboxapi.com/2/files/download_zip?Authorization=Bearer ${this.token.access_token}&Dropbox-API-Arg={"path":"${directory}"}`;
    // link.click();
    return new Promise((res, err) => {
      const resp = fetch(
        `https://content.dropboxapi.com/2/files/download_zip`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token.access_token}`,
            // "Content-Type": "application/json",
            "Dropbox-API-Arg": `{"path":"${directory}"}`,
          },
        },
      )
        .then((res) => {
          if (res.status >= 400) {
            console.error("Status code " + res.status + ": " + res.statusText);
            throw new Error(
              "Status code " + res.status + ": " + res.statusText,
            );
          }
          let contentLength = +res.headers.get("Content-Length");
          let currentDownloaded = 0;
          console.log("Initial length: " + contentLength);
          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = res.body.getReader();

                read();
                function read() {
                  reader
                    .read()
                    .then(({ done, value }) => {
                      if (done) {
                        controller.close();
                        return;
                      } else {
                        currentDownloaded += value.length;
                        if (progressCallback != null) {
                          progressCallback(currentDownloaded, contentLength);
                        }
                      }
                      controller.enqueue(value);
                      read();
                    })
                    .catch((error) => {
                      console.error(error);
                      controller.error(error);
                    });
                }
              },
            }),
          );
        })
        .then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          let link = document.createElement("a");
          link.download = directory + ".zip";
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    });
  }
}

//this gets auth token assuming it is accessing a user's dropbox. Not what we want.
// generateUserAuthToken(){
//     let runSignIn = true;
//     let run = true;

//     if(this.findingToken) {
//         return
//     }
//     this.findingToken = true;

//     if(runSignIn && windowControllerInstance.dropBoxKey == null){
//         //gets the user to sign in and allow access
//         window.open(`https://www.dropbox.com/oauth2/authorize?client_id=${process.env.REACT_APP_DROPBOXV1KEY}&token_access_type=offline&response_type=code&redirect_uri=http://localhost:3000/`,"_self")
//     }
//     return new Promise((res, err)=>{
//         if(!run){
//             res(null)
//             return
//         }
//         //Create the form data
//         let formData = new FormData()
//         formData.append("code",windowControllerInstance.dropBoxKey)
//         formData.append("client_id",process.env.REACT_APP_DROPBOXV1KEY)
//         formData.append("client_secret",process.env.REACT_APP_DROPBOXV1SECRET)
//         formData.append("redirect_uri","http://localhost:3000/")
//         formData.append("grant_type","authorization_code")
//         const resp = fetch("https://api.dropboxapi.com/oauth2/token", {
//             method:"POST",
//             body:formData
//         })
//         .then((val)=>{
//             //if successful, convert to json and return
//             val.json().then((jsonVal)=>{
//                 this.findingToken = false;
//                 res(jsonVal)
//             })
//         }).catch((error)=>{
//             console.log("Auth error was" + error)
//         })
//     })
// }
