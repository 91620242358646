import { forwardRef, memo } from "react";

import {
  Box,
  Grid,
  Typography,
  Button,
  alpha,
  Container,
  Slide,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import _ from "lodash";

type DashboardEditHeaderProps = {
  title: string;
  subtitle?: string;
  onBack?: () => void;
  onDelete?: () => void;
  onSave: () => void;
  isLoading: boolean;
};

type DashboardEditSliderHeaderProps = {
  isShow: boolean;
  title: string;
  subtitle?: string;
  onSave: () => void;
  isLoading: boolean;
};

export const DashboardEditSliderHeader = memo(
  ({
    isShow,
    title,
    subtitle,
    onSave,
    isLoading,
  }: DashboardEditSliderHeaderProps) => {
    const smallScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm"),
    );

    return (
      <Slide direction="down" in={isShow}>
        <Box
          sx={{
            position: "fixed",
            top: ({ customConfig }) =>
              `calc(${customConfig.navbarHeight} + ${customConfig.subNavbarHeight})`,
            backgroundColor: ({ palette }) => palette.grey[200],
            width: "100%",
            zIndex: 10,
            boxShadow: 4,
          }}
        >
          <Container
            fixed
            maxWidth={"xl"}
            sx={{
              height: "100%",
              py: {
                xs: 1.5,
              },
              px: {
                xs: 2,
                sm: 4,
              },
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                color: ({ palette }) => palette.secondary.main,
                rowGap: 0.5,
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 16,
                      sm: 20,
                    },
                    lineHeight: 1,
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Typography>

                {smallScreenAndUp && (
                  <Typography sx={{ fontSize: 12, mt: 0.2 }}>
                    {subtitle}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Button
                  onClick={onSave}
                  disabled={isLoading}
                  size="small"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{
                    color: "white !important",
                    fontSize: {
                      xs: 10,
                      sm: 13,
                    },
                  }}
                >
                  save
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Slide>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

const DashboardEditHeader = forwardRef<
  HTMLDivElement,
  DashboardEditHeaderProps
>((props, ref) => {
  const { title, subtitle, onBack, onDelete, onSave, isLoading } = props;

  return (
    <Box ref={ref}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          color: ({ palette }) => palette.secondary.main,
          mb: 1.5,
          rowGap: 1,
        }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: {
                xs: 18,
                sm: 22,
              },
              lineHeight: 1,
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 14,
                },
                mt: 0.2,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: {
              xs: "100%",
              sm: "max-content",
            },
            gap: 1,
          }}
        >
          {onBack && (
            <Button
              fullWidth
              onClick={onBack}
              disabled={isLoading}
              size="small"
              disableElevation
              variant="contained"
              sx={{
                color: "white !important",
                background: ({ palette }) =>
                  alpha(palette.secondary.light, 0.5),

                ":hover": {
                  background: ({ palette }) => palette.secondary.light,
                },
                fontSize: {
                  xs: 10,
                  sm: 12,
                  md: 13,
                },
              }}
              startIcon={<ArrowBackIcon />}
            >
              back
            </Button>
          )}

          {onDelete && (
            <Button
              fullWidth
              onClick={onDelete}
              disabled={isLoading}
              size="small"
              color="error"
              disableElevation
              variant="contained"
              sx={{
                color: "white !important",
                fontSize: {
                  xs: 10,
                  sm: 12,
                  md: 13,
                },
              }}
            >
              delete
            </Button>
          )}

          <Button
            fullWidth
            onClick={onSave}
            disabled={isLoading}
            size="small"
            color="primary"
            disableElevation
            variant="contained"
            sx={{
              color: "white !important",
              fontSize: {
                xs: 10,
                sm: 12,
                md: 13,
              },
            }}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});

export default memo(DashboardEditHeader, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
