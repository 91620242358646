import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
    <App />
  </BrowserRouter>,
  document.getElementById("root"),
);
