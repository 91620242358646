import {
  Modal as MuiModal,
  Box,
  Typography,
  Button,
  ModalProps as MuiModalProps,
} from "@mui/material";
import useIsScrollComplete from "hooks/useIsScrollComplete";

import { MouseEvent, ReactNode, useRef } from "react";

type BasicModalProps = Omit<MuiModalProps, "children" | "onClose"> & {
  title?: string;
  onCancel?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "cancelClick",
  ) => void;
  onDone: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  actions?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  HeaderComponent?: ReactNode;
  ContentComponent?: ReactNode;
  FooterComponent?: ReactNode;
};

type BaseModalProps = Omit<MuiModalProps, "children"> & {
  children: ReactNode | ReactNode[];
};

export const ModalHeader = ({
  title,
  children,
}: {
  title?: string;
  children?: ReactNode | ReactNode[];
}) => {
  return (
    <Box>
      {title && (
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              lineHeight: 1,
              fontWeight: "bold",
              width: "max-content",
            }}
          >
            {title}
          </Typography>
        </Box>
      )}

      {children && <Box>{children}</Box>}
    </Box>
  );
};

export const ModalContent = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isScrollComplete } = useIsScrollComplete({ ref });

  return (
    <Box
      ref={ref}
      sx={{
        mt: 2,
        overflow: "auto",
        // border: "1px solid lightgrey",

        minHeight: 100,
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        maskImage: !isScrollComplete
          ? "linear-gradient(to bottom, white calc(100% - 100px), transparent 100%)"
          : "",
      }}
    >
      {children}
    </Box>
  );
};

export const ModalFooter = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  return (
    <Box
      sx={{
        mt: "auto",
        pt: 2,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {children}
    </Box>
  );
};

export const ModalActions = ({
  actions,
  onCancel = () => {},
  onDone = () => {},
  disabled,
}: {
  actions?: ReactNode | ReactNode[];
  onDone: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {actions ? (
        { actions }
      ) : (
        <>
          <Button
            sx={{ color: "white !important" }}
            size="small"
            color="error"
            variant="contained"
            disableElevation
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
          <Button
            sx={{ ml: 1.5, color: "white !important" }}
            size="small"
            color="primary"
            variant="contained"
            disableElevation
            onClick={onDone}
            disabled={disabled}
          >
            Done
          </Button>
        </>
      )}
    </Box>
  );
};

export const BasicModal = ({
  open,
  onClose,
  onDone,
  onCancel,
  disabled,
  title,
  children,
  HeaderComponent,
  ContentComponent,
  FooterComponent,
}: BasicModalProps) => {
  return (
    <BaseModal open={open} onClose={onClose}>
      {HeaderComponent ? (
        <>{HeaderComponent}</>
      ) : (
        <ModalHeader title={title}></ModalHeader>
      )}

      <ModalContent>{children}</ModalContent>

      {FooterComponent ? (
        <>{FooterComponent}</>
      ) : (
        <ModalFooter>
          <ModalActions
            onDone={onDone}
            onCancel={(e) =>
              onCancel ? onCancel(e) : onClose(e, "cancelClick")
            }
            disabled={disabled}
          />
        </ModalFooter>
      )}
    </BaseModal>
  );
};

// TODO: just use Dialog
const BaseModal = ({ open, onClose, children }: BaseModalProps) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        sx={({ breakpoints }) => ({
          position: "absolute",
          bgcolor: "background.paper",
          px: {
            xs: 2,
            sm: 4,
          },
          py: 4,
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",

          width: {
            xs: "100vw",
            sm: "90vw",
            md: "70vw",
            lg: "70vw",
          },

          "&:focus": {
            outline: "none",
          },

          ...(breakpoints.up("sm")
            ? {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 1,
              }
            : {
                bottom: "0",
                left: "0",
                minHeight: "80vh",

                borderRadius: 3,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }),

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          //   height: "100%",
        })}
      >
        {children}
      </Box>
    </MuiModal>
  );
};

export default BaseModal;
