import { Client, JobSite } from "database/DataTypes";
import { Image } from "database/ImageHandlerV2";
import { atomWithReset } from "jotai/utils";

export const selectedImageState = atomWithReset<Image | null>(null);
export const currentWeatherImageState = atomWithReset<Image | null>(null);

export const currentGalleryJobsiteState = atomWithReset<JobSite | null>(null);
export const currentGalleryClientState = atomWithReset<Client | null>(null);

export const imageViewerComponentCahcesState = atomWithReset<any>({});
export const weatherReportComponentCahcesState = atomWithReset<any>({});
export const gridComponentCahcesState = atomWithReset<any>({});
