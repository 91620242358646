import moment from "moment";
import momentTz, { Moment, MomentInput } from "moment-timezone";
import { useCallback } from "react";

export const momentWithTimeZoneHOF =
  (timeZone: string = "") =>
  (date?: MomentInput, keepLocalTime = true): Moment => {
    let currentTimeZone = timeZone;

    const isValid = momentTz.tz.zone(timeZone);

    if (!isValid) {
      currentTimeZone = momentTz.tz.guess() as string;
    }

    return moment(date).tz(currentTimeZone, keepLocalTime);
  };

const useTimeZone = (timeZone: string = "") => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const momentWithTimeZone = useCallback(
    momentWithTimeZoneHOF(timeZone),

    [timeZone],
  );

  return { momentWithTimeZone };
};

export default useTimeZone;
