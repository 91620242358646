import { createTheme } from "@mui/material";

import functions from "./functions";

import _ from "lodash";

const createCssConst = (obj, parentKey) => {
  let result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey + "-" + _.kebabCase(key);

      if (typeof obj[key] === "object") {
        const nestedObj = createCssConst(obj[key], newKey);
        result = { ...result, ...nestedObj };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};

const createCssVariables = (theme) => ({
  body: {
    ...createCssConst(theme.palette.primary, "--mui-pallette-primary"),
    ...createCssConst(theme.palette.secondary, "--mui-pallette-secondary"),
    ...createCssConst(theme.palette.dark, "--mui-pallette-dark"),
    ...createCssConst(theme.palette.text, "--mui-pallette-text"),
  },
});

const theme = createTheme();

const reliveItTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: "smooth",
        },
        body: {
          background: "none",
          backgroundColor: "#383838",
        },
        "*, *::before, *::after": {
          margin: 0,
          padding: 0,
        },
        "a, a:link, a:visited": {
          textDecoration: "none !important",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("md")]: {
            maxWidth: "900px !important",
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: { main: "#08b0d9" },
    secondary: { main: "#383838" },
    light: { main: "#dedbdc" },
    warning: { main: "#fde78f", light: "#fdf0bc", dark: "#ffb547" },
    error: { main: "#ff949c", light: "#ffb2b8", dark: "#e31a1a" },
    success: { main: "#08d99a" },
    white: { main: "#FFFFFF" },
    chart: { main: "#285A64", dark: "#143138" },
  },
  borders: {
    borderRadius: {
      sm: "2px",
      md: "4px",
      lg: "8px",
      pill: "999999px",
      circle: "100%",
    },
  },
  customConfig: {
    imageViewerNavbarHeight: "50px",
    navbarHeight: "50px",
    subNavbarHeight: "45px",
  },
  functions: {
    ...functions,
  },
};

export default reliveItTheme;
