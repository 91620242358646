import { LinkProps as MuiLinkProps, Link as MuiLink } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

type LinkWrapperProps = MuiLinkProps & LinkProps;

const LinkWrapper = ({ children, ...restProps }: LinkWrapperProps) => {
  return (
    <MuiLink component={Link} {...restProps}>
      {children}
    </MuiLink>
  );
};

export default LinkWrapper;
